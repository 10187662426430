import { makeStyles } from '@material-ui/core/styles';
import {
  nuance250,
  thea150,
} from '../../constants/colors';

const useStyles = makeStyles(() => ({
  paper: {
    height: '40px',
    backgroundColor: thea150,
    borderRadius: '10px',
    marginBottom: '20px',
    padding: '8px',
    position: 'relative',
    top: '20px',
    width: '100%',
  },
  formControl: {
    width: '100%',
    height: '80px',
  },
  label: {
    marginLeft: '3px',
  },
  helperText: {
    width: '100%',
    position: 'relative',
    marginLeft: '3px',
    // top: '-5px',
  },
  select: {
    position: 'absolute',
    padding: 0,
    left: '20px',
    width: 'calc(100% - 40px)',
    height: '1.5em',
    '& > div': {
      backgroundColor: `${thea150}!important`,
    },
    '&:before': {
      borderBottom: 'none',
    },
    '&:after': {
      borderBottom: 'none',
    },
    '&:hover:before': {
      borderBottom: 'none !important',
    },
  },
  menuItem: {
    margin: 0,
    color: nuance250,
  },
}));

export default useStyles;
