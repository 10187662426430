import { makeStyles } from '@material-ui/core/styles';
import {
  thea500,
  tonique3,
} from '../../../constants/colors';

const useStyles = makeStyles(() => ({
  paper: {
    textAlign: 'center',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '1rem',
  },
  buttonExportCsv: {
    margin: 0,
    textTransform: 'unset',
    backgroundColor: tonique3,
    '&:hover': {
      backgroundColor: tonique3,
    },
  },
  closeButton: {
    position: 'absolute',
    right: '20px',
    top: '10px',
  },
  cell: {
    color: thea500,
    fontFamily: 'FuturaPTMedium',
  },
}));

export default useStyles;
