import React from 'react';
import {
  ClickAwayListener, Fade, Grid, IconButton, MenuItem, MenuList, Paper, Popper,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { EditorState } from 'draft-js';
import EditorComponent from '../../../components/form/EditorComponent';
import GreyTextInput from '../../../components/form/GreyTextInput';
import useStyles from './FormProgramEvent.style';
import { nuance100 } from '../../../constants/colors';
import MoreVerticalOutline from '../../../components/icon/MoreVerticalOutline';

type Props = {
  time: string,
  text: string | EditorState,
  sortOrder: number,
  formErrors: any,
  /* eslint-disable no-unused-vars */
  onChangeEditor: (editor: EditorState) => void
  onChangeDate: (e: React.ChangeEvent<HTMLInputElement>) => void
  onChangeSortOrder: (e: React.ChangeEvent<HTMLInputElement>) => void
  onDeleteProgram:() => void
}

const FormProgramEvent = ({
  time,
  text,
  sortOrder,
  formErrors,
  onChangeDate,
  onChangeEditor,
  onChangeSortOrder,
  onDeleteProgram,
}: Props) => {
  const { t } = useTranslation(['backoffice']);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'popper-event' : undefined;

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };
  const handleClickDelete = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
    onDeleteProgram();
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  return (
    <Paper className={classes.paper}>
      <IconButton
        className={classes.icon}
        onClick={handleClick}
      >
        <MoreVerticalOutline />
      </IconButton>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <GreyTextInput
            type="text"
            value={time}
            label={t('event.form.input.time')}
            onChange={onChangeDate}
            style={{ backgroundColor: nuance100 }}
          />
          <GreyTextInput
            type="number"
            value={sortOrder}
            label={t('event.form.input.display_order')}
            onChange={onChangeSortOrder}
            error={formErrors.display_order !== ''}
            helperText={formErrors.display_order}
            style={{ backgroundColor: nuance100 }}
          />
          <EditorComponent
            label={t('event.form.input.description')}
            value={text}
            onChange={onChangeEditor}
            isWhite
          />
        </Grid>
      </Grid>
      <Popper id={id} open={open} anchorEl={anchorEl} style={{ zIndex: 2 }} transition>
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClickAway}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Fade {...TransitionProps} timeout={350}>
              <Paper elevation={1}>
                <MenuList>
                  <MenuItem onClick={handleClickDelete} className={classes.buttonDelete}>
                    {t('common.delete')}
                  </MenuItem>
                </MenuList>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </Paper>
  );
};

export default React.memo(FormProgramEvent);
