import React, { Dispatch, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { DefaultRootState, useDispatch, useSelector } from 'react-redux';
import { Button, Typography } from '@material-ui/core';
import useStyles from '../../../components/paper/MainPaper.style';
import { ToolboxState } from '../../../store/toolbox/types';
import { NotificationContext } from '../../../contexts/NotificationContext';
import { getToolboxes, resetStatus } from '../../../store/toolbox/actions';
import PlusIcon from '../../../components/icon/PlusIcon';
import { routeToolboxAdd } from '../../../constants/routes';
import ProgressContainer from '../../../components/common/ProgressContainer';
import ToolboxTable from './ToolboxTable';

type Props = {}

const ToolboxPage: React.FC<Props> = () => {
  const { t } = useTranslation(['backoffice']);
  const classes = useStyles();
  const history = useHistory();
  const dispatch: Dispatch<any> = useDispatch();
  const {
    toolboxes,
    pending,
    error,
    status,
  } = useSelector<DefaultRootState, ToolboxState>(
    (state: any) => state.toolboxStore,
  );
  const { setNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (toolboxes.length === 0) dispatch(getToolboxes());
  }, []);

  useEffect(() => {
    if (status === 'ERROR') {
      setNotification({ isOpened: true, severity: 'error', message: error || '' });
    }
    if (status !== 'INIT') dispatch(resetStatus());
  }, [status]);

  return (
    <>
      <Typography variant="h1" className={classes.title}>
        {t('title.toolbox')}
        <Button
          variant="contained"
          color="primary"
          startIcon={<PlusIcon width={22} height={22} />}
          className={classes.buttonAdd}
          onClick={() => history.push(routeToolboxAdd)}
        >
          <Typography variant="button">
            {t('toolbox.buttonAdd')}
          </Typography>
        </Button>
      </Typography>

      <ProgressContainer loading={pending}>
        <ToolboxTable toolboxes={toolboxes} />
      </ProgressContainer>
    </>
  );
};

export default ToolboxPage;
