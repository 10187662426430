import {
  AxiosError,
  AxiosResponse,
} from 'axios';
import {
  DispatchType, IFlashInfo, FlashInfoAction,
} from './types';
import {
  STORE_FLASH_INFO_ADDED_SENT,
  STORE_FLASH_INFO_DELETED,
  STORE_FLASH_INFO_FAILED,
  STORE_FLASH_INFO_PENDING,
  STORE_FLASH_INFO_LOADED,
  STORE_FLASH_INFO_SENDING,
  STORE_FLASH_INFO_UPDATED_SENT,
  STORE_FLASH_INFO_RESET_STATUS,
} from './actionTypes';
import {
  apiGetFlashInfos, apiDeleteFlashInfo, apiPostFlashInfo, apiEditFlashInfo,
} from '../../api/apiFlashInfo';

const flashInfoDeletedAction = (flashInfoId: string): FlashInfoAction => ({
  type: STORE_FLASH_INFO_DELETED,
  flashInfoId,
});

const flashInfoFailedAction = (error: string): FlashInfoAction => ({
  type: STORE_FLASH_INFO_FAILED,
  error,
});

const flashInfoPendingAction = (): FlashInfoAction => ({
  type: STORE_FLASH_INFO_PENDING,
});

const flashInfoLoadedAction = (flashInfos: IFlashInfo[]): FlashInfoAction => ({
  type: STORE_FLASH_INFO_LOADED,
  flashInfos,
});

const flashInfoSendingAction = (): FlashInfoAction => ({
  type: STORE_FLASH_INFO_SENDING,
});

const flashInfoAddedSentAction = (flashInfo: IFlashInfo): FlashInfoAction => ({
  type: STORE_FLASH_INFO_ADDED_SENT,
  flashInfo,
});

const flashInfoUpdatedAction = (flashInfo: IFlashInfo): FlashInfoAction => ({
  type: STORE_FLASH_INFO_UPDATED_SENT,
  flashInfo,
});

const flashInfoResetStatus = (): FlashInfoAction => ({
  type: STORE_FLASH_INFO_RESET_STATUS,
});

export const resetStatus = () => (dispatch: DispatchType) => {
  dispatch(flashInfoResetStatus());
};

export const deleteFlashInfo = (flashInfoId: string) => (dispatch: DispatchType) => {
  apiDeleteFlashInfo(flashInfoId)
    .then(() => dispatch(flashInfoDeletedAction(flashInfoId)))
    .catch((error: AxiosError) => dispatch(flashInfoFailedAction(error.message)));
};

export const getFlashInfos = () => (dispatch: DispatchType) => {
  dispatch(flashInfoPendingAction());
  apiGetFlashInfos()
    .then((data: AxiosResponse) => dispatch(flashInfoLoadedAction(data.data)))
    .catch((error: AxiosError) => dispatch(flashInfoFailedAction(error.message)));
};

export const postFlashInfo = (
  content: Pick<IFlashInfo, 'title' | 'description' | 'start_date' | 'end_date' | 'display_end_date'>,
  picture?: File,
) => (dispatch: DispatchType) => {
  dispatch(flashInfoSendingAction());
  apiPostFlashInfo(content, picture)
    .then((data: AxiosResponse) => dispatch(flashInfoAddedSentAction(data.data)))
    .catch((error: AxiosError) => dispatch(flashInfoFailedAction(error.message)));
};

export const putFlashInfo = (
  flashInfoId: string,
  content: Pick<IFlashInfo, 'title' | 'description' | 'start_date' | 'end_date' | 'display_end_date'>,
  file?: File | string,
) => (dispatch: DispatchType) => {
  dispatch(flashInfoSendingAction());
  apiEditFlashInfo(flashInfoId, content, file)
    .then((data: AxiosResponse) => dispatch(flashInfoUpdatedAction(data.data)))
    .catch((error: AxiosError) => dispatch(flashInfoFailedAction(error.message)));
};
