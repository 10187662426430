import React, {
  Dispatch, useEffect, useContext, useState,
} from 'react';
import { DefaultRootState, useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography, Button, Grid,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { UserState } from '../../../../store/user/types';
import {
  addMarketingEmails, deleteMarketingEmails, getMarketingEmails, resetStatus,
} from '../../../../store/user/actions';
import WhitePaper from '../../../../components/paper/WhitePaper';
import { NotificationContext } from '../../../../contexts/NotificationContext';
import PlusIcon from '../../../../components/icon/PlusIcon';
import GreyTextInput from '../../../../components/form/GreyTextInput';
import ProgressContainer from '../../../../components/common/ProgressContainer';

const UsersEmailMarketingAuthorizedList = () => {
  const { t } = useTranslation(['backoffice']);
  const {
    emailsAuthorized,
    error,
    status,
    pending,
  } = useSelector<DefaultRootState, UserState>(
    (state: any) => state.userStore,
  );
  const dispatch: Dispatch<any> = useDispatch();
  const { setNotification } = useContext(NotificationContext);

  const [value, setValue] = useState('');

  useEffect(() => {
    if (emailsAuthorized.length === 0) dispatch(getMarketingEmails());
  }, []);

  useEffect(() => {
    if (status === 'ERROR') {
      setNotification({ isOpened: true, severity: 'error', message: error || '' });
    }
    if (status === 'SUCCESS') {
      setNotification({ isOpened: true, severity: 'success', message: t('users.emailsAuthorized.form.success') });
    }
    if (status !== 'INIT') dispatch(resetStatus());
  }, [status]);

  return (
    <div>
      <Typography variant="h1" style={{ marginBottom: '1rem' }}>
        {t('title.emailsAuthorized')}
      </Typography>
      <ProgressContainer loading={pending}>
        <Grid container xs={12} spacing={1}>
          <Grid item xs={8}>
            <WhitePaper title={t('users.emailsAuthorized.title')}>
              <List>
                {emailsAuthorized.map((email) => (
                  <ListItem key={email.id}>
                    <ListItemText
                      primary={email.email}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => dispatch(deleteMarketingEmails(email.id))}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>

            </WhitePaper>
          </Grid>

          <Grid item xs={4}>
            <WhitePaper title={t('users.emailsAuthorized.addTitle')}>
              <GreyTextInput
                type="text"
                value={value}
                label={t('users.emailsAuthorized.form.email')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                startIcon={<PlusIcon width={16} height={16} />}
                style={{ textTransform: 'none', fontWeight: 300 }}
                onClick={() => {
                  dispatch(addMarketingEmails(value));
                  setValue('');
                }}
              >
                <Typography variant="button">
                  {t('users.emailsAuthorized.buttonAdd')}
                </Typography>
              </Button>
            </WhitePaper>
          </Grid>
        </Grid>
      </ProgressContainer>
    </div>
  );
};

export default React.memo(UsersEmailMarketingAuthorizedList);
