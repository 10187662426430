export type ISpeciality = {
  id: string
  name: string
}

export const SPECIALITIES: ISpeciality[] = [
  {
    id: 'Cataract',
    name: 'Cataracte',
  },
  {
    id: 'Contactology',
    name: 'Contactologie',
  },
  {
    id: 'Cornea',
    name: 'Cornée',
  },
  {
    id: 'Eye Lids',
    name: 'Paupières',
  },
  {
    id: 'General',
    name: 'Général',
  },
  {
    id: 'Glaucoma Medical',
    name: 'Glaucome médical',
  },
  {
    id: 'Glaucoma Surgical',
    name: 'Chirurgie du glaucome',
  },
  {
    id: 'Infectology',
    name: 'Infectologie',
  },
  {
    id: 'Neuro-Ophthalmology',
    name: 'Neuro - ophtalmologie',
  },
  {
    id: 'Ocular surface specialist',
    name: 'Surface oculaire',
  },
  {
    id: 'Oculoplastic',
    name: 'Oculoplastique',
  },
  {
    id: 'Oncology',
    name: 'Oncologie',
  },
  {
    id: 'Paediatrics',
    name: 'Pédiatrie',
  },
  {
    id: 'Refractive',
    name: 'Réfractif',
  },
  {
    id: 'Retina Medical',
    name: 'Rétine médicale',
  },
  {
    id: 'Retina Surgical',
    name: 'Chirurgie de la rétine',
  },
  {
    id: 'Strabismus',
    name: 'Strabisme',
  },
  {
    id: 'Ultrasound/ Echography',
    name: 'Imagerie',
  },
  {
    id: 'Uveitis',
    name: 'Uvéite',
  },
  {
    id: 'Other',
    name: 'Autre',
  },
];
