import axios from 'axios';
import { apiBookOrders, apiBooks } from '../constants/api';
import { getToken } from '../services/AuthService';
import { IBook } from '../store/book/types';

export const apiGetBooks = async () => {
  const token: string = await getToken();
  const data = await axios.get(
    `${process.env.REACT_APP_API_ROOT}${apiBooks}`,
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return data;
};

export const apiPostBook = async (content: Partial<IBook>, file?: File) => {
  const token: string = await getToken();

  const formData = new FormData();
  formData.append('content', JSON.stringify(content));
  if (file) {
    formData.append('picture', file);
  }

  const data = await axios.post(
    `${process.env.REACT_APP_API_ROOT}${apiBooks}`,
    formData,
    { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/x-www-form-urlencoded' } },
  );
  return data;
};

export const apiEditBook = async (
  bookId: string,
  content: Partial<IBook>,
  file?: File | string,
) => {
  const token: string = await getToken();

  const formData = new FormData();
  formData.append('content', JSON.stringify(content));
  if (file) {
    formData.append('picture', file);
  }

  const data = await axios.post(
    `${process.env.REACT_APP_API_ROOT}${apiBooks}/${bookId}`,
    formData,
    { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/x-www-form-urlencoded' } },
  );
  return data;
};

export const apiDeleteBook = async (id: string) => {
  const token: string = await getToken();
  const data = await axios.delete(
    `${process.env.REACT_APP_API_ROOT}${apiBooks}/${id}`,
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return data;
};

export const apiPutEnableBook = async (id: string) => {
  const token: string = await getToken();
  const data = await axios.put(
    `${process.env.REACT_APP_API_ROOT}${apiBooks}/activate/${id}`,
    {
      is_active: true,
    },
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return data;
};

export const apiExportBooks = async (type: 'TYPE_USERS' | 'TYPE_SIMPLE' | 'TYPE_DETAILED') => {
  const token: string = await getToken();
  const data = await axios.get(
    `${process.env.REACT_APP_API_ROOT}${apiBookOrders}?format=csv&type=${type}`,
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return data;
};
