import { makeStyles } from '@material-ui/core/styles';
import { thea150 } from '../../constants/colors';

export const leftIconStyle = {
  position: 'absolute',
  left: 18,
  top: 18,
  margin: 0,
  padding: 0,
};

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
    height: '80px',
  },
  formControlError: {
    width: '100%',
    height: '94px',
  },
  paper: {
    height: '40px',
    backgroundColor: thea150,
    width: '100%',
    borderRadius: '10px',
    padding: '8px',
    marginBottom: '20px',
    position: 'relative',
    minWidth: 100,
    top: '20px',
  },
  inputBase: {
    position: 'absolute',
    padding: 0,
    left: '20px',
    width: 'calc(100% - 40px)',
  },
  inputBaseWithIcon: {
    position: 'absolute',
    left: '54px',
    padding: 0,
    width: 'calc(100% - 74px)',
  },
  inputPassword: {
    position: 'absolute',
    left: '20px',
    padding: 0,
    width: 'calc(100% - 74px)',
  },
  inputPasswordWithIcon: {
    position: 'absolute',
    left: '54px',
    padding: 0,
    width: 'calc(100% - 110px)',
  },
  input: {
    padding: 0,
    width: '100%',
    height: '1.5em',
  },
  label: {
    marginLeft: '3px',
  },
  helperText: {
    width: '100%',
    position: 'relative',
    marginLeft: '3px',
    // top: '-5px',
  },
  rightIcon: {
    position: 'absolute',
    right: 18,
    top: 10,
    margin: 0,
    padding: 0,
  },
  switchContainer: {
    width: '100%',
    marginBottom: '12px',
  },
}));

export default useStyles;
