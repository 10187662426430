import React, { ReactElement } from 'react';
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';

interface Props {
  severity: 'success' | 'error';
  children: string | Node;
  isOpen: boolean;
  /* eslint-disable no-unused-vars */
  handleClose: (reason: string) => void;
}

export default function Notification({
  severity,
  children,
  isOpen,
  handleClose,
}: Props): ReactElement {
  return (
    <Snackbar
      style={{ padding: '0 2rem' }}
      open={isOpen}
      autoHideDuration={3000}
      onClose={(event: any, reason: string) => handleClose(reason)}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert severity={severity}>{children}</Alert>
    </Snackbar>
  );
}
