import React, {
  Dispatch,
  useContext,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  DefaultRootState,
  useDispatch,
  useSelector,
} from 'react-redux';
import { useHistory } from 'react-router';
import useStyles from '../../../components/paper/MainPaper.style';
import ProgressContainer from '../../../components/common/ProgressContainer';
import { NotificationContext } from '../../../contexts/NotificationContext';
import { routeProductsAdd } from '../../../constants/routes';
import {
  getProductsAndProductCategories,
  resetStatus,
} from '../../../store/product/actions';
import {
  IProduct,
  IProductCategory,
  ProductState,
} from '../../../store/product/types';
import PlusIcon from '../../../components/icon/PlusIcon';
import ProductItem from './ProductItem';
import productsFilteredSelector from '../../../store/product/selectors';
import ProductCategories from './ProductCategories';

type Props = {};

const ProductsPage: React.FC<Props> = () => {
  const { t } = useTranslation(['backoffice']);
  const classes = useStyles();
  const history = useHistory();
  const dispatch: Dispatch<any> = useDispatch();
  const {
    products,
    categories,
    categoryIds,
    pending,
    error,
    status,
  } = useSelector<DefaultRootState, ProductState>(
    (state: any) => state.productStore,
  );
  const { setNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (products.length === 0) dispatch(getProductsAndProductCategories());
  }, []);

  useEffect(() => {
    if (status === 'ERROR') {
      setNotification({ isOpened: true, severity: 'error', message: error || '' });
    }
    if (status !== 'INIT') dispatch(resetStatus());
  }, [status]);

  const productsFiltered = useSelector(productsFilteredSelector);

  return (
    <>
      <Typography variant="h1" className={classes.title}>
        {t('title.products')}
        <Button
          variant="contained"
          color="primary"
          startIcon={<PlusIcon width={22} height={22} />}
          className={classes.buttonAdd}
          onClick={() => history.push(routeProductsAdd)}
        >
          <Typography variant="button">
            {t('product.buttonAdd')}
          </Typography>
        </Button>
      </Typography>

      <ProgressContainer loading={pending}>
        <Grid container spacing={4}>
          <Grid item xs={9}>
            <Grid container spacing={1}>
              {categoryIds.map((id: string) => {
                const categoryProducts: IProduct[] = productsFiltered
                  .filter((e: IProduct) => e.product_category_id === id);
                if (categoryProducts.length > 0) {
                  return (
                    <>
                      <Grid item xs={12}>
                        <Typography variant="h4" className={classes.title}>
                          {categories.find((c: IProductCategory) => c.id === id)?.title}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={4}>
                          {categoryProducts.length > 0 && categoryProducts
                            .map((product) => (
                              <ProductItem product={product} key={product.id} />
                            ))}
                        </Grid>
                      </Grid>
                    </>
                  );
                }
                return null;
              })}
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid container spacing={2}>
              <ProductCategories categories={categories} categoryIds={categoryIds} />
            </Grid>
          </Grid>
        </Grid>
      </ProgressContainer>
    </>
  );
};

export default ProductsPage;
