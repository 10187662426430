import {
  AxiosError,
  AxiosResponse,
} from 'axios';
import {
  STORE_USER_ADMIN_SAVED,
  STORE_USER_DELETED,
  STORE_USER_EMAIL_MARKETING_DELETED,
  STORE_USER_EMAIL_MARKETING_FAILED,
  STORE_USER_EMAIL_MARKETING_RECEIVED,
  STORE_USER_EMAIL_MARKETING_SAVED,
  STORE_USER_FAILED,
  STORE_USER_PENDING,
  STORE_USER_RECEIVED,
  STORE_USER_RESET_STATUS,
  STORE_USER_SAVED,
} from './actionTypes';
import {
  DispatchType,
  EmailAuthorized,
  IUser,
  UserAction,
} from './types';
import {
  apiDeleteMarketingEmails,
  apiDeleteUser,
  apiExportUsers,
  apiGetMarketingEmails,
  apiGetUsers,
  apiPostAdminUser,
  apiPostMarketingEmails,
  apiPutUser,
} from '../../api/apiUser';
import generateCsv from '../../utils/generateCsv';

const userPendingAction = (): UserAction => ({
  type: STORE_USER_PENDING,
});

const userReceivedAction = (users: IUser[]): UserAction => ({
  type: STORE_USER_RECEIVED,
  users,
});

const userFailedAction = (error: string): UserAction => ({
  type: STORE_USER_FAILED,
  error,
});

const UserResetStatus = (): UserAction => ({
  type: STORE_USER_RESET_STATUS,
});

const userSavedAction = (user: IUser): UserAction => ({
  type: STORE_USER_SAVED,
  user,
});

const userAdminSavedAction = (user: IUser): UserAction => ({
  type: STORE_USER_ADMIN_SAVED,
  user,
});

const emailMarketingReceivedAction = (emails: EmailAuthorized[]): UserAction => ({
  type: STORE_USER_EMAIL_MARKETING_RECEIVED,
  emails,
});

const emailMarketingSavedAction = (email: EmailAuthorized): UserAction => ({
  type: STORE_USER_EMAIL_MARKETING_SAVED,
  email,
});

const emailMarketingDeletedAction = (emailId: string): UserAction => ({
  type: STORE_USER_EMAIL_MARKETING_DELETED,
  emailId,
});

const emailMarketingFailedAction = (error: string): UserAction => ({
  type: STORE_USER_EMAIL_MARKETING_FAILED,
  error,
});

const userDeletedAction = (userId: string): UserAction => ({
  type: STORE_USER_DELETED,
  userId,
});

export const resetStatus = () => (dispatch: DispatchType) => {
  dispatch(UserResetStatus());
};
export const getUsers = () => (dispatch: DispatchType) => {
  dispatch(userPendingAction());
  apiGetUsers()
    .then((data: AxiosResponse) => dispatch(userReceivedAction(data.data)))
    .catch((error: AxiosError) => dispatch(userFailedAction(error.message)));
};

export const putUser = (userId: string, content: Partial<IUser>) => (dispatch: DispatchType) => {
  apiPutUser(userId, content)
    .then((data: AxiosResponse) => dispatch(userSavedAction(data.data)))
    .catch((error: AxiosError) => dispatch(userFailedAction(error.message)));
};

export const addAdminUser = (content: Partial<IUser>) => (dispatch: DispatchType) => {
  apiPostAdminUser(content)
    .then((data: AxiosResponse) => dispatch(userAdminSavedAction(data.data)))
    .catch((error: AxiosError) => dispatch(userFailedAction(error.message)));
};

export const deleteUser = (userId: string) => (dispatch: DispatchType) => {
  apiDeleteUser(userId)
    .then(() => dispatch(userDeletedAction(userId)))
    .catch((error: AxiosError) => dispatch(userFailedAction(error.message)));
};

export const getMarketingEmails = () => (dispatch: DispatchType) => {
  dispatch(userPendingAction());
  apiGetMarketingEmails()
    .then((data: AxiosResponse) => dispatch(emailMarketingReceivedAction(data.data)))
    .catch((error: AxiosError) => dispatch(emailMarketingFailedAction(error.message)));
};

export const addMarketingEmails = (email: string) => (dispatch: DispatchType) => {
  apiPostMarketingEmails(email)
    .then((data: AxiosResponse) => dispatch(emailMarketingSavedAction(data.data)))
    .catch((error: AxiosError) => dispatch(emailMarketingFailedAction(error.message)));
};

export const deleteMarketingEmails = (emailId: string) => (dispatch: DispatchType) => {
  apiDeleteMarketingEmails(emailId)
    .then(() => dispatch(emailMarketingDeletedAction(emailId)))
    .catch((error: AxiosError) => dispatch(emailMarketingFailedAction(error.message)));
};

export const exportCsv = (roles: ('ROLE_OPHTHA' | 'ROLE_MARKETING' | 'ROLE_ADMIN' | 'ROLE_NURSE' | 'ROLE_ORTHOPTIST' | '')[]) => (dispatch: DispatchType) => {
  const filenameRole = (roles.length > 0 && roles[0] !== '')
    ? `_${roles.map((r) => r.split('_')[1].toLowerCase()).join('_')}`
    : '';

  const filename = `liste_des_utilisateurs${filenameRole}`;

  apiExportUsers(roles)
    .then((data: AxiosResponse) => generateCsv(data.data, filename, 'userList'))
    .catch((error: AxiosError) => dispatch(userFailedAction(error.message)));
};
