import {
  RegionAction,
  RegionState,
} from './types';
import {
  STORE_REGION_FAILED,
  STORE_REGION_LOADED,
  STORE_REGION_PENDING,
} from './actionTypes';

const initialState: RegionState = {
  regions: [],
  pending: false,
  error: null,
  status: 'INIT',
};

const reducer = (state: RegionState = initialState, action: RegionAction): RegionState => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case STORE_REGION_LOADED:
      return {
        ...state,
        pending: false,
        regions: action.regions || [],
      };
    case STORE_REGION_FAILED:
      if (action.error !== undefined) {
        return {
          ...state,
          error: action.error,
          pending: false,
          status: 'ERROR',
        };
      }
      return state;
    case STORE_REGION_PENDING:
      return {
        ...state,
        pending: true,
      };
  }
  return state;
};

export default reducer;
