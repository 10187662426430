import React from 'react';
import { thea300 } from '../../constants/colors';

interface Props {
  color?: string,
  style?: any
}

const CartFillIcon: React.FC<Props> = (props: Props) => {
  const { color, style } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      style={style}
    >
      <g data-name="Layer 2">
        <g data-name="shopping-cart">
          <path
            d="M21.08 7a2 2 0 0 0-1.7-1H6.58L6 3.74A1 1 0 0 0 5 3H3a1 1 0 0 0 0 2h1.24L7 15.26A1 1 0 0 0 8 16h9a1 1 0 0 0 .89-.55l3.28-6.56A2 2 0 0 0 21.08 7z"
            fill={color}
          />
          <circle
            cx={7.5}
            cy={19.5}
            r={1.5}
            fill={color}
          />
          <circle
            cx={17.5}
            cy={19.5}
            r={1.5}
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

const defaultProps: Props = {
  color: thea300,
  style: {},
};

CartFillIcon.defaultProps = defaultProps;

export default CartFillIcon;
