import { makeStyles } from '@material-ui/core/styles';
import {
  thea300, tonique2, tonique4,
} from '../../../constants/colors';

const useStyles = makeStyles(() => ({
  buttonEdit: {
    color: thea300,
    fontWeight: 500,
    fontFamily: 'FuturaPTMedium',
  },
  buttonDelete: {
    color: tonique2,
    fontWeight: 500,
    fontFamily: 'FuturaPTMedium',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  card: {
    display: 'flex',
    position: 'relative',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(100% - 157px)',
  },
  tag: {
    color: tonique4,
    backgroundColor: '#BBE2D5',
    fontSize: '14px',
    width: 'fit-content',
    borderRadius: '10px',
    padding: '10px',
    margin: '0px 10px 10px 0',
    display: 'inline-flex',
  },
  date: {
    textTransform: 'capitalize',
  },
  content: {
    flex: '1 0 auto',

    '& > p, & > h6': {
      paddingBottom: '10px',
    },
  },
  cover: {
    width: '157px',
  },
  description: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  event: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    alignItems: 'center',
    position: 'relative',
    width: '240px',
    textAlign: 'center',
  },
  icon: {
    position: 'absolute',
    right: '0',
    cursor: 'pointer',
  },
}));

export default useStyles;
