import React, { ReactNode } from 'react';
import { Paper } from '@material-ui/core';
import useStyles from './MainPaper.style';

type Props = {
  children: ReactNode
}

const MainPaper: React.FC<Props> = (props: Props) => {
  const { children } = props;
  const classes = useStyles();
  return (
    <Paper elevation={0} className={classes.root}>
      <div className={classes.content}>
        {children}
      </div>
    </Paper>
  );
};

export default MainPaper;
