/* common routes */
const apiBasePath = '/api/v1';
export const apiForgotPassword = `${apiBasePath}/forgot-password`;
export const apiRestPassword = `${apiBasePath}/reset-password`;
export const apiLoginCheck = `${apiBasePath}/login-check`;
export const apiRefreshToken = `${apiBasePath}/refresh-token`;
/* admin routes */
const apiAdminBasePath = '/api/admin';
export const apiAdminMe = `${apiAdminBasePath}/me`;
export const apiAdminMeChangePassword = `${apiAdminMe}/password`;
export const apiProducts = `${apiAdminBasePath}/products`;
export const apiProfessionalUnitOrders = `${apiAdminBasePath}/professional-unit-orders`;
export const apiProductCategories = `${apiAdminBasePath}/product-categories`;
export const apiRegions = `${apiAdminBasePath}/regions`;
export const apiExpertCourses = `${apiAdminBasePath}/expert-courses`;
export const apiTerritories = `${apiAdminBasePath}/territories`;
export const apiToolboxes = `${apiAdminBasePath}/tools`;
export const apiBooks = `${apiAdminBasePath}/books`;
export const apiBookOrders = `${apiAdminBasePath}/book-orders`;
export const apiEvents = `${apiAdminBasePath}/events`;
export const apiEventCategories = `${apiAdminBasePath}/event-categories`;
export const apiFlashInfos = `${apiAdminBasePath}/flash-infos`;
export const apiUsers = `${apiAdminBasePath}/users`;
export const apiAdmins = `${apiAdminBasePath}/admins`;
export const apiMarketingEmails = `${apiAdminBasePath}/marketing-emails`;
export const apiNotifications = `${apiAdminBasePath}/notifications`;
export const apiNotificationUsers = `${apiAdminBasePath}/notification-users`;
