import React from 'react';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import { hasToken } from '../services/AuthService';
import {
  routeUsers,
  routeLogin,
} from '../constants/routes';

type Props = {}

const DefaultPage: React.FC<Props> = () => {
  const history = useHistory();
  if (hasToken()) {
    history.replace(routeUsers);
  } else {
    history.replace(routeLogin);
  }
  return (<Typography variant="h1">Chargement</Typography>);
};

export default DefaultPage;
