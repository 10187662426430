import React from 'react';
import {
  thea300,
} from '../../constants/colors';

interface Props {
  color?: string,
  style?: any
}

const CheckMarkCircleFillIcon: React.FC<Props> = (props: Props) => {
  const { color, style } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24} style={style}>
      <g data-name="Layer 2">
        <g data-name="file">
          <rect width="24" height="24" opacity="0" />
          <path
            d="M16.2954,9.6055 L11.7274,15.6055 C11.5394,15.8525 11.2484,15.9985 10.9384,16.0005 L10.9314,16.0005 C10.6244,16.0005 10.3344,15.8585 10.1444,15.6165 L7.7124,12.5095 C7.3724,12.0755 7.4484,11.4465 7.8834,11.1065 C8.3174,10.7655 8.9474,10.8415 9.2874,11.2775 L10.9204,13.3635 L14.7044,8.3945 C15.0384,7.9555 15.6654,7.8695 16.1064,8.2045 C16.5454,8.5395 16.6304,9.1665 16.2954,9.6055 M12.0004,2.0005 C6.4774,2.0005 2.0004,6.4775 2.0004,12.0005 C2.0004,17.5225 6.4774,22.0005 12.0004,22.0005 C17.5234,22.0005 22.0004,17.5225 22.0004,12.0005 C22.0004,6.4775 17.5234,2.0005 12.0004,2.0005"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

const defaultProps: Props = {
  color: thea300,
  style: {},
};

CheckMarkCircleFillIcon.defaultProps = defaultProps;

export default CheckMarkCircleFillIcon;
