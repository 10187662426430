import {
  AxiosError,
  AxiosResponse,
} from 'axios';
import {
  DispatchType,
  IRegion,
  RegionAction,
} from './types';
import {
  STORE_REGION_FAILED,
  STORE_REGION_LOADED,
  STORE_REGION_PENDING,
} from './actionTypes';
import { apiGetRegions } from '../../api/apiRegion';

const regionFailedAction = (error: string): RegionAction => ({
  type: STORE_REGION_FAILED,
  error,
});

const regionPendingAction = (): RegionAction => ({
  type: STORE_REGION_PENDING,
});

const regionLoadedAction = (regions: IRegion[]): RegionAction => ({
  type: STORE_REGION_LOADED,
  regions,
});

// eslint-disable-next-line import/prefer-default-export
export const getRegions = () => (dispatch: DispatchType) => {
  dispatch(regionPendingAction());
  apiGetRegions()
    .then((data: AxiosResponse) => dispatch(regionLoadedAction(data.data)))
    .catch((error: AxiosError) => dispatch(regionFailedAction(error.message)));
};
