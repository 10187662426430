import React, { Dispatch } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Popper,
  ClickAwayListener,
  Fade,
  MenuList,
  MenuItem,
  Link,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import useStyles from './ToolboxTable.style';
import { IToolbox } from '../../../store/toolbox/types';
import MoreVerticalOutline from '../../../components/icon/MoreVerticalOutline';
import { deleteToolbox } from '../../../store/toolbox/actions';
import { routeToolbox } from '../../../constants/routes';
import getFileName from '../../../utils/getFileName';

type Props = {
  toolboxes: IToolbox[]
}

const ToolboxTable = ({ toolboxes }: Props) => {
  const { t } = useTranslation(['backoffice']);
  const classes = useStyles();
  const history = useHistory();
  const dispatch: Dispatch<any> = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [toolSelected, setToolSelected] = React.useState<IToolbox | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'popper-toolbox' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLElement>, toolbox: IToolbox) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setToolSelected(toolbox);
  };

  const handleClickEdit = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    history.push(`${routeToolbox}/edit/${toolSelected?.id}`);
  };

  const handleClickDelete = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    if (toolSelected) {
      dispatch(deleteToolbox(toolSelected.id));
    }
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  return (
    <TableContainer component={Paper} style={{ padding: '30px' }}>
      <Table aria-label="toolbox table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell}>Titre</TableCell>
            <TableCell className={classes.cell}>Lien PDF</TableCell>
            <TableCell className={classes.cell}>Ordre</TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {toolboxes.map((toolbox) => (
            <TableRow key={toolbox.id}>
              <TableCell component="th" scope="row">
                {toolbox.title}
              </TableCell>
              <TableCell component="th" scope="row">
                <Link href={toolbox.file_path} target="_blank" rel="noopener">
                  {getFileName(toolbox.file_path)}
                </Link>
              </TableCell>
              <TableCell component="th" scope="row">
                {toolbox.sort_order}
              </TableCell>
              <TableCell align="right">
                <IconButton
                  onClick={(e) => handleClick(e, toolbox)}
                >
                  <MoreVerticalOutline />
                </IconButton>

              </TableCell>
            </TableRow>
          ))}
          <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            style={{ zIndex: 2 }}
            transition
          >
            {({ TransitionProps }) => (
              <ClickAwayListener onClickAway={handleClickAway}>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <Fade {...TransitionProps} timeout={350}>
                  <Paper elevation={1}>
                    <MenuList>
                      <MenuItem
                        onClick={handleClickEdit}
                        className={classes.buttonEdit}
                      >
                        {t('common.edit')}
                      </MenuItem>
                      <MenuItem
                        onClick={handleClickDelete}
                        className={classes.buttonDelete}
                      >
                        {t('common.delete')}
                      </MenuItem>
                    </MenuList>
                  </Paper>
                </Fade>
              </ClickAwayListener>
            )}
          </Popper>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ToolboxTable;
