import { makeStyles } from '@material-ui/core/styles';
import image from '../../images/background.jpeg';
import { nuance150 } from '../../constants/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: nuance150,
    margin: 0,
    padding: 0,
    minWidth: '100vw',
    minHeight: '100vh',
    display: 'block',
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      backgroundSize: 'contain',
    },
  },
  background: {
    zIndex: 0,
    backgroundImage: `url(${image})`,
    backgroundSize: 'cover',
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    [theme.breakpoints.down('sm')]: {
      backgroundSize: 'contain',
    },
  },
}));

export default useStyles;
