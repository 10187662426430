import React from 'react';
import {
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { IProductCategory } from '../../../store/product/types';
import useStyles from './ProductCategory.style';

type Props = {
  category: IProductCategory,
  categoryIds: string[],
  /* eslint-disable no-unused-vars */
  handleChange: (categoryId: string) => void
}

const ProductCategory = ({ category, categoryIds, handleChange }: Props) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      className={classes.root}
      control={(
        <Checkbox
          checked={categoryIds.includes(category.id)}
          onChange={() => handleChange(category.id)}
          name={category.title}
          color="primary"
        />
      )}
      label={category.title}
    />
  );
};

export default ProductCategory;
