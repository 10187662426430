import { convertToRaw, EditorState, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import DOMPurify from 'dompurify';

export const editorStateToHtml = (editorState: EditorState) => {
  if (editorState === undefined || editorState === null) {
    return null;
  }
  if (editorState.getCurrentContent().hasText()) {
    const tmpVal = convertToRaw(editorState.getCurrentContent());
    return draftToHtml(tmpVal);
  }
  return null;
};

export const htmlToEditorState = (html:string) => {
  if (html === undefined || html === null || html.trim() === '') {
    return EditorState.createEmpty();
  }
  const contentBlock = htmlToDraft(html);
  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks,
    );
    return EditorState.createWithContent(contentState);
  }
  return EditorState.createEmpty();
};

export const htmlToString = (element: string) => {
  let htmlString = DOMPurify.sanitize(element);
  if (htmlString.length > 180) {
    htmlString = htmlString.slice(0, 180).concat('...');
  }

  return htmlString;
};
