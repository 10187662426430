import React, { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Typography, Grid, Paper, Popper, IconButton, MenuList, MenuItem, ClickAwayListener, Fade,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import useStyles from './BookItem.style';
import { IBook } from '../../../store/book/types';
import { disableBook, enableBook } from '../../../store/book/actions';
import { routeBooks } from '../../../constants/routes';
import MoreVerticalOutline from '../../../components/icon/MoreVerticalOutline';
import image from '../../../images/background_placeholder.jpg';

type Props = {
  book: IBook
};

const BookItem = ({ book } : Props) => {
  const { t } = useTranslation(['backoffice', 'common']);
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const dispatch: Dispatch<any> = useDispatch();

  const open = Boolean(anchorEl);
  const id = open ? 'popper-book' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickEdit = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    history.push(`${routeBooks}/edit/${book.id}`);
  };

  const handleClickDisable = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    dispatch(disableBook(book.id));
  };

  const handleClickEnable = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    dispatch(enableBook(book.id));
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  return (
    <Grid item>
      <Paper className={classes.book}>
        <img src={book.file_path || image} alt="" width={100} height={150} className={classes.picture} />
        {!book.is_orderable && (
          <Typography variant="subtitle2" className={classes.bookTitle} color="textPrimary">
            {t('book.is_not_orderable')}
          </Typography>
        )}
        <Typography variant="subtitle1" className={classes.bookTitle} color="primary">
          {book.title}
        </Typography>
        <IconButton
          className={classes.icon}
          onClick={handleClick}
        >
          <MoreVerticalOutline />
        </IconButton>

        <Popper id={id} open={open} anchorEl={anchorEl} style={{ zIndex: 2 }} transition>
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={handleClickAway}>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <Fade {...TransitionProps} timeout={350}>
                <Paper elevation={1}>
                  <MenuList>
                    <MenuItem onClick={handleClickEdit} className={classes.buttonEdit}>
                      {t('common.edit')}
                    </MenuItem>
                    {book.is_activated && (
                      <MenuItem onClick={handleClickDisable} className={classes.buttonDisable}>
                        {t('common.disable')}
                      </MenuItem>
                    )}
                    {!book.is_activated && (
                      <MenuItem onClick={handleClickEnable} className={classes.buttonDisable}>
                        {t('common.enable')}
                      </MenuItem>
                    )}
                  </MenuList>
                </Paper>
              </Fade>
            </ClickAwayListener>
          )}
        </Popper>
      </Paper>
    </Grid>
  );
};

export default BookItem;
