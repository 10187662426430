import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import useStyles from './NotificationUserModal.style';
import { IUser } from '../../../store/user/types';

type Props = {
  affectedUsers: IUser[]
}

const NotificationUserTable = ({ affectedUsers }: Props) => {
  const { t } = useTranslation(['backoffice']);
  const classes = useStyles();

  return (
    <TableContainer style={{ padding: '30px' }}>
      <Table aria-label="affected user table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell}>
              {t('notification.affectedUsersModal.table.createdAt')}
            </TableCell>
            <TableCell className={classes.cell}>
              {t('notification.affectedUsersModal.table.lastName')}
            </TableCell>
            <TableCell className={classes.cell}>
              {t('notification.affectedUsersModal.table.firstName')}
            </TableCell>
            <TableCell className={classes.cell}>
              {t('notification.affectedUsersModal.table.rpps')}
            </TableCell>
            <TableCell className={classes.cell}>
              {t('notification.affectedUsersModal.table.email')}
            </TableCell>
            <TableCell className={classes.cell}>
              {t('notification.affectedUsersModal.table.phone')}
            </TableCell>
            <TableCell className={classes.cell}>
              {t('notification.affectedUsersModal.table.role')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {affectedUsers.map((user) => (
            <TableRow key={user.id}>
              <TableCell component="th" scope="row">
                {user.created_at && format(new Date(user.created_at), 'dd-MM-yyyy')}
              </TableCell>
              <TableCell component="th" scope="row">
                {user.last_name}
              </TableCell>
              <TableCell component="th" scope="row">
                {user.first_name}
              </TableCell>
              <TableCell component="th" scope="row">
                {user.rpps}
              </TableCell>
              <TableCell component="th" scope="row">
                {user.email}
              </TableCell>
              <TableCell component="th" scope="row">
                {user.phone}
              </TableCell>
              <TableCell component="th" scope="row">
                {user.roles.filter((role) => role !== 'ROLE_USER').join(' / ')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default NotificationUserTable;
