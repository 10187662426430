import React, { ReactNode } from 'react';
import {
  CircularProgress,
  Paper,
  Typography,
} from '@material-ui/core';
import useStyles from './WhitePaper.style';

type Props = {
  children: ReactNode,
  title: string,
  loading?: boolean
}

const WhitePaper: React.FC<Props> = (props: Props) => {
  const { children, title, loading } = props;
  const classes = useStyles();
  return (
    <Paper elevation={0} className={classes.root}>
      <div className={classes.content}>
        <Typography variant="h4" className={classes.title}>
          {title}
        </Typography>
        {children}
      </div>
      {loading && (
        <div className={classes.disabledContainer}>
          <CircularProgress
            size={24}
            color="primary"
            className={classes.disabledContainerProgress}
          />
        </div>
      )}
    </Paper>
  );
};

const defaultProps: Partial<Props> = {
  loading: false,
};
WhitePaper.defaultProps = defaultProps;

export default WhitePaper;
