import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import useStyles from './EventModalSubscriptions.style';
import { Subscription } from '../../../store/event/types';

type Props = {
  subscriptions: Subscription[]
}

const EventTableSubscriptions = ({ subscriptions }: Props) => {
  const { t } = useTranslation(['backoffice']);
  const classes = useStyles();

  return (
    <TableContainer style={{ padding: '30px' }}>
      <Table aria-label="subscription table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell}>{t('event.subscription.table.createdAt')}</TableCell>
            <TableCell className={classes.cell}>{t('event.subscription.table.lastName')}</TableCell>
            <TableCell className={classes.cell}>{t('event.subscription.table.firstName')}</TableCell>
            <TableCell className={classes.cell}>{t('event.subscription.table.rpps')}</TableCell>
            <TableCell className={classes.cell}>{t('event.subscription.table.email')}</TableCell>
            <TableCell className={classes.cell}>{t('event.subscription.table.phone')}</TableCell>
            <TableCell className={classes.cell}>{t('event.subscription.table.response')}</TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {subscriptions.map((subscription) => (
            <TableRow key={subscription.id}>
              <TableCell component="th" scope="row">
                {subscription.created_at && format(new Date(subscription.created_at), 'dd-MM-yyyy')}
              </TableCell>
              <TableCell component="th" scope="row">
                {subscription.user.last_name}
              </TableCell>
              <TableCell component="th" scope="row">
                {subscription.user.first_name}
              </TableCell>
              <TableCell component="th" scope="row">
                {subscription.user.rpps}
              </TableCell>
              <TableCell component="th" scope="row">
                {subscription.user.email}
              </TableCell>
              <TableCell component="th" scope="row">
                {subscription.user.phone}
              </TableCell>
              <TableCell component="th" scope="row">
                {subscription.responses[0]?.response}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EventTableSubscriptions;
