import React, {
  Dispatch,
  FormEvent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  DefaultRootState,
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useHistory,
  useParams,
} from 'react-router';
import {
  convertToRaw,
  EditorState,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import useStyles from '../../../components/paper/MainPaper.style';
import WhitePaper from '../../../components/paper/WhitePaper';
import GreyTextInput from '../../../components/form/GreyTextInput';
import UploadFileButton from '../../../components/form/UploadFileButton';
import {
  getFlashInfos,
  postFlashInfo,
  putFlashInfo,
  resetStatus,
} from '../../../store/flash-info/actions';
import {
  FlashInfoState,
  IFlashInfo,
} from '../../../store/flash-info/types';
import { routeFlashInfos } from '../../../constants/routes';
import Breadcrumb from '../../../components/common/Breadcrumb';
import { NotificationContext } from '../../../contexts/NotificationContext';
import getFileName from '../../../utils/getFileName';
import DatePicker from '../../../components/form/DatePicker';
import EditorComponent from '../../../components/form/EditorComponent';
import SwitchComponent from '../../../components/form/SwitchComponent';
import { htmlToEditorState } from '../../../utils/editorHelper';
import formatDateWithTimezone from '../../../utils/formatDate';

type Props = {};

type Picture = {
  file?: File | string,
  preview: string,
  fileName: string
}

const FormFlashInfoPage: React.FC<Props> = () => {
  const { t } = useTranslation(['backoffice', 'common']);
  const classes = useStyles();
  const history = useHistory();
  const dispatch: Dispatch<any> = useDispatch();
  const slug: { id: string } = useParams();
  const { setNotification } = useContext(NotificationContext);

  const {
    sending,
    flashInfos,
    error,
    status,
  } = useSelector<DefaultRootState, FlashInfoState>(
    (state: any) => state.flashInfoStore,
  );

  useEffect(() => {
    if (flashInfos.length === 0) dispatch(getFlashInfos());
  }, []);

  const flashInfoId = slug.id;
  let flashInfo: IFlashInfo | undefined;

  if (flashInfoId) {
    flashInfo = flashInfos.find((p: IFlashInfo) => p.id === flashInfoId);
  }

  const getValuesFromFlashInfo = (from: IFlashInfo | undefined) => ({
    title: from?.title ?? '',
    description: from
      ? htmlToEditorState(from.description as string)
      : EditorState.createEmpty(),
    start_date: from?.start_date ? new Date(from.start_date) : new Date(),
    end_date: from?.end_date ? new Date(from.end_date) : null,
    display_end_date: from?.display_end_date ?? true,
  });

  const [values, setValues] = useState<Pick<IFlashInfo, 'title' | 'description' | 'start_date' | 'end_date' | 'display_end_date'>>(
    getValuesFromFlashInfo(flashInfo),
  );

  const [formErrors, setFormErrors] = useState({
    title: '',
    description: '',
    start_date: '',
  });

  const getPictureFromFlashInfo = (from: IFlashInfo | undefined) => ({
    file: from?.file_path ?? undefined,
    preview: from?.file_path ?? '',
    fileName: getFileName(from?.file_path) ?? '',
  });

  const [picture, setPicture] = useState<Picture>(
    getPictureFromFlashInfo(flashInfo),
  );

  useEffect(() => {
    flashInfo = flashInfos.find((p: IFlashInfo) => p.id === flashInfoId);
    setValues(getValuesFromFlashInfo(flashInfo));
    setPicture(getPictureFromFlashInfo(flashInfo));
  }, [flashInfos]);

  const handleFormAction = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    let formError = false;

    if (values.title === '') {
      formErrors.title = t('flashInfo.form.titleRequired');
      formError = true;
    }

    if (draftToHtml(convertToRaw(
      (values.description as EditorState)
        .getCurrentContent(),
    )).length === 8) {
      formErrors.description = t('flashInfo.form.descriptionRequired');
      formError = true;
    }

    if (values.start_date === null) {
      formErrors.start_date = t('flashInfo.form.startDateRequired');
      formError = true;
    }

    setFormErrors({ ...formErrors });

    if (!formError) {
      const content = {
        title: values.title,
        start_date: formatDateWithTimezone(values.start_date as Date),
        end_date: values.end_date ? formatDateWithTimezone(values.end_date as Date) : null,
        display_end_date: values.display_end_date,
        description: draftToHtml(convertToRaw(
          (values.description as EditorState)
            .getCurrentContent(),
        )),
      };
      if (flashInfoId) {
        dispatch(putFlashInfo(flashInfoId, content, picture.file));
      } else {
        dispatch(postFlashInfo(content, picture.file as File));
      }
    }
  };

  const handleChange = (prop: keyof Partial<IFlashInfo>, value: any) => {
    setValues({ ...values, [prop]: value });
    setFormErrors({ ...formErrors, [prop]: '' });
  };

  const uploadFile = (file: File) => {
    if (file) {
      setPicture({ file, preview: URL.createObjectURL(file), fileName: file.name });
    }
  };

  useEffect(() => {
    if (status === 'ERROR') {
      setNotification({ isOpened: true, severity: 'error', message: error || '' });
    }

    if (status === 'SUCCESS') {
      setNotification({
        isOpened: true,
        severity: 'success',
        message: t('flashInfo.form.success'),
      });
      history.push(routeFlashInfos);
    }
    if (status !== 'INIT') dispatch(resetStatus());
  }, [status]);

  return (
    <>
      <Breadcrumb
        title={t('title.flashInfo')}
        baseUrl={routeFlashInfos}
        link={flashInfo && flashInfo.title}
      />
      <Typography variant="h1" className={classes.title}>
        {flashInfo && flashInfo.title}
      </Typography>
      <form onSubmit={handleFormAction}>
        <Grid container spacing={4}>
          <Grid item id="form-grid-item" xs={12} md={9}>

            <Grid container direction="column" spacing={4}>
              <Grid item xs={12}>
                <WhitePaper title={t('flashInfo.form.subtitle')}>
                  <GreyTextInput
                    type="text"
                    value={values.title}
                    label={t('flashInfo.form.title')}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('title', event.target.value)}
                    error={formErrors.title !== ''}
                    helperText={formErrors.title}
                  />
                  <EditorComponent
                    value={values.description}
                    label={t('flashInfo.form.description')}
                    onChange={(editor: EditorState) => handleChange('description', editor)}
                    error={formErrors.description !== ''}
                    helperText={formErrors.description}
                  />
                  <DatePicker
                    value={values.start_date}
                    onChange={(date: MaterialUiPickersDate) => handleChange('start_date', date)}
                    error={formErrors.start_date !== ''}
                    helperText={formErrors.start_date}
                    label={t('flashInfo.form.startDate')}
                  />
                  <DatePicker
                    value={values.end_date}
                    onChange={(date: MaterialUiPickersDate) => handleChange('end_date', date)}
                    label={t('flashInfo.form.endDate')}
                  />
                  <SwitchComponent
                    value={values.display_end_date}
                    onChange={(e: any) => handleChange('display_end_date', e.target.checked)}
                    label={t('flashInfo.form.display_end_date')}
                    name="display_end_date"
                  />
                </WhitePaper>
              </Grid>
              <Grid item xs={12}>
                <WhitePaper title={t('flashInfo.form.subtitlePicture')}>
                  {picture.preview && (
                    <img
                      src={picture.preview}
                      alt={picture.fileName}
                      width="auto"
                      height={115}
                      className={classes.preview}
                    />
                  )}
                  <UploadFileButton
                    uploadFile={uploadFile}
                    label={t('common.addPicture')}
                  />
                </WhitePaper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item id="validation-grid-item" xs={12} md={3}>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              disabled={sending}
              fullWidth
            >
              <Typography variant="button">
                {t('common.save')}
              </Typography>
              {
                sending
                && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                    color="primary"
                  />
                )
              }
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default FormFlashInfoPage;
