import { makeStyles } from '@material-ui/core/styles';
import { nuance500 } from '../../constants/colors';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    textAlign: 'center',
    paddingTop: '50px',
  },
  title: {
    color: nuance500,
    marginBottom: '5px',
  },
  progress: {
    margin: 'auto',
    widht: '100%',
    maxWidth: '500px',
  },
}));

export default useStyles;
