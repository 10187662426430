import { makeStyles } from '@material-ui/core/styles';
import { thea75, thea300 } from '../../constants/colors';

export const iconStyle = {
  marginRight: '0.5rem',
};

const useStyles = makeStyles(() => ({
  input: {
    display: 'none',
  },
  label: {
    cursor: 'pointer',
    backgroundColor: thea300,
    color: thea75,
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    fontWeight: 'bolder',
    fontSize: '17px',
    borderRadius: '30px',
    padding: '11.5px 19px',
    minWidth: '196px',
    marginBottom: '20px',
  },
  helperText: {
    width: '100%',
    position: 'relative',
    marginLeft: '3px',
    // top: '-5px',
  },
}));

export default useStyles;
