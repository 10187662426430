export const STORE_PRODUCT_ADDED_SENT = 'store_product_added_sent';
export const STORE_PRODUCT_CATEGORIES_LOADED = 'store_product_categories_loaded';
export const STORE_PRODUCT_DELETED = 'store_product_deleted';
export const STORE_PRODUCT_FAILED = 'store_product_failed';
export const STORE_PRODUCT_PENDING = 'store_product_pending';
export const STORE_PRODUCT_LOADED = 'store_product_loaded';
export const STORE_PRODUCT_RESET_STATUS = 'store_product_reset_status';
export const STORE_PRODUCT_SENDING = 'store_product_sending';
export const STORE_PRODUCT_UPDATED_SENT = 'store_product_updated_sent';
export const STORE_PRODUCT_UPDATED_FILTERS = 'store_product_updated_filters';
