import React from 'react';
import { thea300 } from '../../constants/colors';

interface Props {
  color?: string,
  style?: any,
}

const MoreVerticalOutline: React.FC<Props> = ({
  color, style,
}: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={style}>
    <g data-name="Layer 2">
      <g data-name="more-vertical">
        <rect width="24" height="24" transform="rotate(-90 12 12)" opacity="0" />
        <circle cx="12" cy="12" r="2" fill={color} />
        <circle cx="12" cy="5" r="2" fill={color} />
        <circle cx="12" cy="19" r="2" fill={color} />
      </g>
    </g>
  </svg>
);

const defaultProps: Props = {
  color: thea300,
  style: {},
};

MoreVerticalOutline.defaultProps = defaultProps;

export default MoreVerticalOutline;
