import React, {
  Dispatch,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  DefaultRootState,
  useDispatch,
  useSelector,
} from 'react-redux';
import { useHistory } from 'react-router';
import useStyles from '../../../components/paper/MainPaper.style';
import ProgressContainer from '../../../components/common/ProgressContainer';
import { NotificationContext } from '../../../contexts/NotificationContext';
import { routeEventsAdd } from '../../../constants/routes';
import {
  deleteEvent,
  getEventsAndCategories,
  resetStatus,
} from '../../../store/event/actions';
import { EventState } from '../../../store/event/types';
import PlusIcon from '../../../components/icon/PlusIcon';
import EventItem from './EventItem';
import EventCategories from './EventCategories';
import {
  futureEventsFilteredSelector,
  pastEventsFilteredSelector,
} from '../../../store/event/selectors';
import EventModalDelete from './EventModalDelete';
import { RegionState } from '../../../store/region/types';
import { getRegions } from '../../../store/region/actions';

type Props = {};

const EventsPage: React.FC<Props> = () => {
  const { t } = useTranslation(['backoffice']);
  const classes = useStyles();
  const history = useHistory();
  const dispatch: Dispatch<any> = useDispatch();
  const {
    events,
    pending,
    categories,
    error,
    status,
    categoryIds,
  } = useSelector<DefaultRootState, EventState>(
    (state: any) => state.eventStore,
  );

  const regionStore = useSelector<DefaultRootState, RegionState>(
    (state: any) => state.regionStore,
  );

  const [open, setOpen] = useState(false);
  const [eventId, setEventId] = useState('');

  const handleClickOpenModal = (id: string) => {
    setOpen(true);
    setEventId(id);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setEventId('');
  };

  const handleDeleteEvent = () => {
    dispatch(deleteEvent(eventId));
    setOpen(false);
  };

  const futureEventsFiltered = useSelector(futureEventsFilteredSelector);
  const pastEventsFiltered = useSelector(pastEventsFilteredSelector);

  const { setNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (events.length === 0) {
      dispatch(getEventsAndCategories());
    }
    if (regionStore.regions.length === 0) {
      dispatch(getRegions());
    }
  }, []);

  useEffect(() => {
    if (status === 'ERROR') {
      setNotification({ isOpened: true, severity: 'error', message: error || '' });
    }
    if (status !== 'INIT') dispatch(resetStatus());
  }, [status]);

  return (
    <>
      <Typography variant="h1" className={classes.title}>
        {t('title.events')}
        <Button
          variant="contained"
          color="primary"
          startIcon={<PlusIcon width={22} height={22} />}
          className={classes.buttonAdd}
          onClick={() => history.push(routeEventsAdd)}
        >
          <Typography variant="button">
            {t('event.buttonAdd')}
          </Typography>
        </Button>
      </Typography>

      <ProgressContainer loading={pending}>
        <Grid container spacing={4}>
          <Grid item xs={9}>
            <Grid container spacing={4} direction="column">
              {futureEventsFiltered.length !== 0 && (
                <>
                  <Typography variant="h4" className={classes.title}>{t('event.coming')}</Typography>
                  {futureEventsFiltered.map(((event) => (
                    <EventItem
                      event={event}
                      key={event.id}
                      categories={categories}
                      handleClickOpenModal={handleClickOpenModal}
                    />
                  )))}
                </>
              )}
              {pastEventsFiltered.length !== 0 && (
                <>
                  <Typography variant="h4" className={classes.title}>{t('event.past')}</Typography>
                  {pastEventsFiltered.map(((event) => (
                    <EventItem
                      event={event}
                      key={event.id}
                      categories={categories}
                      handleClickOpenModal={handleClickOpenModal}
                    />
                  )))}
                </>
              )}
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Grid container>
              <EventCategories categories={categories} categoryIds={categoryIds} />
            </Grid>
          </Grid>
        </Grid>
        <EventModalDelete
          open={open}
          handleClose={handleCloseModal}
          handleValidate={handleDeleteEvent}
        />
      </ProgressContainer>
    </>
  );
};

export default EventsPage;
