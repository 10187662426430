import React from 'react';
import {
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { eventCategories } from '../../../store/event/types';
import useStyles from './EventCategory.style';

type Props = {
  category: eventCategories,
  categoryIds: string[],
  /* eslint-disable no-unused-vars */
  handleChange: (categoryId: string) => void
}

const EventCategory = ({ category, categoryIds, handleChange }: Props) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      className={classes.root}
      control={(
        <Checkbox
          checked={categoryIds.includes(category.id)}
          onChange={() => handleChange(category.id)}
          name={category.name}
          color="primary"
        />
      )}
      label={category.name}
    />
  );
};

export default EventCategory;
