import React from 'react';
import {
  thea300,
} from '../../constants/colors';

interface Props {
  color?: string,
  style?: any,
  width?: number,
  height?: number,
}

const NavigationFillIcon: React.FC<Props> = (props: Props) => {
  const {
    color, style, width, height,
  } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={width} height={height} style={style}>
      <g data-name="Layer 2">
        <g data-name="file">
          <rect width="24" height="24" opacity="0" />
          <path
            d="M13.667,22 C13.646,22 13.626,21.999 13.606,21.998 C13.154,21.971 12.777,21.643 12.687,21.199 L11.149,13.632 C11.069,13.238 10.762,12.931 10.368,12.851 L2.801,11.312 C2.357,11.223 2.029,10.846 2.002,10.394 C1.975,9.941 2.254,9.527 2.684,9.385 L18.684,4.052 C19.043,3.93 19.439,4.024 19.707,4.293 C19.975,4.561 20.068,4.957 19.949,5.316 L14.615,21.316 C14.479,21.727 14.095,22 13.667,22"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

const defaultProps: Props = {
  color: thea300,
  style: {},
  width: 24,
  height: 24,
};

NavigationFillIcon.defaultProps = defaultProps;

export default NavigationFillIcon;
