export const STORE_USER_PENDING = 'store_user_pending';
export const STORE_USER_FAILED = 'store_user_failed';
export const STORE_USER_RECEIVED = 'store_user_received';
export const STORE_USER_RESET_STATUS = 'store_user_reset_status';
export const STORE_USER_SAVED = 'store_user_saved';
export const STORE_USER_DELETED = 'store_user_deleted';
export const STORE_USER_ADMIN_SAVED = 'store_user_admin_saved';
export const STORE_USER_EMAIL_MARKETING_RECEIVED = 'store_user_email_marketing_received';
export const STORE_USER_EMAIL_MARKETING_PENDING = 'store_user_email_marketing_pending';
export const STORE_USER_EMAIL_MARKETING_FAILED = 'store_user_email_marketing_failed';
export const STORE_USER_EMAIL_MARKETING_SAVED = 'store_user_email_marketing_saved';
export const STORE_USER_EMAIL_MARKETING_DELETED = 'store_user_email_marketing_deleted';
