import React from 'react';
import {
  tonique3,
} from '../../constants/colors';

interface Props {
  color?: string,
  style?: any,
  width?: number,
  height?: number,
}

const AlertCircleFillIcon: React.FC<Props> = (props: Props) => {
  const {
    color, style, width, height,
  } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={width} height={height} style={style}>
      <g data-name="Layer 2">
        <g data-name="file-text">
          <rect width="24" height="24" opacity="0" />
          <path
            d="M13,13 C13,13.552 12.552,14 12,14 C11.448,14 11,13.552 11,13 L11,8 C11,7.448 11.448,7 12,7 C12.552,7 13,7.448 13,8 L13,13 Z M12,17 C11.448,17 11,16.552 11,16 C11,15.448 11.448,15 12,15 C12.552,15 13,15.448 13,16 C13,16.552 12.552,17 12,17 L12,17 Z M12,2 C6.477,2 2,6.477 2,12 C2,17.523 6.477,22 12,22 C17.523,22 22,17.523 22,12 C22,6.477 17.523,2 12,2 L12,2 Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

const defaultProps: Props = {
  color: tonique3,
  style: {},
  width: 24,
  height: 24,
};

AlertCircleFillIcon.defaultProps = defaultProps;

export default AlertCircleFillIcon;
