import {
  UserAction,
  UserState,
  IUser,
} from './types';

import {
  STORE_USER_SAVED,
  STORE_USER_FAILED,
  STORE_USER_PENDING,
  STORE_USER_RESET_STATUS,
  STORE_USER_RECEIVED,
  STORE_USER_EMAIL_MARKETING_RECEIVED,
  STORE_USER_EMAIL_MARKETING_SAVED,
  STORE_USER_EMAIL_MARKETING_DELETED,
  STORE_USER_DELETED,
  STORE_USER_ADMIN_SAVED,
} from './actionTypes';

const initialState: UserState = {
  error: null,
  pending: false,
  users: [],
  status: 'INIT',
  emailsAuthorized: [],
};

const reducer = (state: UserState = initialState, action: UserAction): UserState => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case STORE_USER_PENDING:
      return {
        ...state,
        pending: true,
      };
    case STORE_USER_RESET_STATUS:
      return {
        ...state,
        status: 'INIT',
      };
    case STORE_USER_FAILED:
      if (action.error !== undefined) {
        return {
          ...state,
          pending: false,
          error: action.error,
          status: 'ERROR',
        };
      }
      return state;

    case STORE_USER_SAVED:
      if (action.user !== undefined) {
        return {
          ...state,
          users: state.users
            .map(
              (user) => (user.id === action.user!.id ? action.user : user),
            ) as IUser[],
          status: 'SUCCESS',
        };
      }
      return state;
    case STORE_USER_ADMIN_SAVED:
      if (action.user !== undefined) {
        return {
          ...state,
          users: [...state.users, action.user],
          status: 'SUCCESS',
        };
      }
      return state;
    case STORE_USER_RECEIVED:
      if (action.users !== undefined) {
        return {
          ...state,
          pending: false,
          users: action.users,
        };
      }
      return state;

    case STORE_USER_DELETED:
      if (action.userId !== undefined) {
        return {
          ...state,
          users: state.users.filter((user) => user.id !== action.userId),
        };
      }
      return state;

    case STORE_USER_EMAIL_MARKETING_RECEIVED:
      if (action.emails !== undefined) {
        return {
          ...state,
          pending: false,
          emailsAuthorized: action.emails,
        };
      }
      return state;

    case STORE_USER_EMAIL_MARKETING_SAVED:
      if (action.email !== undefined) {
        return {
          ...state,
          emailsAuthorized: [...state.emailsAuthorized, action.email],
          status: 'SUCCESS',
        };
      }
      return state;

    case STORE_USER_EMAIL_MARKETING_DELETED:
      if (action.emailId !== undefined) {
        return {
          ...state,
          emailsAuthorized: state.emailsAuthorized.filter((email) => email.id !== action.emailId),
        };
      }
      return state;
  }
  return state;
};

export default reducer;
