import { makeStyles } from '@material-ui/core/styles';
import {
  thea300, thea500, tonique2,
} from '../../../constants/colors';

const useStyles = makeStyles(() => ({
  cell: {
    color: thea500,
    fontFamily: 'FuturaPTMedium',
  },
  buttonEdit: {
    color: thea300,
    fontWeight: 500,
    fontFamily: 'FuturaPTMedium',
  },
  buttonDelete: {
    color: tonique2,
    fontWeight: 500,
    fontFamily: 'FuturaPTMedium',
  },
}));

export default useStyles;
