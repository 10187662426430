import axios from 'axios';
import {
  apiEventCategories,
  apiEvents,
} from '../constants/api';
import { getToken } from '../services/AuthService';
import {
  IEvent,
  IEventPush,
} from '../store/event/types';

export const apiGetEvents = async () => {
  const token: string = await getToken();
  const data = await axios.get(
    `${process.env.REACT_APP_API_ROOT}${apiEvents}`,
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return data;
};

export const apiGetEventsCategories = async () => {
  const token: string = await getToken();
  const data = await axios.get(
    `${process.env.REACT_APP_API_ROOT}${apiEventCategories}`,
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return data;
};

export const apiGetEventsSubscriptions = async (eventId: string, format: 'json' | 'csv' = 'json') => {
  const token: string = await getToken();
  const data = await axios.get(
    `${process.env.REACT_APP_API_ROOT}${apiEvents}/${eventId}/subscriptions?format=${format}`,
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return data;
};

export const apiPostEvent = async (content: Partial<IEvent>) => {
  const token: string = await getToken();

  const data = await axios.post(
    `${process.env.REACT_APP_API_ROOT}${apiEvents}`,
    content,
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return data;
};

export const apiEditEvent = async (eventId: string, content: Partial<IEvent>) => {
  const token: string = await getToken();

  const data = await axios.put(
    `${process.env.REACT_APP_API_ROOT}${apiEvents}/${eventId}`,
    content,
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return data;
};

export const apiDeleteEvent = async (id: string) => {
  const token: string = await getToken();
  const data = await axios.delete(
    `${process.env.REACT_APP_API_ROOT}${apiEvents}/${id}`,
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return data;
};

export const apiPostPictureEvent = async (eventId: string, picture: File) => {
  const token: string = await getToken();

  const formData = new FormData();
  formData.append('picture', picture);

  const data = await axios.post(
    `${process.env.REACT_APP_API_ROOT}${apiEvents}/${eventId}`,
    formData,
    { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/x-www-form-urlencoded' } },
  );
  return data;
};

export const apiDeletePictureEvent = async (eventId: string, pictureId: string) => {
  const token: string = await getToken();
  const data = await axios.delete(
    `${process.env.REACT_APP_API_ROOT}${apiEvents}/${eventId}/pictures/${pictureId}`,
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return data;
};

export const apiPostPushEvent = async (eventId: string, content: Partial<IEventPush>) => {
  const token: string = await getToken();

  await axios.post(
    `${process.env.REACT_APP_API_ROOT}${apiEvents}/${eventId}/push`,
    content,
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return true;
};

export const apiPostPushStartEvent = async (eventId: string) => {
  const token: string = await getToken();

  const data = axios.post(
    `${process.env.REACT_APP_API_ROOT}${apiEvents}/${eventId}/start-push`,
    {},
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return data;
};
