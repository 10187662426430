import {
  MeAction,
  MeState,
} from './types';
import {
  STORE_ME_SAVED,
  STORE_ME_FAILED,
  STORE_ME_PENDING,
  STORE_ME_SENDING,
  STORE_ME_RESET_STATUS,
  STORE_ME_RECEIVED,
} from './actionTypes';

const initialState: MeState = {
  error: null,
  pending: false,
  sending: false,
  me: null,
  status: 'INIT',
};

const reducer = (state: MeState = initialState, action: MeAction): MeState => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case STORE_ME_PENDING:
      return {
        ...state,
        pending: true,
      };
    case STORE_ME_RESET_STATUS:
      return {
        ...state,
        status: 'INIT',
      };
    case STORE_ME_SENDING:
      return {
        ...state,
        sending: true,
      };
    case STORE_ME_FAILED:
      if (action.error !== undefined) {
        return {
          ...state,
          pending: false,
          sending: false,
          error: action.error,
          status: 'ERROR',
        };
      }
      return state;
    case STORE_ME_SAVED:
      if (action.me !== undefined) {
        return {
          ...state,
          sending: false,
          me: action.me,
          status: 'SUCCESS',
        };
      }
      return state;
    case STORE_ME_RECEIVED:
      if (action.me !== undefined) {
        return {
          ...state,
          pending: false,
          me: action.me,
        };
      }
      return state;
  }
  return state;
};

export default reducer;
