import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    right: '-20px',
    top: '-20px',
  },
  popper: {
    zIndex: 2,
  },
  paper: {
    marginTop: '10px',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  item: {
    minWidth: 250,
    textAlign: 'left',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
}));

export default useStyles;
