import {
  createMuiTheme,
  ThemeOptions,
} from '@material-ui/core/styles';

import {
  feedbackError,
  feedbackInfo,
  feedbackSuccess,
  feedbackWarning,
  nuance100,
  nuance200,
  nuance300,
  nuance500,
  thea200,
  thea300,
  thea400,
  thea75,
  theaBoxShadow,
  tonique3,
} from './constants/colors';

export const jsonTheme: ThemeOptions = {
  palette: {
    primary: {
      light: thea200,
      main: thea300,
      dark: thea400,
      contrastText: nuance100,
    },
    secondary: {
      light: tonique3,
      main: tonique3,
      dark: tonique3,
      contrastText: nuance100,
    },
    error: {
      light: feedbackError,
      main: feedbackError,
      dark: feedbackError,
      contrastText: nuance100,
    },
    warning: {
      light: feedbackWarning,
      main: feedbackWarning,
      dark: feedbackWarning,
      contrastText: nuance100,
    },
    info: {
      light: feedbackInfo,
      main: feedbackInfo,
      dark: feedbackInfo,
      contrastText: nuance100,
    },
    success: {
      light: feedbackSuccess,
      main: feedbackSuccess,
      dark: feedbackSuccess,
      contrastText: nuance100,
    },
  },
  typography: {
    fontFamily: '"Open Sans", "FuturaPTMedium", "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontFamily: '"FuturaPTMedium", "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: '33px',
      lineHeight: '38px',
      letterSpacing: '-0.19px',
      color: nuance500,
    },
    h2: {
      fontFamily: '"FuturaDemiBold", "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: '26px',
      lineHeight: '36px',
      letterSpacing: 'normal',
    },
    h3: {
      fontFamily: '"FuturaPTMedium", "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: '26px',
      lineHeight: '36px',
      letterSpacing: 'normal',
      display: 'initial',
    },
    h4: {
      fontFamily: '"FuturaPTMedium", "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: '18px',
      lineHeight: '25px',
      letterSpacing: 'normal',
      color: nuance500,
    },
    h5: {
      fontFamily: '"FuturaPTMedium", "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: '18px',
      lineHeight: '25px',
      letterSpacing: 'normal',
      color: nuance100,
    },
    h6: {
      fontFamily: '"FuturaPTBold", "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '2px',
      textTransform: 'uppercase',
      color: thea300,
    },
    subtitle1: {
      fontFamily: '"FuturaPTDemiBold", "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: '16px',
      lineHeight: '20px',
      letterSpacing: 0,
      color: nuance500,
    },
    subtitle2: {
      fontFamily: '"FuturaPTDemiBold", "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: '14px',
      lineHeight: '18px',
      letterSpacing: 0,
      color: thea300,
    },
    body1: {
      fontFamily: '"FuturaPTBook", "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: '16px',
      lineHeight: '20px',
      letterSpacing: 0,
      color: nuance200,
    },
    body2: {
      fontFamily: '"FuturaPTMedium", "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: '16px',
      lineHeight: '18px',
      letterSpacing: 'normal',
      color: nuance300,
    },
    button: {
      fontFamily: '"FuturaDemiBold", "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
      // fontSize: '17px',
      // lineHeight: '17px',
      // letterSpacing: '0.5px',
      // color: nuance100,
      fontWeight: 'bold',
      fontSize: '16px',
      lineHeight: '25px',
      letterSpacing: 'normal',
      textTransform: 'inherit',
    },
    caption: {},
    overline: {},
  },
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: '30px',
      },
      elevation1: {
        boxShadow: theaBoxShadow,
      },
    },
    MuiTable: {
      root: {
        backgroundColor: nuance100,
      },
    },
    MuiTableCell: {
      root: {
        fontSize: '16px',
      },
    },
    MuiTableBody: {
      root: {
        '& tr:last-child > th, & tr:last-child > td ': {
          borderBottom: 0,
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: '30px',
        padding: '11.5px 19px',
        minWidth: '196px',
        marginBottom: '20px',
        color: nuance100,
        textTransform: 'none',
      },
      label: {
        textTransform: 'inherit',
      },
      containedPrimary: {
        backgroundColor: thea300,
        boxShadow: theaBoxShadow,
      },
      containedSecondary: {
        backgroundColor: tonique3,
        boxShadow: theaBoxShadow,
      },
      outlined: {
        padding: '11.5px 19px',
        '&:hover': {
          padding: '11.5px 19px',
        },
      },
      outlinedPrimary: {
        borderWidth: '2px',
        color: thea300,
        textTransform: 'uppercase',
        '&:hover': {
          borderWidth: '2px',
        },
      },
    },
    MuiLink: {
      root: {
        textDecoration: 'underline',
      },
    },
    // MuiFormHelperText: {
    //   root: {
    //     '&.Mui-error': {
    //       marginLeft: '-15px',
    //     },
    //   },
    // },
    MuiMenuItem: {
      root: {
        marginTop: '15px',
        marginBottom: '15px',
      },
    },
    MuiFab: {
      root: {
        boxShadow: theaBoxShadow,
        backgroundColor: thea300,
        color: thea75,
        '&:hover': {
          backgroundColor: thea400,
        },
      },
      primary: {
        backgroundColor: thea75,
        color: thea300,
        '&:hover': {
          backgroundColor: thea300,
          color: thea75,
        },
      },
    },
  },
};
const theme = createMuiTheme(jsonTheme);
export default theme;
