import React, {
  Dispatch,
  useContext,
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Fab,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useHistory } from 'react-router';
import {
  DefaultRootState,
  useDispatch,
  useSelector,
} from 'react-redux';
import useStyles from '../../../components/paper/MainPaper.style';
import { NotificationContext } from '../../../contexts/NotificationContext';
import { UserState } from '../../../store/user/types';
import {
  exportCsv,
  getUsers,
  resetStatus,
} from '../../../store/user/actions';
import ProgressContainer from '../../../components/common/ProgressContainer';
import UsersOphthaContent from './ophtha/UsersOphthaContent';
import UsersMarketingContent from './marketing/UsersMarketingContent';
import UsersAdminContent from './admin/UsersAdminContent';
import PlusIcon from '../../../components/icon/PlusIcon';
import EyeOnIcon from '../../../components/icon/EyeOnIcon';
import { thea75 } from '../../../constants/colors';
import {
  routeUsersAdminAdd,
  routeUsersMarketingEmailAuthorized,
} from '../../../constants/routes';

type Props = {}

const UserPage: React.FC<Props> = () => {
  const { t } = useTranslation(['backoffice']);
  const classes = useStyles();
  const history = useHistory();
  const dispatch: Dispatch<any> = useDispatch();
  const [tabValue, setTabValue] = React.useState<number>(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const {
    users,
    pending,
    error,
    status,
  } = useSelector<DefaultRootState, UserState>(
    (state: any) => state.userStore,
  );

  const { setNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (users.length === 0) dispatch(getUsers());
  }, []);

  useEffect(() => {
    if (status === 'ERROR') {
      setNotification({ isOpened: true, severity: 'error', message: error || '' });
    }
    if (status !== 'INIT') dispatch(resetStatus());
  }, [status]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const handleExportMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleExportMenuClose = () => {
    setAnchorEl(null);
  };

  const downloadCsv = (roles: ('ROLE_OPHTHA' | 'ROLE_MARKETING' | 'ROLE_ADMIN' | 'ROLE_NURSE' | 'ROLE_ORTHOPTIST' | '')[]) => {
    dispatch(exportCsv(roles));
    handleExportMenuClose();
  };

  return (
    <div style={{ paddingBottom: '50px' }}>
      <Typography
        variant="h1"
        className={classes.title}
      >
        {t('title.users')}
        {tabValue === 1 && (
          <Button
            variant="contained"
            color="primary"
            startIcon={(
              <EyeOnIcon
                width={22}
                height={22}
                color={thea75}
              />
            )}
            className={classes.buttonAdd}
            onClick={() => history.push(routeUsersMarketingEmailAuthorized)}
          >
            <Typography variant="button">
              {t('users.buttonManageMarketing')}
            </Typography>
          </Button>
        )}
        {tabValue === 2 && (
          <Button
            variant="contained"
            color="primary"
            startIcon={(
              <PlusIcon
                width={22}
                height={22}
              />
)}
            className={classes.buttonAdd}
            onClick={() => history.push(routeUsersAdminAdd)}
          >
            <Typography variant="button">
              {t('users.buttonAddAdmin')}
            </Typography>
          </Button>
        )}
      </Typography>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label={t('users.tabs.doctors')} />
        <Tab label={t('users.tabs.marketing')} />
        <Tab label={t('users.tabs.admin')} />
      </Tabs>

      <ProgressContainer loading={pending}>

        {tabValue === 0 && (
          <UsersOphthaContent />
        )}
        {tabValue === 1 && (
          <>
            <UsersMarketingContent />
          </>
        )}
        {tabValue === 2 && (
          <>
            <UsersAdminContent />
          </>
        )}
      </ProgressContainer>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleExportMenuClose}
      >
        <MenuItem onClick={() => downloadCsv(['ROLE_OPHTHA', 'ROLE_NURSE', 'ROLE_ORTHOPTIST'])}>
          {t('users.tabs.doctors')}
        </MenuItem>
        <MenuItem onClick={() => downloadCsv(['ROLE_MARKETING'])}>
          {t('users.tabs.marketing')}
        </MenuItem>
        <MenuItem onClick={() => downloadCsv(['ROLE_ADMIN'])}>
          {t('users.tabs.admin')}
        </MenuItem>
        <MenuItem onClick={() => downloadCsv([''])}>
          {t('user.roles.ALL')}
        </MenuItem>
      </Menu>
      <Fab
        color="default"
        variant="extended"
        className={classes.fab}
        aria-label={t('users.admin.export')}
        onClick={handleExportMenuClick}
      >
        <GetAppIcon style={{ marginRight: '0.4em' }} />
        <Typography variant="button">
          {t('users.admin.export')}
        </Typography>
      </Fab>
    </div>
  );
};

export default UserPage;
