// const numberRegex = /\d/g;
const lowercaseRegex = /[a-z]/;
const uppercaseRegex = /[A-Z]/;
// eslint-disable-next-line no-useless-escape
const specialCharRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

export const checkPassword = (checkedPassword: string) => {
  if (checkedPassword.length < 12) {
    return false;
  }
  // if (!numberRegex.test(checkedPassword)) {
  //   return false;
  // }
  if (!lowercaseRegex.test(checkedPassword)) {
    return false;
  }
  if (!uppercaseRegex.test(checkedPassword)) {
    return false;
  }
  return specialCharRegex.test(checkedPassword);
};

export default { checkPassword };
