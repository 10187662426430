import React from 'react';
import { useSelector, DefaultRootState } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import {
  Dialog, DialogTitle, Typography, IconButton,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useStyles from './NotificationUserModal.style';
import NotificationUserTable from './NotificationUserTable';
import { NotificationState } from '../../../store/notification/types';

type Props = {
  open: boolean,
  handleClose: () => void
}

const NotificationUserModal = ({ open, handleClose }: Props) => {
  const { t } = useTranslation(['backoffice']);
  const {
    affectedUsers,
  } = useSelector<DefaultRootState, NotificationState>(
    (state: any) => state.notificationStore,
  );

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      className={classes.paper}
      aria-labelledby="subscriptions-modal"
    >
      <>
        <DialogTitle disableTypography>
          <Typography variant="h3">
            {t('notification.affectedUsersModal.title')}
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon color="primary" />
          </IconButton>
        </DialogTitle>
        <NotificationUserTable affectedUsers={affectedUsers} />
      </>
    </Dialog>
  );
};

export default React.memo(NotificationUserModal);
