import {
  ROLE_ADMIN,
  ROLE_ANONYMIZE,
  ROLE_MARKETING,
  ROLE_NURSE,
  ROLE_OPHTHA,
  ROLE_ORTHOPTIST,
} from '../constants/role';

const getHigherRole = (roles: string[], includeAdmin?: boolean): string => {
  if (includeAdmin && roles.includes(ROLE_ADMIN)) {
    return ROLE_ADMIN;
  }
  if (roles.includes(ROLE_OPHTHA)) {
    return ROLE_OPHTHA;
  }
  if (roles.includes(ROLE_NURSE)) {
    return ROLE_NURSE;
  }
  if (roles.includes(ROLE_ORTHOPTIST)) {
    return ROLE_ORTHOPTIST;
  }
  if (roles.includes(ROLE_MARKETING)) {
    return ROLE_MARKETING;
  }
  return ROLE_ANONYMIZE;
};

export {
  // eslint-disable-next-line import/prefer-default-export
  getHigherRole,
};
