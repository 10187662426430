import {
  AxiosError,
  AxiosResponse,
} from 'axios';
import {
  DispatchType,
  ExpertCourseAction,
} from './types';
import {
  STORE_EXPERT_COURSE_FAILED,
  STORE_EXPERT_COURSE_LOADED,
  STORE_EXPERT_COURSE_PENDING,
} from './actionTypes';
import { apiGetExpertCourses } from '../../api/apiExpertCourse';

const expertCourseFailedAction = (error: string): ExpertCourseAction => ({
  type: STORE_EXPERT_COURSE_FAILED,
  error,
});

const expertCoursePendingAction = (): ExpertCourseAction => ({
  type: STORE_EXPERT_COURSE_PENDING,
});

// eslint-disable-next-line camelcase
const expertCourseLoadedAction = (expert_courses: string[]): ExpertCourseAction => ({
  type: STORE_EXPERT_COURSE_LOADED,
  expert_courses,
});

// eslint-disable-next-line import/prefer-default-export
export const getExpertCourses = () => (dispatch: DispatchType) => {
  dispatch(expertCoursePendingAction());
  apiGetExpertCourses()
    .then((data: AxiosResponse) => dispatch(expertCourseLoadedAction(data.data)))
    .catch((error: AxiosError) => dispatch(expertCourseFailedAction(error.message)));
};
