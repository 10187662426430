import React, {
  FormEvent,
  useState,
} from 'react';
import { AxiosResponse } from 'axios';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Link,
  Typography,
} from '@material-ui/core';
import {
  routeForgotPassword,
  routeUsers,
} from '../../constants/routes';
import useStyles from '../../components/paper/IconBluePaper.style';
import { apiPostLogin } from '../../api/apiAnonymous';
import WhiteTextInput from '../../components/form/WhiteTextInput';
import { leftIconStyle } from '../../components/form/WhiteTextInput.style';
import {
  storageAccessToken,
  storageRefreshToken,
  storageTokenTimeout,
} from '../../constants/auth';
import LockIcon from '../../components/icon/LockIcon';
import BookmarkIcon from '../../components/icon/BookmarkIcon';
import { getNewTimeout } from '../../services/AuthService';

type Props = {}

const LoginPage: React.FC<Props> = () => {
  const { t } = useTranslation(['anonymous']);
  const history = useHistory();
  const classes = useStyles();
  const [loginError, setLoginError] = useState('');
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const handleFormAction = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    let formError = false;
    if (username.trim() === '') {
      formError = true;
      setUsernameError(t('login.usernameRequired'));
    }
    if (password.trim() === '') {
      formError = true;
      setPasswordError(t('login.passwordRequired'));
    }
    if (!formError) {
      apiPostLogin(username, password).then(
        (data: AxiosResponse) => {
          // eslint-disable-next-line no-console
          localStorage.setItem(storageAccessToken, data.data.token);
          localStorage.setItem(storageRefreshToken, data.data.refresh_token);
          localStorage.setItem(storageTokenTimeout, getNewTimeout().toString());
          history.push(routeUsers);
        },
      ).catch((apiError: any) => {
        setLoginError(t('login.unauthorized'));
        // eslint-disable-next-line no-console
        console.log(apiError);
      });
    }
  };

  const handleChangeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setUsername(event.target.value);
    setUsernameError('');
    setLoginError('');
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setPassword(event.target.value);
    setPasswordError('');
    setLoginError('');
  };

  return (
    <>
      <Typography variant="body1" className={classes.description}>
        {t('login.text')}
      </Typography>
      <form onSubmit={handleFormAction}>
        {loginError !== ''
        && (
          <Typography variant="body1" color="error" className={classes.description}>
            {loginError}
          </Typography>
        )}
        <WhiteTextInput
          type="text"
          value={username}
          onChange={handleChangeUsername}
          name="username"
          helperText={usernameError}
          error={usernameError !== ''}
          label={t('login.login')}
          icon={(<BookmarkIcon style={leftIconStyle} />)}
        />
        <WhiteTextInput
          type="password"
          value={password}
          onChange={handleChangePassword}
          name="username"
          label={t('login.password')}
          helperText={passwordError}
          error={passwordError !== ''}
          icon={(<LockIcon style={leftIconStyle} />)}
        />
        <div className={classes.link}>
          <Link
            component="a"
            href={routeForgotPassword}
          >
            {t('login.forgotPasswordLink')}
          </Link>
        </div>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disableElevation
          className={classes.button}
        >
          <Typography variant="button">
            {t('login.button')}
          </Typography>
        </Button>
      </form>
    </>
  );
};

export default LoginPage;
