import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Divider,
  MenuList,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useLocation } from 'react-router';
import useStyles from './Menu.style';
import Logo from '../icon/Logo';
import Title from '../common/Title';
import {
  nuance100,
  nuance100Alpha,
} from '../../constants/colors';
import SettingFillIcon from '../icon/SettingFillIcon';
import FlashFillIcon from '../icon/FlashFillIcon';
import CalendarFillIcon from '../icon/CalendarFillIcon';
import BookOpenFillIcon from '../icon/BookOpenFillIcon';
import EyeOnFillIcon from '../icon/EyeOnFillIcon';
import ToolFillIcon from '../icon/ToolFillIcon';
import {
  routeBooks,
  routeEvents,
  routeFlashInfos,
  routeNotification,
  routeProducts,
  routeProfessionalUnits,
  routeToolbox,
  routeUsers,
} from '../../constants/routes';
import IconMenuItem from './IconMenuItem';
import CartFillIcon from '../icon/CartFillIcon';
import BellFillIcon from '../icon/BellFillIcon';

type Props = {}

const Menu: React.FC<Props> = () => {
  const classes = useStyles();
  const { t } = useTranslation(['backoffice']);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));
  const location = useLocation();

  const usersActivate = location.pathname.includes(routeUsers);
  const flashInfoActivate = location.pathname.includes(routeFlashInfos);
  const eventsActivate = location.pathname.includes(routeEvents);
  const booksActivate = location.pathname.includes(routeBooks);
  const productsActivate = location.pathname.includes(routeProducts);
  const professionalUnitsActivate = location.pathname.includes(routeProfessionalUnits);
  const toolboxActivate = location.pathname.includes(routeToolbox);
  const notificationActivate = location.pathname.includes(routeNotification);

  return (
    <div className={classes.root}>
      {process.env.REACT_APP_ENV !== 'prod' && (
        <Typography
          variant="h1"
          style={{
            backgroundColor: 'red',
            transform: 'rotate(-45deg)',
            color: 'white',
            textAlign: 'center',
            width: '240px',
            position: 'absolute',
            left: '-50px',
            top: '50px',
          }}
        >
          {process.env.REACT_APP_ENV === 'local' && t('title.local')}
          {process.env.REACT_APP_ENV !== 'local' && t('title.dev')}
        </Typography>
      )}
      <div className={classes.iconContainer}>
        <Logo className={classes.icon} />
      </div>
      {matches && <Title />}
      <MenuList>
        <IconMenuItem
          classes={classes}
          isActivate={usersActivate}
          matches={matches}
          title={t('title.users')}
          icon={<SettingFillIcon color={usersActivate ? nuance100 : nuance100Alpha} />}
          link={routeUsers}
        />
        <IconMenuItem
          classes={classes}
          isActivate={flashInfoActivate}
          matches={matches}
          title={t('title.flashInfo')}
          icon={<FlashFillIcon color={flashInfoActivate ? nuance100 : nuance100Alpha} />}
          link={routeFlashInfos}
        />
        <Divider variant="middle" className={classes.divider} />
        <IconMenuItem
          classes={classes}
          isActivate={eventsActivate}
          matches={matches}
          title={t('title.events')}
          icon={<CalendarFillIcon color={eventsActivate ? nuance100 : nuance100Alpha} />}
          link={routeEvents}
        />
        <IconMenuItem
          classes={classes}
          isActivate={notificationActivate}
          matches={matches}
          title={t('title.notifications')}
          icon={<BellFillIcon color={notificationActivate ? nuance100 : nuance100Alpha} />}
          link={routeNotification}
        />
        <IconMenuItem
          classes={classes}
          isActivate={booksActivate}
          matches={matches}
          title={t('title.books')}
          icon={<BookOpenFillIcon color={booksActivate ? nuance100 : nuance100Alpha} />}
          link={routeBooks}
        />
        <IconMenuItem
          classes={classes}
          isActivate={productsActivate}
          matches={matches}
          title={t('title.products')}
          icon={<EyeOnFillIcon color={productsActivate ? nuance100 : nuance100Alpha} />}
          link={routeProducts}
        />
        <IconMenuItem
          classes={classes}
          isActivate={professionalUnitsActivate}
          matches={matches}
          title={t('title.professionalUnits')}
          icon={<CartFillIcon color={professionalUnitsActivate ? nuance100 : nuance100Alpha} />}
          link={routeProfessionalUnits}
        />
        <IconMenuItem
          classes={classes}
          isActivate={toolboxActivate}
          matches={matches}
          title={t('title.toolbox')}
          icon={<ToolFillIcon color={toolboxActivate ? nuance100 : nuance100Alpha} />}
          link={routeToolbox}
        />
      </MenuList>
    </div>
  );
};

export default Menu;
