import React from 'react';
import { thea300 } from '../../constants/colors';

interface Props {
  color?: string,
  style?: any
}

const FlashFillIcon: React.FC<Props> = (props: Props) => {
  const { color, style } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      style={style}
    >
      <g id="flash-fill" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g>
          <path
            d="M11.1113,23.0001 C10.9973,23.0001 10.8823,22.9801 10.7693,22.9391 C10.3373,22.7821 10.0673,22.3501 10.1173,21.8931 L10.8853,14.8001 L5.0003,14.8001 C4.6313,14.8001 4.2923,14.5971 4.1183,14.2711 C3.9443,13.9451 3.9643,13.5511 4.1693,13.2441 L12.0573,1.4441 C12.3133,1.0611 12.7973,0.9021 13.2303,1.0611 C13.6633,1.2181 13.9323,1.6501 13.8823,2.1071 L13.1143,9.2001 L19.0003,9.2001 C19.3693,9.2001 19.7083,9.4031 19.8823,9.7291 C20.0553,10.0551 20.0363,10.4491 19.8313,10.7561 L11.9423,22.5561 C11.7533,22.8401 11.4373,23.0001 11.1113,23.0001"
            id="path-1"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

const defaultProps: Props = {
  color: thea300,
  style: {},
};

FlashFillIcon.defaultProps = defaultProps;

export default FlashFillIcon;
