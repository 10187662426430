export const routeForgotPassword = '/forgot-password';
export const routeResetPassword = '/reset-password';
export const routeLogin = '/login';
export const routeLogout = '/logout';
export const backOfficeBasePath = '/back-office';
export const routeUsers = `${backOfficeBasePath}/users`;
export const routeUsersMarketingEmailAuthorized = `${backOfficeBasePath}/users/emails`;
export const routeUsersAdminAdd = `${backOfficeBasePath}/users/admin/add`;
export const routeUsersAdminEdit = `${backOfficeBasePath}/users/admin/edit/:id`;
export const routeBooks = `${backOfficeBasePath}/books`;
export const routeBooksAdd = `${backOfficeBasePath}/books/add`;
export const routeBooksEdit = `${backOfficeBasePath}/books/edit/:id`;
export const routeProducts = `${backOfficeBasePath}/products`;
export const routeProductsAdd = `${backOfficeBasePath}/products/add`;
export const routeProductsEdit = `${backOfficeBasePath}/products/edit/:id`;
export const routeToolbox = `${backOfficeBasePath}/toolbox`;
export const routeToolboxAdd = `${backOfficeBasePath}/toolbox/add`;
export const routeToolboxEdit = `${backOfficeBasePath}/toolbox/edit/:id`;
export const routeNotification = `${backOfficeBasePath}/notifications`;
export const routeNotificationAdd = `${backOfficeBasePath}/notifications/add`;
export const routeNotificationEdit = `${backOfficeBasePath}/notifications/edit/:id`;
export const routeEvents = `${backOfficeBasePath}/events`;
export const routeEventsAdd = `${backOfficeBasePath}/events/add`;
export const routeEventsEdit = `${backOfficeBasePath}/events/edit/:id`;
export const routeEventsPush = `${backOfficeBasePath}/events/push/:id`;
export const routeFlashInfos = `${backOfficeBasePath}/flash-infos`;
export const routeFlashInfosAdd = `${backOfficeBasePath}/flash-infos/add`;
export const routeFlashInfosEdit = `${backOfficeBasePath}/flash-infos/edit/:id`;
export const routeProfessionalUnits = `${backOfficeBasePath}/professional-units`;
export const routeAccount = `${backOfficeBasePath}/account`;
export const routeChangePassword = `${backOfficeBasePath}/account/password`;
