const generateCsv = (
  data: string,
  filename: string,
  elementId: string,
): void => {
  if (document) {
    const anchor = document.createElement('a');
    if (anchor) {
      anchor.setAttribute('id', elementId);
      anchor.setAttribute('style', 'display:none;');
      const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      anchor.href = url;
      anchor.download = filename;
      anchor.click();
    }
  }
};

export default generateCsv;
