import axios from 'axios';
import {
  apiTerritories,
} from '../constants/api';
import { getToken } from '../services/AuthService';

// eslint-disable-next-line import/prefer-default-export
export const apiGetTerritories = async () => {
  const token: string = await getToken();
  const data = await axios.get(
    `${process.env.REACT_APP_API_ROOT}${apiTerritories}`,
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return data;
};
