import { makeStyles } from '@material-ui/core/styles';
import { nuance100 } from '../../constants/colors';

const useStyles = makeStyles(() => ({
  title: {
    width: '100%',
    textAlign: 'center',
  },
  titleWhite: {
    color: nuance100,
  },
}));

export default useStyles;
