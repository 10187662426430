import axios from 'axios';
import {
  apiForgotPassword,
  apiLoginCheck,
  apiRestPassword,
} from '../constants/api';

export const apiPostLogin = async (username: string, password: string) => {
  const data = await axios.post(
    `${process.env.REACT_APP_API_ROOT}${apiLoginCheck}`,
    { username, password },
  );
  return data;
};

export const apiPostForgotPassword = async (username: string) => {
  const data = await axios.post(
    `${process.env.REACT_APP_API_ROOT}${apiForgotPassword}`,
    { email: username },
  );
  return data;
};

export const apiPostResetPassword = async (code: string, password: string) => {
  const data = await axios.post(
    `${process.env.REACT_APP_API_ROOT}${apiRestPassword}`,
    { code, password },
  );
  return data;
};

export default {
  apiPostLogin,
  apiPostForgotPassword,
  apiPostResetPassword,
};
