import { makeStyles } from '@material-ui/core/styles';
import { nuance100Alpha } from '../../constants/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '300px',
    height: '100vh',
    paddingLeft: '16px',
    paddingRight: '16px',
    paddingTop: '48px',
    [theme.breakpoints.down('md')]: {
      paddingTop: '60px',
      width: '125px',
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  iconContainer: {
    width: '80px',
    height: '80px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '10px',
    [theme.breakpoints.down('md')]: {
      top: '60px',
      width: '58px',
      height: '58px',
    },
  },
  icon: {
    width: '80px',
    height: '80px',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('md')]: {
      top: '60px',
      width: '58px',
      height: '58px',
    },
  },
  menuItem: {
    minHeight: '48px !important',
    [theme.breakpoints.down('md')]: {
      marginLeft: 'auto !important',
      marginRight: 'auto !important',
      minWidth: 0,
    },
  },
  menuIcon: {
    [theme.breakpoints.down('md')]: {
      margin: 'auto !important',
      minWidth: '0 !important',
    },
  },
  divider: {
    backgroundColor: nuance100Alpha,
    width: '36px',
    height: '2px',
    [theme.breakpoints.down('md')]: {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  text: {
    width: 'calc(100% - 56px)',
    whiteSpace: 'break-spaces',
  },
}));

export default useStyles;
