import * as XLSX from 'xlsx';

export type IImportUser = {
  email: string | null
  rpps: string | null
}

const extractFromCsv = (data: string): IImportUser[] => {
  const values: IImportUser[] = [];
  const allTextLines: string[] = data.split(/\r\n|\n/);
  if (allTextLines.length <= 1) {
    return values;
  }
  const separator: string = allTextLines[0].indexOf(';') >= 0 ? ';' : ',';
  const entries: string[] = allTextLines[0].split(separator);
  const secondLine: string[] = allTextLines[1].split(separator);
  let firstDataLine: number = 0;
  let emailIndex: number | undefined;
  let rppsIndex: number | undefined;
  if (entries.length !== secondLine.length || entries[1].trim() === '') {
    firstDataLine = 2;
    emailIndex = secondLine.map((item: string) => item.toLowerCase().trim()).indexOf('email');
    rppsIndex = secondLine.map((item: string) => item.toLowerCase().trim()).indexOf('rpps');
  } else {
    firstDataLine = 1;
    emailIndex = entries.map((item: string) => item.toLowerCase().trim()).indexOf('email');
    rppsIndex = entries.map((item: string) => item.toLowerCase().trim()).indexOf('rpps');
  }
  if (emailIndex || rppsIndex) {
    // eslint-disable-next-line no-plusplus
    for (let i: number = firstDataLine; i < allTextLines.length; i++) {
      const value: string[] = allTextLines[i].split(separator);

      const email = emailIndex !== undefined && emailIndex >= 0 ? value[emailIndex] : null;
      const rpps = rppsIndex !== undefined && rppsIndex >= 0 ? value[rppsIndex] : null;
      if (email !== null && rpps !== null) {
        values.push({ email, rpps });
      }
    }
  }
  return values;
};

// eslint-disable-next-line no-unused-vars
export const getUsersFromCsv = async (csvFile: File): Promise<IImportUser[]> => {
  const data = await csvFile.text().then((text: string) => text);
  return extractFromCsv(data);
};

// eslint-disable-next-line no-unused-vars
export const getUsersFromExcel = async (excelFile: File): Promise<IImportUser[]> => {
  const buffer = await excelFile.arrayBuffer();
  const workbook = XLSX.read(
    new Uint8Array(buffer),
    {
      type: 'array', sheets: 0, raw: true, cellFormula: false, cellHTML: false,
    },
  );
  const sheetName = workbook.SheetNames[0];
  const sheet = workbook.Sheets[sheetName];
  const data = XLSX.utils.sheet_to_csv(sheet, {});
  return extractFromCsv(data);
};
