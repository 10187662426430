import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@material-ui/core';
import { IProductCategory } from '../../store/product/types';
import useStyles from './SelectInput.style';
import {
  feedbackError,
  nuance150,
} from '../../constants/colors';

// type generic pour les prochains à utiliser ce composant
type Props<T> = {
  label: string,
  values: T[],
  valueSelected: string,
  onChange: any,
  error?: boolean,
  helperText?: string,
  disabled?: boolean,
  resetOption?: boolean,
}

export type SubscriptionType = {
  id: string,
  title: string,
}

const SelectInput = <T extends IProductCategory | SubscriptionType>({
  label,
  values,
  valueSelected,
  onChange,
  error,
  helperText,
  disabled,
  resetOption,
}: Props<T>) => {
  const { t } = useTranslation(['backoffice']);
  const classes = useStyles();
  return (
    <FormControl className={classes.formControl} error={error} disabled={disabled}>
      <InputLabel
        id="select-form"
        error={error}
        className={classes.label}
        disableAnimation
        shrink
        disabled={disabled}
      >
        <Typography variant="body1" style={error ? { color: feedbackError } : {}}>
          {label}
        </Typography>
      </InputLabel>
      <Paper elevation={0} className={classes.paper}>
        <Select
          labelId="select-form"
          id="select"
          value={valueSelected}
          onChange={onChange}
          className={classes.select}
          disabled={disabled}
          MenuProps={{
            TransitionProps: {
              // @ts-ignore
              anchororigin: { horizontal: 'center', vertical: 'bottom' },
              transformorigin: { vertical: 'top', horizontal: 'center' },
            },
          }}
        >
          {resetOption && (
            <MenuItem
              value=""
              key="reset"
              className={classes.menuItem}
            >
              <i style={{ color: nuance150 }}>{t('common.selectAnItem')}</i>
            </MenuItem>
          )}
          {values && values.map((value: { id: string, title?: string }) => (
            <MenuItem
              value={value.id}
              key={value.id}
              className={classes.menuItem}
            >
              {value.title}
            </MenuItem>
          ))}
        </Select>
      </Paper>
      {
        helperText
        && (
          <FormHelperText error={error} disabled={disabled}>
            <Typography
              variant="caption"
              color={error ? 'error' : 'primary'}
              className={classes.helperText}
            >
              {helperText}
            </Typography>
          </FormHelperText>
        )
      }
    </FormControl>
  );
};

const defaultProps: Partial<Props<IProductCategory>> = {
  helperText: '',
  error: false,
  disabled: false,
  resetOption: false,
};

SelectInput.defaultProps = defaultProps;

export default React.memo(SelectInput);
