import React, { Dispatch } from 'react';
import { useSelector, DefaultRootState, useDispatch } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import {
  Dialog, DialogTitle, Typography, Button, IconButton,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { EventState } from '../../../store/event/types';
import useStyles from './EventModalSubscriptions.style';
import EventTableSubscriptions from './EventTableSubscriptions';
import { exportCsvSubscription } from '../../../store/event/actions';
import FileFillIcon from '../../../components/icon/FileFillIcon';

type Props = {
  open: boolean,
  eventId: string,
  handleClose: () => void
}

const EventModalSubscriptions = ({ open, eventId, handleClose }: Props) => {
  const { t } = useTranslation(['backoffice']);
  const dispatch: Dispatch<any> = useDispatch();
  const {
    subscriptions,
  } = useSelector<DefaultRootState, EventState>(
    (state: any) => state.eventStore,
  );

  const classes = useStyles();

  const exportCsv = () => {
    dispatch(exportCsvSubscription(eventId, 'csv'));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="xl"
      className={classes.paper}
      aria-labelledby="subscriptions-modal"
    >
      <>
        <DialogTitle disableTypography>
          <Typography variant="h3">
            {t('event.subscription.title')}
          </Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon color="primary" />
          </IconButton>
        </DialogTitle>
        <div className={classes.actions}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<FileFillIcon />}
            className={classes.buttonExportCsv}
            onClick={exportCsv}
          >
            <Typography variant="button">
              {t('event.subscription.buttonExportCsv')}
            </Typography>
          </Button>
        </div>
        <EventTableSubscriptions subscriptions={subscriptions} />
      </>
    </Dialog>
  );
};

export default React.memo(EventModalSubscriptions);
