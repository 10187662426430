import React, {
  Dispatch,
  useEffect,
} from 'react';
import {
  DefaultRootState,
  useDispatch,
  useSelector,
} from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import {
  Button,
  Typography,
} from '@material-ui/core';
import PlusIcon from '../../../components/icon/PlusIcon';
import {
  routeNotificationAdd,
} from '../../../constants/routes';
import useStyles from '../../../components/paper/MainPaper.style';
import { EventState } from '../../../store/event/types';
import { NotificationState } from '../../../store/notification/types';
import { RegionState } from '../../../store/region/types';
import { TerritoryState } from '../../../store/territory/types';
import { getEventsAndCategories } from '../../../store/event/actions';
import { getRegions } from '../../../store/region/actions';
import { getTerritories } from '../../../store/territory/actions';
import {
  getNotifications,
  resetAffectedUsers,
} from '../../../store/notification/actions';
import ProgressContainer from '../../../components/common/ProgressContainer';
import NotificationTable from './NotificationTable';
import { notificationsDateSortedSelector } from '../../../store/notification/selectors';

type Props = {};

const NotificationsPage: React.FC<Props> = () => {
  const { t } = useTranslation(['backoffice']);
  const history = useHistory();
  const classes = useStyles();
  const dispatch: Dispatch<any> = useDispatch();
  const eventStore: EventState = useSelector<DefaultRootState, EventState>(
    (state: any) => state.eventStore,
  );
  const notificationStore: NotificationState = useSelector<DefaultRootState, NotificationState>(
    (state: any) => state.notificationStore,
  );

  const regionStore = useSelector<DefaultRootState, RegionState>(
    (state: any) => state.regionStore,
  );

  const territoryStore = useSelector<DefaultRootState, TerritoryState>(
    (state: any) => state.territoryStore,
  );
  const sortedNotifications = useSelector(notificationsDateSortedSelector);

  useEffect(() => {
    if (eventStore.events.length === 0) {
      dispatch(getEventsAndCategories());
    }
    if (regionStore.regions.length === 0) {
      dispatch(getRegions());
    }
    if (territoryStore.territories.length === 0) {
      dispatch(getTerritories());
    }
    if (notificationStore.notifications.length === 0) {
      dispatch(getNotifications());
    }
    if (notificationStore.affectedUsers.length !== 0) {
      dispatch(resetAffectedUsers());
    }
  }, []);
  const pending = eventStore.pending
  || regionStore.pending
  || territoryStore.pending
  || notificationStore.pending;

  return (
    <>
      <Typography variant="h1" className={classes.title}>
        {t('title.notifications')}
        <Button
          variant="contained"
          color="primary"
          startIcon={<PlusIcon width={22} height={22} />}
          className={classes.buttonAdd}
          onClick={() => history.push(routeNotificationAdd)}
        >
          <Typography variant="button">
            {t('notification.buttonAdd')}
          </Typography>
        </Button>
      </Typography>

      <ProgressContainer loading={pending}>
        <NotificationTable notifications={sortedNotifications} />
      </ProgressContainer>
    </>
  );
};

export default NotificationsPage;
