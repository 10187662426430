import {
  AxiosError,
  AxiosResponse,
} from 'axios';
import {
  DispatchType, IBook, BookAction,
} from './types';
import {
  STORE_BOOK_ADDED_SENT,
  STORE_BOOK_DISABLED,
  STORE_BOOK_FAILED,
  STORE_BOOK_PENDING,
  STORE_BOOK_LOADED,
  STORE_BOOK_SENDING,
  STORE_BOOK_UPDATED_SENT,
  STORE_BOOK_RESET_STATUS,
  STORE_BOOK_ENABLED,
} from './actionTypes';
import {
  apiGetBooks, apiDeleteBook, apiPostBook, apiEditBook, apiExportBooks, apiPutEnableBook,
} from '../../api/apiBook';
import generateCsv from '../../utils/generateCsv';

const bookDisabledAction = (book: IBook): BookAction => ({
  type: STORE_BOOK_DISABLED,
  book,
});

const bookEnabledAction = (book: IBook): BookAction => ({
  type: STORE_BOOK_ENABLED,
  book,
});

const bookFailedAction = (error: string): BookAction => ({
  type: STORE_BOOK_FAILED,
  error,
});

const bookPendingAction = (): BookAction => ({
  type: STORE_BOOK_PENDING,
});

const bookLoadedAction = (books: IBook[]): BookAction => ({
  type: STORE_BOOK_LOADED,
  books,
});

const bookSendingAction = (): BookAction => ({
  type: STORE_BOOK_SENDING,
});

const bookAddedSentAction = (book: IBook): BookAction => ({
  type: STORE_BOOK_ADDED_SENT,
  book,
});

const bookUpdatedAction = (book: IBook): BookAction => ({
  type: STORE_BOOK_UPDATED_SENT,
  book,
});

const bookResetStatus = (): BookAction => ({
  type: STORE_BOOK_RESET_STATUS,
});

export const resetStatus = () => (dispatch: DispatchType) => {
  dispatch(bookResetStatus());
};

export const disableBook = (bookId: string) => (dispatch: DispatchType) => {
  apiDeleteBook(bookId)
    .then((data: AxiosResponse) => dispatch(bookDisabledAction(data.data)))
    .catch((error: AxiosError) => dispatch(bookFailedAction(error.message)));
};

export const enableBook = (bookId: string) => (dispatch: DispatchType) => {
  apiPutEnableBook(bookId)
    .then((data: AxiosResponse) => dispatch(bookEnabledAction(data.data)))
    .catch((error: AxiosError) => dispatch(bookFailedAction(error.message)));
};

export const getBooks = () => (dispatch: DispatchType) => {
  dispatch(bookPendingAction());
  apiGetBooks()
    .then((data: AxiosResponse) => dispatch(bookLoadedAction(data.data)))
    .catch((error: AxiosError) => dispatch(bookFailedAction(error.message)));
};

export const postBook = (
  content: Partial<IBook>,
  picture?: File,
) => (dispatch: DispatchType) => {
  dispatch(bookSendingAction());
  apiPostBook(content, picture)
    .then((data: AxiosResponse) => dispatch(bookAddedSentAction(data.data)))
    .catch((error: AxiosError) => dispatch(bookFailedAction(error.message)));
};

export const putBook = (
  bookId: string,
  content: Partial<IBook>,
  file?: File | string,
) => (dispatch: DispatchType) => {
  dispatch(bookSendingAction());
  apiEditBook(bookId, content, file)
    .then((data: AxiosResponse) => dispatch(bookUpdatedAction(data.data)))
    .catch((error: AxiosError) => dispatch(bookFailedAction(error.message)));
};

export const exportCsv = (type: 'TYPE_USERS' | 'TYPE_SIMPLE' | 'TYPE_DETAILED') => (dispatch: DispatchType) => {
  const filenameType = (type.length > 0) ? `_${type.split('_')[1].toLowerCase()}` : '';
  const filename = `commandes_livres${filenameType}`;

  apiExportBooks(type)
    .then((data: AxiosResponse) => generateCsv(data.data, filename, 'bookOrders'))
    .catch((error: AxiosError) => dispatch(bookFailedAction(error.message)));
};
