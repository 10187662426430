/* eslint-disable arrow-body-style */
import axios, { AxiosResponse } from 'axios';
import {
  apiAdminMe,
  apiAdminMeChangePassword,
} from '../constants/api';
import { getToken } from '../services/AuthService';
import { IMe } from '../store/me/types';

export const apiGetMe = async (): Promise<AxiosResponse> => {
  return getToken().then((token: string) => {
    return axios.get(
      `${process.env.REACT_APP_API_ROOT}${apiAdminMe}`,
      { headers: { Authorization: `Bearer ${token}` } },
    );
  });
};

export const apiPutMe = async (me: IMe): Promise<AxiosResponse> => {
  return getToken().then((token: string) => {
    return axios.put(
      `${process.env.REACT_APP_API_ROOT}${apiAdminMe}`,
      me,
      { headers: { Authorization: `Bearer ${token}` } },
    );
  });
};

export const apiPutMePassword = async (
  oldPassword: string,
  newPassword: string,
): Promise<AxiosResponse> => {
  return getToken().then((token: string) => {
    const body = {
      old_password: oldPassword,
      new_password: newPassword,
    };
    return axios.put(
      `${process.env.REACT_APP_API_ROOT}${apiAdminMeChangePassword}`,
      body,
      { headers: { Authorization: `Bearer ${token}` } },
    );
  });
};
