import React, { ReactNode } from 'react';
import {
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useStyles from './ProgressContainer.style';

interface Props {
  loading: boolean,
  children: ReactNode
}

const ProgressContainer: React.FC<Props> = (props: Props) => {
  const { loading, children } = props;
  const { t } = useTranslation(['backoffice']);
  const classes = useStyles();
  if (loading) {
    return (
      <div className={classes.root}>
        <Typography variant="body1" className={classes.title}>
          {t('common.loading')}
        </Typography>
        <LinearProgress variant="indeterminate" color="primary" className={classes.progress} />
      </div>
    );
  }
  return <>{children}</>;
};

export default ProgressContainer;
