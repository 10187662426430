import { makeStyles } from '@material-ui/core/styles';
import {
  thea150, thea300,
} from '../../constants/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1,
    backgroundColor: thea150,
    position: 'absolute',
    top: 0,
    left: '300px',
    borderRadius: '30px',
    margin: '20px',
    width: 'calc(100vw - 340px)',
    minWidth: '700px',
    height: 'calc(100vh - 40px)',
    [theme.breakpoints.down('md')]: {
      width: 'calc(100vw - 165px)',
      left: '125px',
    },
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  content: {
    position: 'relative',
    margin: '40px',
    width: 'auto',
    minWidth: '400px',
    height: 'auto',
    maxHeight: 'calc(100vh - 80px)',
    overflow: 'visible',
  },
  title: {
    paddingTop: '10px',
    paddingRight: '10px',
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'baseline',
  },
  fullWidth: {
    width: '100%',
  },
  buttonAdd: {
    textTransform: 'none',
    marginLeft: '2rem',
    fontWeight: 300,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonAddActivity: {
    padding: '6px 19px',
    fontSize: '11px',
    textTransform: 'uppercase',
  },
  buttonSeeList: {
    fontSize: '11px',
    color: thea300,
    padding: '5px',
    width: 'fit-content',
    marginTop: '1rem',

    '&:hover': {
      width: 'fit-content',
      padding: '5px',
    },
  },
  buttonAction: {
    textTransform: 'none',
    fontSize: '17px',
    width: '100%',
  },
  text: {
    textAlign: 'left',
    marginBottom: '20px',
    whiteSpace: 'pre-line',
  },
  preview: {
    borderRadius: '15px',
  },
  fab: {
    position: 'fixed',
    right: '40px',
    bottom: '30px',
  },
}));

export default useStyles;
