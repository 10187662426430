import axios from 'axios';
import {
  apiNotifications,
  apiNotificationUsers,
} from '../constants/api';
import { getToken } from '../services/AuthService';
import { INotification } from '../store/notification/types';

// eslint-disable-next-line import/prefer-default-export
export const apiGetNotifications = async () => {
  const token: string = await getToken();
  const data = await axios.get(
    `${process.env.REACT_APP_API_ROOT}${apiNotifications}`,
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return data;
};

export const apiPostNotification = async (content: Partial<INotification>) => {
  const token: string = await getToken();
  const data = await axios.post(
    `${process.env.REACT_APP_API_ROOT}${apiNotifications}`,
    content,
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return data;
};

export const apiPutNotification = async (id: string, content: Partial<INotification>) => {
  const token: string = await getToken();
  const data = await axios.put(
    `${process.env.REACT_APP_API_ROOT}${apiNotifications}/${id}`,
    content,
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return data;
};

export const apiDeleteNotification = async (id: string) => {
  const token: string = await getToken();
  const data = await axios.delete(
    `${process.env.REACT_APP_API_ROOT}${apiNotifications}/${id}`,
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return data;
};

export const apiPostNotificationUsers = async (content: Partial<INotification>) => {
  const token: string = await getToken();
  const data = await axios.post(
    `${process.env.REACT_APP_API_ROOT}${apiNotificationUsers}`,
    content,
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return data;
};
export const apiGetNotificationUsers = async (id: string) => {
  const token: string = await getToken();
  const data = await axios.get(
    `${process.env.REACT_APP_API_ROOT}${apiNotifications}/${id}/users`,
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return data;
};
