import { makeStyles } from '@material-ui/core/styles';
import {
  thea300, tonique2,
} from '../../../constants/colors';

const useStyles = makeStyles(() => ({
  buttonEdit: {
    color: thea300,
    fontWeight: 500,
    fontFamily: 'FuturaPTMedium',
  },
  buttonDisable: {
    color: tonique2,
    fontWeight: 500,
    fontFamily: 'FuturaPTMedium',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  book: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1rem',
    alignItems: 'center',
    position: 'relative',
    width: '240px',
    textAlign: 'center',
  },
  picture: {
    borderRadius: '15px',
    objectFit: 'contain',
  },
  bookTitle: {
    textTransform: 'uppercase',
    margin: '5px 0',
  },
  icon: {
    position: 'absolute',
    right: '0',
    cursor: 'pointer',
  },
}));

export default useStyles;
