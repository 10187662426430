import React from 'react';
import { thea75 } from '../../constants/colors';

interface Props {
  color?: string,
  style?: any,
  width?: number,
  height?: number,
}

const PlusIcon: React.FC<Props> = ({
  color, style, width = 16, height = 16,
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={width}
    height={height}
    style={style}
  >
    <g data-name="Layer 2">
      <g data-name="plus">
        <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0" />
        <path d="M19 11h-6V5a1 1 0 0 0-2 0v6H5a1 1 0 0 0 0 2h6v6a1 1 0 0 0 2 0v-6h6a1 1 0 0 0 0-2z" fill={color} />
      </g>
    </g>
  </svg>
);

const defaultProps: Props = {
  color: thea75,
  style: {},
  width: 16,
  height: 16,
};

PlusIcon.defaultProps = defaultProps;

export default PlusIcon;
