import {
  AxiosError,
  AxiosResponse,
} from 'axios';
import {
  STORE_ME_SAVED,
  STORE_ME_FAILED,
  STORE_ME_PENDING,
  STORE_ME_SENDING,
  STORE_ME_RESET_STATUS,
  STORE_ME_RECEIVED,
} from './actionTypes';
import {
  DispatchType,
  IMe,
  MeAction,
} from './types';
import {
  apiGetMe,
  apiPutMe,
} from '../../api/apiMe';

const mePendingAction = (): MeAction => ({
  type: STORE_ME_PENDING,
});

const meSendingAction = (): MeAction => ({
  type: STORE_ME_SENDING,
});

const meSavedAction = (me: IMe): MeAction => ({
  type: STORE_ME_SAVED,
  me,
});

const meReceivedAction = (me: IMe): MeAction => ({
  type: STORE_ME_RECEIVED,
  me,
});

const meFailedAction = (error: string): MeAction => ({
  type: STORE_ME_FAILED,
  error,
});

const MeResetStatus = (): MeAction => ({
  type: STORE_ME_RESET_STATUS,
});

export const resetStatus = () => (dispatch: DispatchType) => {
  dispatch(MeResetStatus());
};
export const getMe = () => (dispatch: DispatchType) => {
  dispatch(mePendingAction());
  apiGetMe()
    .then((data: AxiosResponse) => dispatch(meReceivedAction(data.data)))
    .catch((error: AxiosError) => dispatch(meFailedAction(error.message)));
};

export const putMe = (me: IMe) => (dispatch: DispatchType) => {
  dispatch(meSendingAction());
  apiPutMe(me)
    .then((data: AxiosResponse) => dispatch(meSavedAction(data.data)))
    .catch((error: AxiosError) => dispatch(meFailedAction(error.message)));
};
