import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import anonymousFr from './locales/fr/anonymous.json';
import backofficeFr from './locales/fr/backoffice.json';

const resources = {
  fr: {
    anonymous: anonymousFr,
    backoffice: backofficeFr,
  },
};

i18n
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false,
    },
    resources,
  });

export default i18n;
