import { makeStyles } from '@material-ui/core/styles';
import { nuance300, thea500 } from '../../constants/colors';

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: 'none',
    color: nuance300,
    fontSize: '14px',
  },
  linkActive: {
    color: thea500,
  },
}));

export default useStyles;
