import React from 'react';
import {
  Picture,
} from '../../../store/event/types';
import useStyles from './EventListPicture.style';
import CloseIcon from '../../../components/icon/CloseIcon';

type Props = {
  pictures: Picture[],
  /* eslint-disable no-unused-vars */
  onDelete: (pictureId: string) => void
}

const EventListPicture = ({ pictures, onDelete }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.listPicture}>
      {pictures.map((picture) => (
        <div key={picture.id} className={classes.picture}>
          <img
            src={picture.file_path}
            alt=""
            width={100}
            height={150}
            className={classes.image}
          />
          <CloseIcon
            className={`${classes.icon} icon`}
            onClick={() => picture.id && onDelete(picture.id)}
          />
        </div>
      ))}
    </div>
  );
};

export default React.memo(EventListPicture);
