import {
  AxiosError,
  AxiosResponse,
} from 'axios';
import {
  DispatchType, IToolbox, ToolboxAction,
} from './types';
import {
  STORE_TOOLBOX_ADDED_SENT,
  STORE_TOOLBOX_DELETED,
  STORE_TOOLBOX_FAILED,
  STORE_TOOLBOX_PENDING,
  STORE_TOOLBOX_LOADED,
  STORE_TOOLBOX_RESET_STATUS,
  STORE_TOOLBOX_SENDING,
  STORE_TOOLBOX_UPDATED_SENT,

} from './actionTypes';
import {
  apiGetToolboxes,
  apiDeleteToolbox,
  apiPostToolbox,
  apiEditToolbox,
} from '../../api/apiToolbox';

const toolboxDeletedAction = (toolboxId: string): ToolboxAction => ({
  type: STORE_TOOLBOX_DELETED,
  toolboxId,
});

const toolboxFailedAction = (error: string): ToolboxAction => ({
  type: STORE_TOOLBOX_FAILED,
  error,
});

const toolboxPendingAction = (): ToolboxAction => ({
  type: STORE_TOOLBOX_PENDING,
});

const toolboxLoadedAction = (toolboxes: IToolbox[]): ToolboxAction => ({
  type: STORE_TOOLBOX_LOADED,
  toolboxes,
});

const toolboxSendingAction = (): ToolboxAction => ({
  type: STORE_TOOLBOX_SENDING,
});

const toolboxAddedSentAction = (toolbox: IToolbox): ToolboxAction => ({
  type: STORE_TOOLBOX_ADDED_SENT,
  toolbox,
});

const toolboxUpdatedAction = (toolbox: IToolbox): ToolboxAction => ({
  type: STORE_TOOLBOX_UPDATED_SENT,
  toolbox,
});

const toolboxResetStatus = (): ToolboxAction => ({
  type: STORE_TOOLBOX_RESET_STATUS,
});

export const resetStatus = () => (dispatch: DispatchType) => {
  dispatch(toolboxResetStatus());
};
export const deleteToolbox = (toolboxId: string) => (dispatch: DispatchType) => {
  apiDeleteToolbox(toolboxId)
    .then(() => dispatch(toolboxDeletedAction(toolboxId)))
    .catch((error: AxiosError) => dispatch(toolboxFailedAction(error.message)));
};

export const getToolboxes = () => (dispatch: DispatchType) => {
  dispatch(toolboxPendingAction());
  apiGetToolboxes()
    .then((data: AxiosResponse) => dispatch(toolboxLoadedAction(data.data)))
    .catch((error: AxiosError) => dispatch(toolboxFailedAction(error.message)));
};

export const postToolbox = (
  content: Partial<IToolbox>,
  file: File,
) => (dispatch: DispatchType) => {
  dispatch(toolboxSendingAction());
  apiPostToolbox(content, file)
    .then((data: AxiosResponse) => dispatch(toolboxAddedSentAction(data.data)))
    .catch((error: AxiosError) => dispatch(toolboxFailedAction(error.message)));
};

export const putToolbox = (
  toolboxId: string,
  content: Partial<IToolbox>,
  file: File | string,
) => (dispatch: DispatchType) => {
  dispatch(toolboxSendingAction());
  apiEditToolbox(toolboxId, content, file)
    .then((data: AxiosResponse) => dispatch(toolboxUpdatedAction(data.data)))
    .catch((error: AxiosError) => dispatch(toolboxFailedAction(error.message)));
};
