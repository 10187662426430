import React from 'react';
import { feedbackError } from '../../constants/colors';

type Props = {
  className: string,
  onClick: () => void
  color?: string,
  width?: number,
  height?: number,
}

const CloseIcon = ({
  className, color, width = 20, height = 20, onClick,
}: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={className}
    width={width}
    height={height}
    onClick={onClick}
  >
    <g data-name="Layer 2">
      <g data-name="close-circle">
        <rect width="24" height="24" opacity="0" />
        <path fill={color} d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm2.71 11.29a1 1 0 0 1 0 1.42 1 1 0 0 1-1.42 0L12 13.41l-1.29 1.3a1 1 0 0 1-1.42 0 1 1 0 0 1 0-1.42l1.3-1.29-1.3-1.29a1 1 0 0 1 1.42-1.42l1.29 1.3 1.29-1.3a1 1 0 0 1 1.42 1.42L13.41 12z" />
      </g>
    </g>
  </svg>
);

const defaultProps: Partial<Props> = {
  color: feedbackError,
  width: 20,
  height: 20,
};

CloseIcon.defaultProps = defaultProps;

export default CloseIcon;
