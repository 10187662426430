import { makeStyles } from '@material-ui/core/styles';
import { nuance200, thea150 } from '../../constants/colors';

const useStyles = makeStyles(() => ({
  paper: {
    height: '40px',
    backgroundColor: thea150,
    borderRadius: '10px',
    marginBottom: '20px',
    padding: '8px',
    position: 'relative',
    top: '20px',
    width: '100%',
  },
  formControl: {
    width: '100%',
    height: '80px',
  },
  formControlError: {
    width: '100%',
    height: '94px',
  },
  input: {
    position: 'absolute',
    top: '-35px',
    left: '3px',
    padding: '8px 16px',
    width: '100%',
    '& > .MuiFormLabel-root.Mui-focused': {
      color: nuance200,
    },
    '& > .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& > .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
    '& > .MuiInput-underline:after': {
      borderBottom: 'none',
    },
  },
  label: {
    marginLeft: '3px',
  },
  helperText: {
    width: '100%',
    position: 'relative',
    marginLeft: '3px',
    // top: '-5px',
  },
}));

export default useStyles;
