import React, { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';
import {
  ClickAwayListener,
  Fade,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { INotification } from '../../../store/notification/types';
import useStyles from './NotificationTable.style';
import MoreVerticalOutline from '../../../components/icon/MoreVerticalOutline';
import { routeNotification } from '../../../constants/routes';
import CalendarFillIcon from '../../../components/icon/CalendarFillIcon';
import FileIcon from '../../../components/icon/FileIcon';
import CheckMarkCircleFillIcon from '../../../components/icon/CheckMarkCircleFillIcon';
import { nuance150 } from '../../../constants/colors';
import AlertCircleFillIcon from '../../../components/icon/AlertCircleFillIcon';
import { deleteNotification } from '../../../store/notification/actions';

type Props = {
  notifications: INotification[]
}

const getStatusIcon = (status: string) => {
  const style = {
    display: 'inline-block',
    verticalAlign: 'text-bottom',
    marginRight: '0.75em',
  };
  switch (status) {
    case 'draft':
      return <FileIcon style={style} height={24} width={24} />;
    case 'now':
      return <CalendarFillIcon style={style} />;
    case 'scheduled':
      return <CalendarFillIcon style={style} />;
    case 'sent':
      return <CheckMarkCircleFillIcon style={style} color={nuance150} />;
    default:
      return <AlertCircleFillIcon style={style} height={24} width={24} />;
  }
};

const NotificationTable: React.FC<Props> = ({ notifications }: Props) => {
  const { t } = useTranslation(['backoffice']);
  const classes = useStyles();
  const history = useHistory();
  const dispatch: Dispatch<any> = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [notificationSelected, setNotificationSelected] = React.useState<INotification | null>(
    null,
  );
  const open = Boolean(anchorEl);
  const id = open ? 'popper-notification' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLElement>, notification: INotification) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setNotificationSelected(notification);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };
  const handleClickEdit = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    history.push(`${routeNotification}/edit/${notificationSelected?.id}`);
  };

  const handleClickDelete = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    if (notificationSelected) {
      dispatch(deleteNotification(notificationSelected.id));
    }
  };

  return (

    <TableContainer component={Paper} style={{ padding: '30px' }}>
      <Table aria-label="flashInfo table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell}>
              {t('notification.table.status')}
            </TableCell>
            <TableCell className={classes.cell} align="right">
              {t('notification.table.users')}
            </TableCell>
            <TableCell className={classes.cell}>
              {t('notification.table.title')}
            </TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {notifications.map((notification) => (
            <TableRow key={notification.id}>
              <TableCell
                component="th"
                scope="row"
                className={notification.status === 'sent' ? classes.sentRow : ''}
              >
                {getStatusIcon(notification.status)}
                {t(`notification.table.statusRow.${notification.status}`)}
                {notification.send_date
                  && notification.status !== 'error'
                  && format(new Date(notification.send_date), 'dd/MM/yyyy HH:mm')}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                align="right"
                className={notification.status === 'sent' ? classes.sentRow : ''}
              >
                {notification.user_count}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                className={notification.status === 'sent' ? classes.sentRow : ''}
              >
                {notification.title}
              </TableCell>
              <TableCell align="right">
                <IconButton
                  onClick={(e) => handleClick(e, notification)}
                >
                  <MoreVerticalOutline />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            style={{ zIndex: 2 }}
            transition
          >
            {({ TransitionProps }) => (
              <ClickAwayListener onClickAway={handleClickAway}>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <Fade {...TransitionProps} timeout={350}>
                  <Paper elevation={1}>
                    <MenuList>
                      <MenuItem
                        onClick={handleClickEdit}
                        className={classes.buttonEdit}
                      >
                        {t('common.edit')}
                      </MenuItem>
                      <MenuItem
                        onClick={handleClickDelete}
                        className={classes.buttonDelete}
                      >
                        {t('common.delete')}
                      </MenuItem>
                    </MenuList>
                  </Paper>
                </Fade>
              </ClickAwayListener>
            )}
          </Popper>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default NotificationTable;
