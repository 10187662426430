import React, {
  Dispatch,
  FormEvent,
  useEffect,
  useState,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  DefaultRootState,
  useDispatch,
  useSelector,
} from 'react-redux';
import { useHistory } from 'react-router';
import useStyles from '../../../components/paper/MainPaper.style';
import WhitePaper from '../../../components/paper/WhitePaper';
import GreyTextInput from '../../../components/form/GreyTextInput';
import {
  IMe,
  MeState,
} from '../../../store/me/types';
import {
  getMe,
  putMe,
  resetStatus,
} from '../../../store/me/actions';
import ProgressContainer from '../../../components/common/ProgressContainer';
import { routeChangePassword } from '../../../constants/routes';
import { NotificationContext } from '../../../contexts/NotificationContext';

type Props = {}

const AccountPage: React.FC<Props> = () => {
  const { t } = useTranslation(['backoffice']);
  const classes = useStyles();
  const history = useHistory();
  const { setNotification } = useContext(NotificationContext);
  const dispatch: Dispatch<any> = useDispatch();
  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [email, setEmail] = useState('');
  const {
    me,
    pending,
    sending,
    error,
    status,
    // @ts-ignore
  } = useSelector<DefaultRootState, MeState>((state) => state.meStore);

  if (me === null && !pending && error === null) {
    dispatch(getMe());
  }

  useEffect(() => {
    if (me !== null) {
      setFirstName(me.first_name || '');
      setLastName(me.last_name || '');
      setEmail(me.email || '');
    }
  }, [me]);

  useEffect(() => {
    if (status === 'ERROR') {
      setNotification({ isOpened: true, severity: 'error', message: error || '' });
    }

    if (status === 'SUCCESS') {
      setNotification({ isOpened: true, severity: 'success', message: t('account.changeInformationSuccess') });
    }
    if (status !== 'INIT') dispatch(resetStatus());
  }, [status]);

  const handleChangePassword = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    history.push(routeChangePassword);
  };

  const handleFormAction = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (me !== null) {
      let formError = false;
      if (firstName.trim() === '') {
        formError = true;
        setFirstNameError(t('account.firstNameRequired'));
      }
      if (lastName.trim() === '') {
        formError = true;
        setLastNameError(t('account.lastNameRequired'));
      }
      if (!formError) {
        const newMe: IMe = {
          id: me.id,
          email: me.email,
          first_name: firstName,
          last_name: lastName,
        };
        dispatch(putMe(newMe));
      }
    }
  };

  const handleChangeFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setFirstName(event.target.value);
    setFirstNameError('');
  };

  const handleChangeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setLastName(event.target.value);
    setLastNameError('');
  };

  return (
    <>
      <Typography variant="h1" className={classes.title}>
        {t('title.account')}
      </Typography>
      <ProgressContainer loading={pending}>
        <form onSubmit={handleFormAction}>
          <Grid container spacing={3}>
            <Grid item id="form-grid-item" xs={12} md={9}>
              <WhitePaper title={t('account.changeInformation')}>
                <GreyTextInput
                  type="text"
                  value={email}
                  label={t('user.email')}
                  onChange={() => {
                  }}
                  disabled
                />
                <GreyTextInput
                  type="text"
                  value={lastName}
                  label={t('user.lastName')}
                  onChange={handleChangeLastName}
                  error={lastNameError !== ''}
                  helperText={lastNameError}
                />
                <GreyTextInput
                  type="text"
                  value={firstName}
                  label={t('user.firstName')}
                  onChange={handleChangeFirstName}
                  error={firstNameError !== ''}
                  helperText={firstNameError}
                />
              </WhitePaper>
            </Grid>
            <Grid item id="validation-grid-item" xs={12} md={3}>
              <Button
                variant="outlined"
                color="primary"
                type="button"
                className={classes.fullWidth}
                disabled={sending}
                onClick={handleChangePassword}
              >
                <Typography variant="button" color="inherit">
                  {t('account.changePassword')}
                </Typography>
              </Button>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                className={classes.fullWidth}
                disabled={sending}
              >
                <Typography variant="button">
                  {t('common.save')}
                </Typography>
                {
                  sending
                  && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                      color="primary"
                    />
                  )
                }
              </Button>
            </Grid>
          </Grid>
        </form>
      </ProgressContainer>
    </>
  );
};

export default AccountPage;
