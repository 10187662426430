import React, { Dispatch } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Popper,
  ClickAwayListener,
  Fade,
  MenuList,
  MenuItem,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import useStyles from '../../flash-info/FlashInfoTable.style';
import MoreVerticalOutline from '../../../../components/icon/MoreVerticalOutline';
import { IUser } from '../../../../store/user/types';
import { deleteUser } from '../../../../store/user/actions';
import { routeUsers } from '../../../../constants/routes';
import UserModalDelete from '../UserModalDelete';

type Props = {
  users: IUser[],
  userIdConnected: string
}

const UsersAdminTable = ({
  users,
  userIdConnected,
}: Props) => {
  const { t } = useTranslation(['backoffice']);
  const classes = useStyles();

  const dispatch: Dispatch<any> = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [userSelected, setUserSelected] = React.useState<IUser | null>(null);
  const history = useHistory();
  const open = Boolean(anchorEl);
  const id = open ? 'popper-flashInfo' : undefined;
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>, user: IUser) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setUserSelected(user);
  };

  const handleClickOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleDeleteEvent = () => {
    if (userSelected) {
      dispatch(deleteUser(userSelected.id));
      setOpenModal(false);
    }
  };

  const handleClickEdit = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    history.push(`${routeUsers}/admin/edit/${userSelected?.id}`);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  return (
    <TableContainer component={Paper} style={{ padding: '30px' }}>
      <Table aria-label="users table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell}>{t('users.table.email')}</TableCell>
            <TableCell className={classes.cell}>{t('users.table.lastName')}</TableCell>
            <TableCell className={classes.cell}>{t('users.table.firstName')}</TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow key={user.id}>
              <TableCell component="th" scope="row">
                {user.email}
              </TableCell>
              <TableCell component="th" scope="row">
                {user.last_name}
              </TableCell>
              <TableCell component="th" scope="row">
                {user.first_name}
              </TableCell>
              <TableCell align="right">
                <IconButton
                  onClick={(e) => handleClick(e, user)}
                >
                  <MoreVerticalOutline />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            style={{ zIndex: 2 }}
            transition
          >
            {({ TransitionProps }) => (
              <ClickAwayListener onClickAway={handleClickAway}>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <Fade {...TransitionProps} timeout={350}>
                  <Paper elevation={1}>
                    <MenuList>
                      <MenuItem
                        onClick={(handleClickEdit)}
                        className={classes.buttonEdit}
                      >
                        {t('common.edit')}
                      </MenuItem>
                      {userSelected?.id !== userIdConnected && (
                        <MenuItem
                          onClick={handleClickOpenModal}
                          className={classes.buttonDelete}
                        >
                          {t('common.delete')}
                        </MenuItem>
                      )}
                    </MenuList>
                  </Paper>
                </Fade>
              </ClickAwayListener>
            )}
          </Popper>
        </TableBody>
      </Table>
      {userSelected
      && (
      <UserModalDelete
        open={openModal}
        handleClose={handleCloseModal}
        handleValidate={handleDeleteEvent}
        email={userSelected.email}
      />
      )}
    </TableContainer>
  );
};

export default React.memo(UsersAdminTable);
