import { AxiosResponse } from 'axios';
import {
  storageAccessToken,
  storageRefreshToken,
  storageTokenTimeout,
} from '../constants/auth';
/* eslint-disable import/no-cycle */
import { apiPostRefresh } from '../api/apiUser';

export const getNewTimeout = (): number => {
  const dateTime: Date = new Date();
  return Math.floor(dateTime.getTime() / 1000) + 3600;
};

export const hasToken = (): boolean => {
  const token: string | null = localStorage.getItem(storageAccessToken);
  const refresh: string | null = localStorage.getItem(storageRefreshToken);
  const timeout: string | null = localStorage.getItem(storageTokenTimeout);
  return token !== null && refresh !== null && timeout !== null;
};

export const tokenRemainingTime = (): number => {
  if (hasToken()) {
    const timeout: string | null = localStorage.getItem(storageTokenTimeout);
    if (timeout !== null) {
      const dateTime: Date = new Date();
      const now: number = Math.floor(dateTime.getTime() / 1000);
      const intTimeout: number = parseInt(timeout, 10);
      return intTimeout - now;
    }
  }
  return -1; // no token or expired
};

export const refreshToken = async (): Promise<string> => {
  if (hasToken()) {
    const refresh: string | null = localStorage.getItem(storageRefreshToken);
    if (refresh !== null) {
      return apiPostRefresh(refresh)
        .then((data: AxiosResponse) => {
          localStorage.setItem(storageAccessToken, data.data.token);
          localStorage.setItem(storageRefreshToken, data.data.refresh_token);
          localStorage.setItem(storageTokenTimeout, getNewTimeout().toString());
          return data.data.token;
        }).catch((apiError: any) => {
          // eslint-disable-next-line no-console
          console.log(apiError);
        });
    }
  }
  return '';
};

export const getToken = async (): Promise<string> => {
  if (tokenRemainingTime() > 60) {
    return localStorage.getItem(storageAccessToken) || '';
  }
  return refreshToken().then(
    (token: string) => token,
  );
};
