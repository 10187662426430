import axios from 'axios';
import { apiFlashInfos } from '../constants/api';
import { getToken } from '../services/AuthService';
import { IFlashInfo } from '../store/flash-info/types';

export const apiGetFlashInfos = async () => {
  const token: string = await getToken();
  const data = await axios.get(
    `${process.env.REACT_APP_API_ROOT}${apiFlashInfos}`,
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return data;
};

export const apiPostFlashInfo = async (content: Pick<IFlashInfo, 'title' | 'description' | 'start_date' | 'end_date' | 'display_end_date'>, file?: File) => {
  const token: string = await getToken();

  const formData = new FormData();
  formData.append('content', JSON.stringify(content));
  if (file) {
    formData.append('picture', file);
  }
  const data = await axios.post(
    `${process.env.REACT_APP_API_ROOT}${apiFlashInfos}`,
    formData,
    { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/x-www-form-urlencoded' } },
  );
  return data;
};

export const apiEditFlashInfo = async (
  flashInfoId: string,
  content: Pick<IFlashInfo, 'title' | 'description' | 'start_date' | 'end_date' | 'display_end_date'>,
  file?: File | string,
) => {
  const token: string = await getToken();

  const formData = new FormData();
  formData.append('content', JSON.stringify(content));
  if (file) {
    formData.append('picture', file);
  }
  const data = await axios.post(
    `${process.env.REACT_APP_API_ROOT}${apiFlashInfos}/${flashInfoId}`,
    formData,
    { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/x-www-form-urlencoded' } },
  );
  return data;
};

export const apiDeleteFlashInfo = async (flashInfoId: string) => {
  const token: string = await getToken();
  const data = await axios.delete(
    `${process.env.REACT_APP_API_ROOT}${apiFlashInfos}/${flashInfoId}`,
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return data;
};
