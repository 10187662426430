import React, {
  Dispatch,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  DefaultRootState,
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useHistory,
  useParams,
} from 'react-router';
import {
  convertToRaw,
  EditorState,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import useStyles from '../../../components/paper/MainPaper.style';
import WhitePaper from '../../../components/paper/WhitePaper';
import GreyTextInput from '../../../components/form/GreyTextInput';
import UploadFileButton from '../../../components/form/UploadFileButton';
import { routeEvents } from '../../../constants/routes';
import Breadcrumb from '../../../components/common/Breadcrumb';
import EventModalSubscriptions from './EventModalSubscriptions';
import { NotificationContext } from '../../../contexts/NotificationContext';
import DatePicker from '../../../components/form/DatePicker';
import formatDateWithTimezone from '../../../utils/formatDate';
import {
  eventCategories,
  EventState,
  IEvent,
  Picture,
  Program,
} from '../../../store/event/types';
import {
  deletePictureEvent,
  getEventCategories,
  getEventsAndCategories,
  getEventSubscriptions,
  postEvent,
  postEventStartPush,
  postPictureEvent,
  putEvent,
  resetPushStatus,
  resetStatus,
} from '../../../store/event/actions';
import SelectInput from '../../../components/form/SelectInput';
import AutocompleteInput from '../../../components/form/AutocompleteInput';
import EditorComponent from '../../../components/form/EditorComponent';
import SaveIcon from '../../../components/icon/SaveIcon';
import PlusIcon from '../../../components/icon/PlusIcon';
import FormProgramEvent from './FormProgramEvent';
import { htmlToEditorState } from '../../../utils/editorHelper';
import EventListPicture from './EventListPicture';
import BellIcon from '../../../components/icon/BellIcon';
import {
  IRegion,
  RegionState,
} from '../../../store/region/types';
import { getRegions } from '../../../store/region/actions';
import SwitchComponent from '../../../components/form/SwitchComponent';

type Props = {};

const subscriptionType = [
  {
    id: 'internal',
    title: 'Interne',
  },
  {
    id: 'link',
    title: 'Lien',
  },
  {
    id: 'none',
    title: 'Pas d\'inscription',
  },
];

const pushOptions = [
  {
    id: 'none',
    title: 'Notifier manuellement plus tard',
  },
  {
    id: 'now',
    title: 'Notifier maintenant',
  },
  {
    id: '30dayBefore',
    title: 'Notifier 1 mois avant l’événement',
  },
  {
    id: '15dayBefore',
    title: 'Notifier 15 jours avant l’événement',
  },
  {
    id: '7dayBefore',
    title: 'Notifier 7 jours avant l’événement',
  },
];

const eventPushOptionDays: any = {
  none: 0,
  now: 0,
  '30dayBefore': 30,
  '15dayBefore': 15,
  '7dayBefore': 7,
};

const FormEventPage: React.FC<Props> = () => {
  const { t } = useTranslation(['backoffice', 'common']);
  const classes = useStyles();
  const history = useHistory();
  const dispatch: Dispatch<any> = useDispatch();
  const slug: { id: string } = useParams();
  const { setNotification } = useContext(NotificationContext);

  const {
    events,
    error,
    status,
    sending,
    pushSending,
    pushStatus,
    pushError,
    categories,
    subscriptions,
  } = useSelector<DefaultRootState, EventState>(
    (state: any) => state.eventStore,
  );
  const regions = useSelector<DefaultRootState, RegionState>(
    (state: any) => state.regionStore,
  );

  const eventId = slug.id;
  let event: IEvent | undefined;

  if (eventId) {
    event = events.find((e: IEvent) => e.id === eventId);
  }

  const getFormValuesFromEvent = () => ({
    title: event?.title ?? '',
    description: event?.description ? htmlToEditorState(event.description as string)
      : EditorState.createEmpty(),
    start_date: event?.start_date ? new Date(event.start_date) : new Date(),
    end_date: event?.end_date ? new Date(event.end_date) : new Date(),
    can_subscribe: event?.can_subscribe ?? true,
    subscription_type: event?.subscription_type ?? '',
    subscription_link: event?.subscription_link ?? '',
    legal_description: event?.legal_description
      ? htmlToEditorState(event.legal_description as string)
      : EditorState.createEmpty(),
    program_legal_description: event?.program_legal_description
      ? htmlToEditorState(event.program_legal_description as string)
      : EditorState.createEmpty(),
    practical_text: event?.practical_text
      ? htmlToEditorState(event.practical_text as string)
      : EditorState.createEmpty(),
    program_complement: event?.program_complement
      ? htmlToEditorState(event.program_complement as string)
      : EditorState.createEmpty(),
    address: event?.address ?? '',
    programs: event?.programs.map((program) => (
      {
        ...program,
        description: program.description
          ? htmlToEditorState(program.description as string)
          : EditorState.createEmpty(),
      })) ?? [],
    subscription_questions: event?.subscription_questions ?? [],
    event_categories: event?.event_categories ?? [],
    subscription_question: !event
      ? true
      : (event?.subscription_questions ?? []).length !== 0,
    limit_inscription_date: event?.limit_inscription_date
      ? new Date(event.limit_inscription_date)
      : null,
    push_option: 'none',
    region_id: event?.region_id ?? '',
    maximum_subscriptions_count: event?.maximum_subscriptions_count ?? null,
  });

  useEffect(() => {
    if (events.length === 0) {
      dispatch(getEventsAndCategories());
    }
    if (regions.regions.length === 0) {
      dispatch(getRegions());
    }
  }, []);

  const [open, setOpen] = React.useState(false);
  const [values, setValues] = useState(getFormValuesFromEvent());

  useEffect(() => {
    setValues(getFormValuesFromEvent());
  }, [events]);

  useEffect(() => {
    if (pushStatus === 'ERROR') {
      setNotification({ isOpened: true, severity: 'error', message: pushError || '' });
    }
    if (pushStatus === 'SUCCESS') {
      setNotification({ isOpened: true, severity: 'success', message: t('event.pushSuccess') });
    }
    if (pushStatus !== 'INIT') dispatch(resetPushStatus());
  }, [pushStatus]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [formErrors, setFormErrors] = useState({
    title: '',
    start_date: '',
    end_date: '',
    address: '',
    event_categories: '',
    subscription_type: '',
    subscription_link: '',
    pictures: '',
    description: '',
    practical_text: '',
    display_order: '',
    push_option: '',
    region_id: '',
    maximum_subscriptions_count: '',
  });

  const [pictures, setPictures] = useState<Picture[]>(event?.pictures || []);

  const handleFormAction = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    let formError = false;

    if (values.title === '') {
      formErrors.title = t('event.form.error.titleRequired');
      formError = true;
    }

    if (values.start_date === null) {
      formErrors.start_date = t('event.form.error.startDateRequired');
      formError = true;
    }

    if (values.end_date === null) {
      formErrors.end_date = t('event.form.error.endDateRequired');
      formError = true;
    }

    if (values.address === '') {
      formErrors.address = t('event.form.error.addressRequired');
      formError = true;
    }

    if (values.subscription_type === '') {
      formErrors.subscription_type = t('event.form.error.subscriptionTypeRequired');
      formError = true;
    }

    if (values.subscription_type === 'link' && values.subscription_link === '') {
      formErrors.subscription_type = t('event.form.error.subscriptionLinkRequired');
      formError = true;
    }

    if (values.event_categories.length === 0) {
      formErrors.event_categories = t('event.form.error.categoriesRequired');
      formError = true;
    }
    if (
      values.subscription_type === 'internal'
      && values.maximum_subscriptions_count !== undefined
      && values.maximum_subscriptions_count !== null
      && values.maximum_subscriptions_count < 1
    ) {
      formErrors.maximum_subscriptions_count = t('event.form.error.maximumSubscriptionsCountMin');
      formError = true;
    }

    const now = new Date().getTime() / 1000;

    const pushDate = (formErrors.start_date === '')
      ? ((values.start_date as Date).getTime() / 1000)
      - (eventPushOptionDays[values.push_option] * 86400)
      : 0;
    if (values.push_option.indexOf('dayBefore') !== -1 && pushDate < now) {
      formErrors.push_option = t('event.form.error.pushInThePast');
      formError = true;
    }

    setFormErrors({ ...formErrors });

    if (!formError) {
      const content: Partial<IEvent> = {
        title: values.title,
        description: draftToHtml(convertToRaw(
          (values.description as EditorState)
            .getCurrentContent(),
        )),
        start_date: formatDateWithTimezone(values.start_date as Date),
        end_date: formatDateWithTimezone(values.end_date as Date),
        can_subscribe: values.can_subscribe,
        subscription_type: values.subscription_type,
        subscription_link: values.subscription_link,
        legal_description: draftToHtml(convertToRaw(
          (values.legal_description as EditorState)
            .getCurrentContent(),
        )),
        program_legal_description: draftToHtml(convertToRaw(
          (values.program_legal_description as EditorState)
            .getCurrentContent(),
        )),
        practical_text: draftToHtml(convertToRaw(
          (values.practical_text as EditorState)
            .getCurrentContent(),
        )),
        program_complement: draftToHtml(convertToRaw(
          (values.program_complement as EditorState)
            .getCurrentContent(),
        )),
        address: values.address,
        subscription_questions: values.subscription_questions,
        event_categories: values.event_categories,
        subscription_question: values.subscription_question,
        limit_inscription_date: (values.limit_inscription_date !== null)
          ? formatDateWithTimezone(values.limit_inscription_date)
          : null,
        programs: values.programs.map((program: Program) => (
          {
            ...program,
            description: draftToHtml(convertToRaw(
              (program.description as EditorState)
                .getCurrentContent(),
            )),
          })) ?? [],
        push_option: values.push_option,
        region_id: values.region_id,
        maximum_subscriptions_count: values.maximum_subscriptions_count,
      };
      if (eventId) {
        dispatch(putEvent(eventId, content));
      } else {
        dispatch(postEvent(content));
      }
    }
  };

  const handleChange = (prop: keyof Partial<IEvent>, value: any) => {
    setValues({ ...values, [prop]: value });
    setFormErrors({ ...formErrors, [prop]: '' });
  };

  const handleChangeProgram = (prop: keyof Partial<Program>, value: any, index: number) => {
    setValues(
      {
        ...values,
        programs: values.programs
          .map((p, i) => (i === index ? { ...p, [prop]: value } : p)),
      },
    );
  };

  const handleSendStartPush = () => {
    dispatch(postEventStartPush(eventId));
  };

  const addProgram = () => {
    const program = { time: '', description: EditorState.createEmpty(), display_order: 1 };
    setValues({ ...values, programs: [...values.programs, program] });
  };

  const deleteProgram = (index: number) => {
    setValues({ ...values, programs: values.programs.filter((p, i) => i !== index) });
  };

  const uploadFile = (file: File) => {
    if (file && eventId) {
      try {
        dispatch(postPictureEvent(eventId, file));
      } catch {
        setNotification({ isOpened: true, severity: 'error', message: error || '' });
      }
    }
  };

  useEffect(() => {
    if (event && event.pictures.length !== pictures.length) {
      setPictures(event.pictures);
    }
  }, [event]);

  const deletePicture = (pictureId: string) => {
    try {
      dispatch(deletePictureEvent(eventId, pictureId));
      setPictures(() => pictures.filter((picture) => picture.id !== pictureId));
    } catch {
      setNotification({ isOpened: true, severity: 'error', message: error || '' });
    }
  };

  useEffect(() => {
    if (status === 'ERROR') {
      setNotification({ isOpened: true, severity: 'error', message: error || '' });
    }

    if (status === 'SUCCESS') {
      setNotification({ isOpened: true, severity: 'success', message: t('event.form.success') });
      history.push(routeEvents);
    }

    if (status !== 'INIT') dispatch(resetStatus());
  }, [status]);

  useEffect(() => {
    dispatch(getEventCategories());
    if (eventId) {
      dispatch(getEventSubscriptions(eventId));
    }
  }, [eventId]);

  const regionList = regions.regions.map((region: IRegion) => ({
    id: region.id,
    title: region.name,
  }));

  regionList.unshift({
    id: '',
    title: t('event.form.input.empty_region_id'),
  });
  return (
    <>
      <Breadcrumb
        title={t('title.events')}
        baseUrl={routeEvents}
        link={event && event.title}
      />
      <Typography variant="h1" className={classes.title}>
        {event && event.title}
      </Typography>
      <form onSubmit={handleFormAction}>
        <Grid container spacing={4}>
          <Grid item xs={9}>
            <Grid container spacing={4}>

              <Grid item id="form-grid-item" xs={12}>
                <WhitePaper title={t('event.form.subtitle.global')}>
                  <GreyTextInput
                    type="text"
                    value={values.title}
                    label={t('event.form.input.title')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('title', e.target.value)}
                    error={formErrors.title !== ''}
                    helperText={formErrors.title}
                  />
                  <AutocompleteInput
                    options={categories}
                    onChange={(e: object, value: eventCategories[]) => handleChange('event_categories', value.map((v) => v.id))}
                    label={t('event.form.input.categories')}
                    error={formErrors.event_categories !== ''}
                    helperText={formErrors.event_categories}
                    value={categories
                      .filter((category) => values.event_categories.includes(category.id))}
                  />
                  <DatePicker
                    value={values.start_date}
                    onChange={(date: MaterialUiPickersDate) => handleChange('start_date', date)}
                    error={formErrors.start_date !== ''}
                    helperText={formErrors.start_date}
                    label={t('event.form.input.startDate')}
                  />
                  <DatePicker
                    value={values.end_date}
                    onChange={(date: MaterialUiPickersDate) => handleChange('end_date', date)}
                    error={formErrors.end_date !== ''}
                    helperText={formErrors.end_date}
                    label={t('event.form.input.endDate')}
                  />
                  <GreyTextInput
                    type="text"
                    value={values.address}
                    label={t('event.form.input.address')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('address', e.target.value)}
                    error={formErrors.address !== ''}
                    helperText={formErrors.address}
                  />
                  <SelectInput
                    label={t('event.form.input.subscriptionType')}
                    values={subscriptionType}
                    valueSelected={values.subscription_type}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('subscription_type', e.target.value)}
                    error={formErrors.subscription_type !== ''}
                    helperText={formErrors.subscription_type}
                  />
                  {values.subscription_type === 'internal' && (
                    <>
                      <GreyTextInput
                        type="number"
                        value={values.maximum_subscriptions_count ?? ''}
                        label={t('event.form.input.maximumSubscriptionsCount')}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(
                          'maximum_subscriptions_count',
                          e.target.value === '' ? null : Number.parseInt(e.target.value, 10),
                        )}
                        error={formErrors.maximum_subscriptions_count !== ''}
                        helperText={
                          formErrors.maximum_subscriptions_count !== ''
                            ? formErrors.maximum_subscriptions_count
                            : t('event.form.input.maximumSubscriptionsCountHelperText')
                        }
                        inputProps={{
                          step: 1,
                          min: 0,
                        }}
                      />
                      <SwitchComponent
                        value={values.subscription_question}
                        onChange={(e: any) => handleChange('subscription_question', e.target.checked)}
                        label={t('event.form.input.subscriptionQuestion')}
                        name="subscription_question"
                        disabled={!!event}
                      />
                    </>
                  )}
                  {values.subscription_type === 'link' && (
                    <GreyTextInput
                      type="text"
                      value={values.subscription_link}
                      label={t('event.form.input.subscriptionLink')}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('subscription_link', e.target.value)}
                      error={formErrors.subscription_link !== ''}
                      helperText={formErrors.subscription_link}
                    />
                  )}
                  <DatePicker
                    value={values.limit_inscription_date}
                    onChange={(date: MaterialUiPickersDate) => handleChange('limit_inscription_date', date)}
                    label={t('event.form.input.limitInscriptionDate')}
                  />
                  {!(event?.id)
                    && (
                      <SelectInput
                        label={t('event.form.input.pushOption')}
                        values={pushOptions}
                        valueSelected={values.push_option}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('push_option', e.target.value)}
                        error={formErrors.push_option !== ''}
                        helperText={formErrors.push_option}
                      />
                    )}

                  <SelectInput
                    label={t('event.form.input.region_id')}
                    values={regionList}
                    valueSelected={values.region_id}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('region_id', e.target.value)}
                    error={formErrors.region_id !== ''}
                    helperText={formErrors.region_id}
                  />
                  <Typography variant="body2">
                    <>
                      {t('event.form.input.region_detail')}
                      <br />
                      {t('event.form.input.region_detail_2')}
                    </>
                  </Typography>
                </WhitePaper>
              </Grid>
              {eventId && (
                <Grid item xs={12}>
                  <WhitePaper title={t('event.form.subtitle.pictures')}>
                    <EventListPicture
                      pictures={pictures}
                      onDelete={(pictureId: string) => deletePicture(pictureId)}
                    />
                    <UploadFileButton
                      uploadFile={uploadFile}
                      label={t('common.addPicture')}
                      error={formErrors.pictures !== ''}
                      helperText={formErrors.pictures}
                    />
                  </WhitePaper>
                </Grid>
              )}

              <Grid item xs={12}>
                <WhitePaper title={t('event.form.subtitle.brief')}>
                  <EditorComponent
                    label={t('event.form.input.description')}
                    value={values.description}
                    error={formErrors.description !== ''}
                    helperText={formErrors.description}
                    onChange={(editor: EditorState) => handleChange('description', editor)}
                  />
                  <EditorComponent
                    label={t('event.form.input.legalDescription')}
                    value={values.legal_description}
                    onChange={(editor: EditorState) => handleChange('legal_description', editor)}
                  />
                </WhitePaper>
              </Grid>

              <Grid item xs={12}>
                <WhitePaper title={t('event.form.subtitle.program')}>
                  <EditorComponent
                    label={t('event.form.input.programComplement')}
                    value={values.program_complement}
                    onChange={(editor: EditorState) => handleChange('program_complement', editor)}
                  />

                  <Typography variant="h6">
                    {t('event.programTitle')}
                  </Typography>

                  {values.programs.map(((program, index) => (
                    <FormProgramEvent
                      key={program?.id || index}
                      time={program.time}
                      sortOrder={program.display_order}
                      text={program.description}
                      formErrors={formErrors}
                      onChangeEditor={(editor: EditorState) => handleChangeProgram('description', editor, index)}
                      onChangeDate={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeProgram('time', e.target.value, index)}
                      onChangeSortOrder={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeProgram('display_order', Number(e.target.value), index)}
                      onDeleteProgram={() => deleteProgram(index)}
                    />
                  )))}

                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<PlusIcon width={16} height={16} />}
                    className={classes.buttonAddActivity}
                    onClick={() => addProgram()}
                  >
                    <Typography variant="button">
                      {t('event.buttonAddActivity')}
                    </Typography>
                  </Button>
                  <EditorComponent
                    label={t('event.form.input.programLegalDescription')}
                    value={values.program_legal_description}
                    onChange={(editor: EditorState) => handleChange('program_legal_description', editor)}
                  />
                </WhitePaper>
              </Grid>

              <Grid item xs={12}>
                <WhitePaper title={t('event.form.subtitle.practical')}>
                  <EditorComponent
                    label={t('event.form.input.practicalText')}
                    value={values.practical_text}
                    onChange={(editor: EditorState) => handleChange('practical_text', editor)}
                    error={formErrors.practical_text !== ''}
                    helperText={formErrors.practical_text}
                  />
                </WhitePaper>
              </Grid>

            </Grid>
          </Grid>

          <Grid item xs={3}>
            <Grid container id="validation-grid-item" spacing={4} direction="column">

              <Grid item xs={12}>
                {eventId && event && (event.subscription_type === 'internal' || subscriptions.length > 0) && (
                  <WhitePaper title="">
                    <div>
                      <Typography variant="h3" color="primary" component="span">
                        {subscriptions.length}
                      </Typography>
                      {' '}
                      <Typography variant="body1" component="span">
                        {t('event.form.subscriptionText')}
                      </Typography>
                    </div>
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.buttonSeeList}
                      onClick={handleOpen}
                    >
                      <Typography variant="button">
                        {t('event.form.subscriptionButton')}
                      </Typography>
                    </Button>
                  </WhitePaper>
                )}
              </Grid>
              {eventId
                && event
                && !event?.has_been_pushed
                && (new Date().getTime() / 1000) < ((values.start_date as Date).getTime() / 1000)
                && (
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      {
                        t('event.form.pushEventCreatedChoice',
                          {
                            choice: pushOptions.find((p) => p.id === event?.push_option)!.title,
                          })
                      }
                      <br />
                      {t('event.form.pushEventCreatedExplain')}
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      type="button"
                      startIcon={<BellIcon color="#FFF" width={22} height={22} />}
                      disabled={pushSending}
                      className={classes.buttonAction}
                      onClick={handleSendStartPush}
                    >
                      <Typography variant="button">
                        {t('event.form.pushEventCreated')}
                      </Typography>
                      {
                        pushSending
                        && (
                          <CircularProgress
                            size={24}
                            className={classes.buttonProgress}
                            color="primary"
                          />
                        )
                      }
                    </Button>
                  </Grid>
                )}
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  startIcon={<SaveIcon width={22} height={22} />}
                  disabled={sending}
                  className={classes.buttonAction}
                >
                  <Typography variant="button">
                    {t('common.saveAndPublish')}
                  </Typography>
                  {
                    sending
                    && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                        color="primary"
                      />
                    )
                  }
                </Button>
              </Grid>

            </Grid>

          </Grid>
        </Grid>

      </form>
      {
        eventId && (
          <EventModalSubscriptions eventId={eventId} open={open} handleClose={handleClose} />
        )
      }
    </>
  );
};

export default FormEventPage;
