import { createSelector } from 'reselect';
import { State } from '../store';

const productSelector = (state: State) => state.productStore.products;
const categoriesIdSelector = (state: State) => state.productStore.categoryIds;

const productsFilteredSelector = createSelector(
  productSelector,
  categoriesIdSelector,
  (products, cid) => products.filter((e) => cid?.includes(e.product_category_id)),
);

export default productsFilteredSelector;
