import { makeStyles } from '@material-ui/core/styles';
import { thea300 } from '../../constants/colors';

const useStyles = makeStyles({
  root: {
    backgroundColor: thea300,
    margin: 0,
    padding: 0,
    width: '100vw',
    height: '100vh',
  },
});

export default useStyles;
