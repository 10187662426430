import React from 'react';
import { Autocomplete } from '@material-ui/lab';
import {
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import useStyles from './AutocompleteInput.style';
import {
  feedbackError,
  nuance100,
  nuance200,
} from '../../constants/colors';

type Props = {
  options: any[],
  /* eslint-disable no-unused-vars */
  onChange: (event: object, value: any[]) => void,
  label: string,
  helperText?: string,
  error?: boolean,
  value: any[],
  displayId?: boolean,
  disabled?: boolean
}

const AutocompleteInput = ({
  options, onChange, label, helperText, error, value, displayId, disabled,
}: Props) => {
  const classes = useStyles();
  return (
    <FormControl
      className={(error === true || helperText !== '') ? classes.formControlError : classes.formControl}
      error={error}
      disabled={disabled}
    >
      <InputLabel
        id="select-form"
        error={error}
        className={classes.label}
        disableAnimation
        disabled={disabled}
        shrink
      >
        <Typography variant="body1" style={error ? { color: feedbackError } : {}}>
          {label}
        </Typography>
      </InputLabel>
      <Paper elevation={0} className={classes.paper}>
        <Autocomplete
          multiple
          disabled={disabled}
          value={value}
          id="categories-standard"
          options={options}
          getOptionLabel={(option) => ((displayId) ? `${option.id} (${option.name ?? option.title})` : `${option.name ?? option.title ?? option}`)}
          onChange={onChange}
          className={classes.select}
          renderInput={(params) => (
            <TextField
              /* eslint-disable react/jsx-props-no-spreading */
              {...params}
              variant="standard"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          getOptionSelected={(option, val) => {
            if (option.id !== undefined) {
              return option.id === val.id;
            }
            return option === val;
          }}
          renderTags={(items: any[], getTagProps) => items.map(
            (option: any, index: number) => (
              <Chip
                key={`${option.id}`}
                label={displayId ? `${option.id} (${option.name ?? option.title})` : `${option.name ?? option.title ?? option}`}
                style={{
                  margin: '2px 3px',
                  padding: 2,
                  color: nuance100,
                  backgroundColor: nuance200,
                  borderRadius: '5px',
                  height: '2em',
                }}
                deleteIcon={(
                  <Close
                    className={classes.chipDeleteIcon}
                    onMouseDown={(event) => event.stopPropagation()}
                  />
                )}
                {...getTagProps({ index })}
              />
            ),
          )}
        />
      </Paper>
      {
        helperText
        && (
          <FormHelperText error={error} disabled={disabled}>
            <Typography
              variant="caption"
              color={error ? 'error' : 'primary'}
              className={classes.helperText}
            >
              {helperText}
            </Typography>
          </FormHelperText>
        )
      }
    </FormControl>
  );
};

const defaultProps = {
  helperText: '',
  error: false,
  displayId: false,
  disabled: false,
};

AutocompleteInput.defaultProps = defaultProps;

export default React.memo(AutocompleteInput);
