import React, {
  ReactNode,
  useState,
} from 'react';
import {
  IconButton,
  InputBase,
  Paper,
  Typography,
} from '@material-ui/core';
import useStyles from './WhiteTextInput.style';
import { feedbackError } from '../../constants/colors';
import EyeOffIcon from '../icon/EyeOffIcon';
import EyeOnIcon from '../icon/EyeOnIcon';

interface Props {
  value: string | number,
  onChange: React.ChangeEventHandler<HTMLElement>,
  type?: 'text' | 'number' | 'password',
  icon?: ReactNode,
  label?: string,
  name?: string,
  helperText?: string,
  error?: boolean
}

const WhiteTextInput: React.FC<Props> = (props: Props) => {
  const {
    type,
    label,
    value,
    onChange,
    icon,
    name,
    helperText,
    error,
  } = props;

  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [inputType, setInputType] = useState(type);

  const handleEyeClick = () => {
    if (type === 'password') {
      if (showPassword) {
        setShowPassword(false);
        setInputType('password');
      } else {
        setShowPassword(true);
        setInputType('text');
      }
    }
  };
  let inputClass = null;
  if (type === 'password') {
    inputClass = icon !== null ? classes.inputPasswordWithIcon : classes.inputPassword;
  } else {
    inputClass = icon !== null ? classes.inputBaseWithIcon : classes.inputBase;
  }

  return (
    <Paper elevation={0} className={classes.root}>
      {icon}
      <InputBase
        type={inputType}
        placeholder={label}
        inputProps={{
          'aria-label': label,
          className: classes.input,
          style: error ? { color: feedbackError } : {},
        }}
        className={inputClass}
        value={value}
        onChange={onChange}
        name={name}
        error={error}
      />
      {
        type === 'password'
        && showPassword
        && (
          <IconButton onClick={handleEyeClick} className={classes.rightIcon}>
            <EyeOffIcon />
          </IconButton>
        )
      }
      {
        type === 'password'
        && !showPassword
        && (
          <IconButton onClick={handleEyeClick} className={classes.rightIcon}>
            <EyeOnIcon />
          </IconButton>
        )
      }
      {
        helperText
        && (
          <Typography
            variant="caption"
            color={error ? 'error' : 'primary'}
            className={classes.helperText}
          >
            {helperText}
          </Typography>
        )
      }
    </Paper>
  );
};

const defaultProps: Partial<Props> = {
  type: 'text',
  label: '',
  icon: null,
  name: '',
  helperText: '',
  error: false,
};

WhiteTextInput.defaultProps = defaultProps;

export default WhiteTextInput;
