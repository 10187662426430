import React from 'react';
import { Typography } from '@material-ui/core';
import useStyles from './Title.style';

type Props = {}

const Title: React.FC<Props> = () => {
  const classes = useStyles();
  return (
    <Typography variant="h1" className={classes.title}>
      Théa
      <span className={classes.titleWhite}>connect</span>
    </Typography>
  );
};

export default Title;
