import { makeStyles } from '@material-ui/core/styles';
import {
  thea300,
  thea75,
  tonique2,
} from '../../../constants/colors';

const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: thea75,
    borderRadius: '5px 0 0 5px',
    boxShadow: 'none',
    margin: '1rem 0',
    padding: '2.2rem 1rem 1rem 2rem',
    borderLeft: `8px solid ${thea300}`,
    position: 'relative',
  },
  icon: {
    position: 'absolute',
    top: 0,
    right: '5px',
    cursor: 'pointer',
  },
  buttonDelete: {
    color: tonique2,
    fontWeight: 500,
    fontFamily: 'FuturaPTMedium',
  },
}));

export default useStyles;
