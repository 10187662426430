import {
  ExpertCourseAction,
  ExpertCourseState,
} from './types';
import {
  STORE_EXPERT_COURSE_FAILED,
  STORE_EXPERT_COURSE_LOADED,
  STORE_EXPERT_COURSE_PENDING,
} from './actionTypes';

const initialState: ExpertCourseState = {
  expert_courses: [],
  pending: false,
  error: null,
  status: 'INIT',
};

const reducer = (
  state: ExpertCourseState = initialState,
  action: ExpertCourseAction,
): ExpertCourseState => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case STORE_EXPERT_COURSE_LOADED:
      return {
        ...state,
        pending: false,
        expert_courses: action.expert_courses || [],
      };
    case STORE_EXPERT_COURSE_FAILED:
      if (action.error !== undefined) {
        return {
          ...state,
          error: action.error,
          pending: false,
          status: 'ERROR',
        };
      }
      return state;
    case STORE_EXPERT_COURSE_PENDING:
      return {
        ...state,
        pending: true,
      };
  }
  return state;
};

export default reducer;
