import React from 'react';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import { routeLogin } from '../../constants/routes';
import {
  storageAccessToken,
  storageRefreshToken,
  storageTokenTimeout,
} from '../../constants/auth';

const LogoutPage = () => {
  const history = useHistory();
  localStorage.removeItem(storageAccessToken);
  localStorage.removeItem(storageRefreshToken);
  localStorage.removeItem(storageTokenTimeout);
  history.push(routeLogin);
  return (<Typography variant="body1">logout</Typography>);
};

export default LogoutPage;
