export const STORE_NOTIFICATION_FAILED = 'store_notification_failed';
export const STORE_NOTIFICATION_PENDING = 'store_notification_pending';
export const STORE_NOTIFICATION_LOADED = 'store_notification_loaded';
export const STORE_NOTIFICATION_SENDING = 'store_notification_sending';
export const STORE_NOTIFICATION_ADDED_SENT = 'store_notification_added_sent';
export const STORE_NOTIFICATION_UPDATED_SENT = 'store_notification_updated_sent';
export const STORE_NOTIFICATION_DELETED = 'store_notification_deleted';
export const STORE_NOTIFICATION_RESET_STATUS = 'store_notification_reset_status';
export const STORE_NOTIFICATION_USER_PENDING = 'store_notification_user_pending';
export const STORE_NOTIFICATION_USER_LOADED = 'store_notification_user_loaded';
export const STORE_NOTIFICATION_USER_RESET = 'store_notification_user_reset';
export const STORE_NOTIFICATION_USER_RESET_STATUS = 'store_notification_user_reset_status';
export const STORE_NOTIFICATION_USER_FAILED = 'store_notification_user_failed';
