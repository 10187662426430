import React, { ReactNode } from 'react';
import { MuiThemeProvider, createMuiTheme, ThemeOptions } from '@material-ui/core/styles';
import useStyles from './AnonymousLayout.style';
import IconBluePaper from '../paper/IconBluePaper';
import { nuance100, thea400 } from '../../constants/colors';
import { jsonTheme } from '../../theme';

type Props = {
  children: ReactNode
}
const anonymousTheme: ThemeOptions = {
  ...jsonTheme,
  typography: {
    ...jsonTheme.typography,
    body1: {
      fontFamily: '"FuturaPTBook", "Open Sans", "Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: '16px',
      lineHeight: '20px',
      letterSpacing: 0,
      color: nuance100,
    },
  },
  overrides: {
    ...jsonTheme.overrides,
    MuiButton: {
      ...jsonTheme.overrides?.MuiButton,
      containedPrimary: {
        backgroundColor: thea400,
      },
    },
    MuiLink: {
      root: {
        ...jsonTheme.overrides?.MuiLink?.root,
        color: nuance100,
      },
    },
  },
};

const theme = createMuiTheme(anonymousTheme);
const AnonymousLayout: React.FC<Props> = (props: Props) => {
  const { children } = props;
  const classes = useStyles();
  return (
    <MuiThemeProvider theme={theme}>
      <div className={classes.root}>
        <div className={classes.background} />
        <IconBluePaper>
          {children}
        </IconBluePaper>
      </div>
    </MuiThemeProvider>
  );
};

export default AnonymousLayout;
