import { makeStyles } from '@material-ui/core/styles';
import { nuance100 } from '../../constants/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1,
    backgroundColor: theme.palette.primary.main,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '246px',
    width: '465px',
    minHeight: '400px',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      marginTop: '70px',
      minHeight: 'none',
      height: 'calc(100vh - 70px)',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
  logoContainer: {
    width: '100%',
    height: '88px',
  },
  logo: {
    margin: 'auto',
    position: 'relative',
    height: '113px',
    width: '113px',
    top: '-40px',
  },
  title: {
    width: '100%',
    textAlign: 'center',
  },
  titleWhite: {
    color: nuance100,
  },
  content: {
    paddingLeft: '66px',
    paddingRight: '66px',
    paddingBottom: '66px',
    paddingTop: '16px',
    textAlign: 'center',
  },
  description: {
    maxWidth: '300px',
    height: 'auto',
    margin: 'auto !important',
    marginBottom: '20px !important',
    whiteSpace: 'pre-line',
  },
  link: {
    textAlign: 'right',
    paddingBottom: '6px',
    paddingTop: '6px',
  },
  button: {
    marginTop: '16px',
  },
}));

export default useStyles;
