import React, {
  FormEvent,
  useState,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import {
  AxiosError,
} from 'axios';
import useStyles from '../../../components/paper/MainPaper.style';
import WhitePaper from '../../../components/paper/WhitePaper';
import GreyTextInput from '../../../components/form/GreyTextInput';
import { apiPutMePassword } from '../../../api/apiMe';
import { routeAccount } from '../../../constants/routes';
import { checkPassword } from '../../../services/PasswordService';
import { NotificationContext } from '../../../contexts/NotificationContext';

type Props = {}

const ChangePasswordPage: React.FC<Props> = () => {
  const { t } = useTranslation(['backoffice']);
  const classes = useStyles();
  const history = useHistory();
  const { setNotification } = useContext(NotificationContext);
  const [oldPassword, setOldPassword] = useState('');
  const [oldPasswordError, setOldPasswordError] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [repeatNewPassword, setRepeatNewPassword] = useState('');
  const [repeatNewPasswordError, setRepeatNewPasswordError] = useState('');
  const [sending, setSending] = useState(false);

  const handleFormAction = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    let formError = false;
    if (oldPassword.trim() === '') {
      formError = true;
      setOldPasswordError(t('account.oldPasswordRequired'));
    }
    if (!checkPassword(newPassword)) {
      formError = true;
      setNewPasswordError(t('account.passwordDontRespectRules'));
    }
    if (newPassword.trim() === '') {
      formError = true;
      setNewPasswordError(t('account.oldPasswordRequired'));
    }
    if (repeatNewPassword.trim() === '') {
      formError = true;
      setRepeatNewPasswordError(t('account.oldPasswordRequired'));
    }
    if (newPassword !== repeatNewPassword) {
      formError = true;
      setRepeatNewPasswordError(t('account.newPasswordNotEquals'));
    }
    if (!formError) {
      setSending(true);
      apiPutMePassword(oldPassword, newPassword).then(() => {
        history.push(routeAccount);
        setNotification({ isOpened: true, severity: 'success', message: t('account.changePasswordSuccess') });
      }).catch((error: AxiosError) => {
        setSending(false);
        setNotification({ isOpened: true, severity: 'error', message: error.message || '' });
      });
    }
  };

  const handleChangeOldPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setOldPassword(event.target.value);
    setOldPasswordError('');
  };

  const handleChangeNewPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setNewPassword(event.target.value);
    setNewPasswordError('');
  };

  const handleChangeRepeatNewPassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setRepeatNewPassword(event.target.value);
    setRepeatNewPasswordError('');
  };

  return (
    <>
      <Typography variant="h1" className={classes.title}>
        {t('title.account')}
      </Typography>
      <form onSubmit={handleFormAction}>
        <Grid container spacing={3}>
          <Grid item id="form-grid-item" xs={12} md={9}>
            <WhitePaper title={t('account.changePassword')}>
              <Typography variant="body1" className={classes.text}>
                {t('account.passwordHelp')}
              </Typography>
              <GreyTextInput
                type="password"
                value={oldPassword}
                label={t('account.oldPassword')}
                onChange={handleChangeOldPassword}
                error={oldPasswordError !== ''}
                helperText={oldPasswordError}
              />
              <GreyTextInput
                type="password"
                value={newPassword}
                label={t('account.newPassword')}
                onChange={handleChangeNewPassword}
                error={newPasswordError !== ''}
                helperText={newPasswordError}
              />
              <GreyTextInput
                type="password"
                value={repeatNewPassword}
                label={t('account.repeatNewPassword')}
                onChange={handleChangeRepeatNewPassword}
                error={repeatNewPasswordError !== ''}
                helperText={repeatNewPasswordError}
              />
            </WhitePaper>
          </Grid>
          <Grid item id="validation-grid-item" xs={12} md={3}>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              className={classes.fullWidth}
              disabled={sending}
            >
              <Typography variant="button">
                {t('common.save')}
              </Typography>
              {
                sending
                && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                    color="primary"
                  />
                )
              }
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default ChangePasswordPage;
