import React, { Dispatch, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Typography, Button, Grid, Fab, MenuItem, Menu,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { DefaultRootState, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import useStyles from '../../../components/paper/MainPaper.style';
import ProgressContainer from '../../../components/common/ProgressContainer';
import { NotificationContext } from '../../../contexts/NotificationContext';
import { routeBooksAdd } from '../../../constants/routes';
import { exportCsv, getBooks, resetStatus } from '../../../store/book/actions';
import { BookState } from '../../../store/book/types';
import PlusIcon from '../../../components/icon/PlusIcon';
import BookItem from './BookItem';
import {
  activeBooksSelector,
  inactiveBooksSelector,
} from '../../../store/book/selectors';

type Props = {};

const BooksPage: React.FC<Props> = () => {
  const { t } = useTranslation(['backoffice']);
  const classes = useStyles();
  const history = useHistory();
  const dispatch: Dispatch<any> = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const {
    books,
    pending,
    error,
    status,
  } = useSelector<DefaultRootState, BookState>(
    (state: any) => state.bookStore,
  );
  const { setNotification } = useContext(NotificationContext);
  const activeBooksFiltered = useSelector(activeBooksSelector);
  const inactiveBooksFiltered = useSelector(inactiveBooksSelector);

  useEffect(() => {
    if (books.length === 0) dispatch(getBooks());
  }, []);

  useEffect(() => {
    if (status === 'ERROR') {
      setNotification({ isOpened: true, severity: 'error', message: error || '' });
    }
    if (status !== 'INIT') dispatch(resetStatus());
  }, [status]);

  const handleExportMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleExportMenuClose = () => {
    setAnchorEl(null);
  };

  const downloadCsv = (type: 'TYPE_USERS' | 'TYPE_SIMPLE' | 'TYPE_DETAILED') => {
    dispatch(exportCsv(type));
    handleExportMenuClose();
  };

  return (
    <>
      <Typography variant="h1" className={classes.title}>
        {t('title.books')}
        <Button
          variant="contained"
          color="primary"
          startIcon={<PlusIcon width={22} height={22} />}
          className={classes.buttonAdd}
          onClick={() => history.push(routeBooksAdd)}
        >
          <Typography variant="button">
            {t('book.buttonAdd')}
          </Typography>
        </Button>
      </Typography>

      <ProgressContainer loading={pending}>
        {activeBooksFiltered.length !== 0 && (
          <>
            <Typography variant="h4" className={classes.title}>{t('book.activated')}</Typography>
            <Grid container spacing={4}>
              {activeBooksFiltered.map(((book) => (
                <BookItem book={book} key={book.id} />
              )))}
            </Grid>
          </>
        )}

        {inactiveBooksFiltered.length !== 0 && (
          <>
            <Typography variant="h4" className={classes.title}>{t('book.deactivated')}</Typography>
            <Grid container spacing={4}>
              {inactiveBooksFiltered.map(((book) => (
                <BookItem book={book} key={book.id} />
              )))}
            </Grid>
          </>
        )}

      </ProgressContainer>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleExportMenuClose}
      >
        <MenuItem onClick={() => downloadCsv('TYPE_USERS')}>
          {t('book.export.type.USERS')}
        </MenuItem>
        <MenuItem onClick={() => downloadCsv('TYPE_SIMPLE')}>
          {t('book.export.type.SIMPLE')}
        </MenuItem>
        <MenuItem onClick={() => downloadCsv('TYPE_DETAILED')}>
          {t('book.export.type.DETAILED')}
        </MenuItem>
      </Menu>
      <Fab
        color="default"
        variant="extended"
        className={classes.fab}
        aria-label={t('book.export.title')}
        onClick={handleExportMenuClick}
      >
        <GetAppIcon style={{ marginRight: '0.4em' }} />
        <Typography variant="button">
          {t('book.export.title')}
        </Typography>
      </Fab>
    </>
  );
};

export default BooksPage;
