export const STORE_EVENT_ADDED_SENT = 'store_event_added_sent';
export const STORE_EVENT_DELETED = 'store_event_deleted';
export const STORE_EVENT_FAILED = 'store_event_failed';
export const STORE_EVENT_PENDING = 'store_event_pending';
export const STORE_EVENT_LOADED = 'store_event_loaded';
export const STORE_EVENT_RESET_STATUS = 'store_event_reset_status';
export const STORE_EVENT_SENDING = 'store_event_sending';
export const STORE_EVENT_PUSHED = 'store_event_pushed';
export const STORE_EVENT_PUSH_FAILED = 'store_event_push_failed';
export const STORE_EVENT_PUSH_SENDING = 'store_event_push_sending';
export const STORE_EVENT_PUSH_STATUS_RESET = 'store_event_push_status_reset';
export const STORE_EVENT_UPDATED_SENT = 'store_event_updated_sent';
export const STORE_EVENT_UPDATED_FILTERS = 'store_event_updated_filters';
export const STORE_EVENT_ADDED_CATEGORIES = 'store_event_added_categories';
export const STORE_EVENT_ADDED_PICTURE = 'store_event_added_picture';
export const STORE_EVENT_DELETED_PICTURE = 'store_event_deleted_picture';
export const STORE_EVENT_LOADED_SUBSCRIPTIONS = 'store_event_loaded_subscriptions';
