import axios from 'axios';
import { apiToolboxes } from '../constants/api';
import { getToken } from '../services/AuthService';
import { IToolbox } from '../store/toolbox/types';

export const apiGetToolboxes = async () => {
  const token: string = await getToken();
  const data = await axios.get(
    `${process.env.REACT_APP_API_ROOT}${apiToolboxes}`,
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return data;
};

export const apiPostToolbox = async (content: Partial<IToolbox>, file: File) => {
  const token: string = await getToken();

  const formData = new FormData();
  formData.append('content', JSON.stringify(content));
  formData.append('file', file);

  const data = await axios.post(
    `${process.env.REACT_APP_API_ROOT}${apiToolboxes}`,
    formData,
    { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/x-www-form-urlencoded' } },
  );
  return data;
};

export const apiEditToolbox = async (
  toolboxId: string,
  content: Partial<IToolbox>,
  file: File | string,
) => {
  const token: string = await getToken();

  const formData = new FormData();
  formData.append('content', JSON.stringify(content));
  formData.append('file', file);

  const data = await axios.post(
    `${process.env.REACT_APP_API_ROOT}${apiToolboxes}/${toolboxId}`,
    formData,
    { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/x-www-form-urlencoded' } },
  );
  return data;
};

export const apiDeleteToolbox = async (toolboxId: string) => {
  const token: string = await getToken();
  const data = await axios.delete(
    `${process.env.REACT_APP_API_ROOT}${apiToolboxes}/${toolboxId}`,
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return data;
};
