import { makeStyles } from '@material-ui/core/styles';
import {
  nuance100,
  thea150,
} from '../../constants/colors';

const useStyles = makeStyles(() => ({
  paper: {
    height: 'auto',
    backgroundColor: thea150,
    width: '100%',
    borderRadius: '10px',
    padding: '4px',
    minWidth: 100,
    position: 'relative',
    top: '20px',
    marginBottom: '20px',
  },
  formControl: {
    width: '100%',
    height: 'auto',
    marginBottom: '20px',
  },
  formControlError: {
    width: '100%',
    height: '94px',
  },
  label: {
    marginLeft: '3px',
  },
  helperText: {
    width: '100%',
    position: 'relative',
    marginLeft: '3px',
    // top: '-5px',
  },
  select: {
    padding: 0,
    left: '10px',
    top: '1px',
    width: 'calc(100% - 15px)',
    minHeight: '1.5em',
    height: 'auto',

    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },

    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },

    '& .MuiInput-underline:hover:before': {
      borderBottom: 'none !important',
    },
  },
  chipDeleteIcon: {
    color: nuance100,
    height: '16px',
    width: '16px',
  },
}));

export default useStyles;
