import React from 'react';
import { Editor, EditorState } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Typography, InputLabel, FormHelperText } from '@material-ui/core';
import { feedbackError } from '../../constants/colors';
import useStyles from './EditorComponent.style';

type Props = {
  value: string | any,
  label: string
  /* eslint-disable no-unused-vars */
  onChange: (value: EditorState) => void,
  helperText?: string,
  error?: boolean,
  isWhite?: boolean
}

const EditorComponent = ({
  value, label, onChange, error, helperText, isWhite,
}: Props) => {
  const classes = useStyles();

  return (
    <div style={{ marginBottom: '20px', width: '100%' }}>
      <InputLabel error={error} disableAnimation shrink>
        <Typography variant="body1" style={error ? { color: feedbackError } : {}}>
          {label}
        </Typography>
      </InputLabel>
      <Editor
        editorState={value}
        wrapperClassName={`${classes.editor} ${isWhite ? classes.whiteEditor : ''}`}
        editorClassName={`${classes.editor} ${isWhite ? classes.whiteEditor : ''}`}
        toolbarClassName={classes.toolbar}
        onEditorStateChange={onChange}
        toolbar={{
          options: [
            'inline',
            'blockType',
            'fontSize',
            'list',
            'textAlign',
            'colorPicker',
            'link',
          ],
          inline: { inDropdown: true },
          list: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
          emoji: { className: classes.hide },
          embedded: { className: classes.hide },
          image: { className: classes.hide },
          remove: { className: classes.hide },
        }}
      />
      {
        helperText
        && (
          <FormHelperText error={error}>
            <Typography
              variant="caption"
              color={error ? 'error' : 'primary'}
              className={classes.helperText}
            >
              {helperText}
            </Typography>
          </FormHelperText>
        )
      }
    </div>
  );
};

const defaultProps = {
  helperText: '',
  error: false,
  isWhite: false,
};

EditorComponent.defaultProps = defaultProps;

export default React.memo(EditorComponent);
