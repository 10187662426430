import {
  AxiosError,
  AxiosResponse,
} from 'axios';
import {
  DispatchType,
  EventAction,
  eventCategories,
  IEvent,
  IEventPush,
  Picture,
  Subscription,
} from './types';
import {
  STORE_EVENT_ADDED_CATEGORIES,
  STORE_EVENT_ADDED_PICTURE,
  STORE_EVENT_ADDED_SENT,
  STORE_EVENT_DELETED,
  STORE_EVENT_DELETED_PICTURE,
  STORE_EVENT_FAILED,
  STORE_EVENT_LOADED,
  STORE_EVENT_LOADED_SUBSCRIPTIONS,
  STORE_EVENT_PENDING,
  STORE_EVENT_PUSH_FAILED,
  STORE_EVENT_PUSH_STATUS_RESET,
  STORE_EVENT_PUSHED,
  STORE_EVENT_PUSH_SENDING,
  STORE_EVENT_RESET_STATUS,
  STORE_EVENT_SENDING,
  STORE_EVENT_UPDATED_FILTERS,
  STORE_EVENT_UPDATED_SENT,
} from './actionTypes';
import generateCsv from '../../utils/generateCsv';
import {
  apiDeleteEvent,
  apiDeletePictureEvent,
  apiEditEvent,
  apiGetEvents,
  apiGetEventsCategories,
  apiGetEventsSubscriptions,
  apiPostEvent,
  apiPostPictureEvent,
  apiPostPushEvent,
  apiPostPushStartEvent,
} from '../../api/apiEvent';

const eventDeletedAction = (eventId: string): EventAction => ({
  type: STORE_EVENT_DELETED,
  eventId,
});

const eventFailedAction = (error: string): EventAction => ({
  type: STORE_EVENT_FAILED,
  error,
});

const eventPendingAction = (): EventAction => ({
  type: STORE_EVENT_PENDING,
});

const eventLoadedAction = (
  events: IEvent[],
  categories: eventCategories[],
): EventAction => ({
  type: STORE_EVENT_LOADED,
  events,
  categories,
});

const eventSendingAction = (): EventAction => ({
  type: STORE_EVENT_SENDING,
});

const eventPushSendingAction = (): EventAction => ({
  type: STORE_EVENT_PUSH_SENDING,
});

const eventAddedSentAction = (event: IEvent): EventAction => ({
  type: STORE_EVENT_ADDED_SENT,
  event,
});

const eventUpdatedAction = (event: IEvent): EventAction => ({
  type: STORE_EVENT_UPDATED_SENT,
  event,
});

const eventResetStatus = (): EventAction => ({
  type: STORE_EVENT_RESET_STATUS,
});

const eventUpdatedFilters = (categoryIds: string[]): EventAction => ({
  type: STORE_EVENT_UPDATED_FILTERS,
  categoryIds,
});

const eventAddedCategoriesAction = (categories: eventCategories[]) => ({
  type: STORE_EVENT_ADDED_CATEGORIES,
  categories,
});

const eventAddedPictureAction = (eventId: string, picture: Picture) => ({
  type: STORE_EVENT_ADDED_PICTURE,
  eventId,
  picture,
});

const eventDeletedPictureAction = (eventId: string, pictureId: string) => ({
  type: STORE_EVENT_DELETED_PICTURE,
  eventId,
  pictureId,
});

const eventPushAction = (eventId: string, data?: IEvent) => ({
  type: STORE_EVENT_PUSHED,
  eventId,
  event: data,
});

const eventPushFailedAction = (pushError: string) => ({
  type: STORE_EVENT_PUSH_FAILED,
  pushError,
});

const eventPushStatusResetAction = () => ({
  type: STORE_EVENT_PUSH_STATUS_RESET,
});

const eventLoadedSubscriptionsAction = (subscriptions: Subscription[]) => ({
  type: STORE_EVENT_LOADED_SUBSCRIPTIONS,
  subscriptions,
});

export const updateFiltersEvent = (categoryIds: string[]) => (dispatch: DispatchType) => {
  dispatch(eventUpdatedFilters(categoryIds));
};

export const resetStatus = () => (dispatch: DispatchType) => {
  dispatch(eventResetStatus());
};

export const deleteEvent = (eventId: string) => (dispatch: DispatchType) => {
  apiDeleteEvent(eventId)
    .then(() => dispatch(eventDeletedAction(eventId)))
    .catch((error: AxiosError) => dispatch(eventFailedAction(error.message)));
};

export const getEventsAndCategories = () => (dispatch: DispatchType) => {
  dispatch(eventPendingAction());

  Promise.all([
    apiGetEvents(), apiGetEventsCategories(),
  ]).then(([events, categories]: [AxiosResponse<IEvent[]>, AxiosResponse<eventCategories[]>]) => {
    dispatch(eventLoadedAction(events.data, categories.data));
  }).catch((error: AxiosError) => dispatch(eventFailedAction(error.message)));
};

export const getEventSubscriptions = (eventId: string) => (dispatch: DispatchType) => {
  apiGetEventsSubscriptions(eventId)
    .then((data: AxiosResponse) => dispatch(eventLoadedSubscriptionsAction(data.data)))
    .catch((error: AxiosError) => dispatch(eventFailedAction(error.message)));
};

export const getEventCategories = () => (dispatch: DispatchType) => {
  apiGetEventsCategories()
    .then((data: AxiosResponse) => dispatch(eventAddedCategoriesAction(data.data)))
    .catch((error: AxiosError) => dispatch(eventFailedAction(error.message)));
};

export const postEvent = (content: Partial<IEvent>) => (dispatch: DispatchType) => {
  dispatch(eventSendingAction());
  apiPostEvent(content)
    .then((data: AxiosResponse) => dispatch(eventAddedSentAction(data.data)))
    .catch((error: AxiosError) => dispatch(eventFailedAction(error.message)));
};

export const putEvent = (eventId: string, content: Partial<IEvent>) => (dispatch: DispatchType) => {
  dispatch(eventSendingAction());
  apiEditEvent(eventId, content)
    .then((data: AxiosResponse) => dispatch(eventUpdatedAction(data.data)))
    .catch((error: AxiosError) => dispatch(eventFailedAction(error.message)));
};

export const postPictureEvent = (eventId: string, picture: File) => (dispatch: DispatchType) => {
  apiPostPictureEvent(eventId, picture)
    .then((data: AxiosResponse) => dispatch(eventAddedPictureAction(eventId, data.data)))
    .catch((error: AxiosError) => dispatch(eventFailedAction(error.message)));
};

export const deletePictureEvent = (
  eventId: string,
  pictureId: string,
) => (dispatch: DispatchType) => {
  apiDeletePictureEvent(eventId, pictureId)
    .then(() => dispatch(eventDeletedPictureAction(eventId, pictureId)))
    .catch((error: AxiosError) => dispatch(eventFailedAction(error.message)));
};

export const exportCsvSubscription = (eventId: string, format: 'csv') => (dispatch: DispatchType) => {
  apiGetEventsSubscriptions(eventId, format)
    .then((data: AxiosResponse) => generateCsv(data.data, 'liste_inscription', 'subscriptions'))
    .catch((error: AxiosError) => dispatch(eventFailedAction(error.message)));
};

export const postEventPush = (
  eventId: string,
  content: Partial<IEventPush>,
) => (dispatch: DispatchType) => {
  dispatch(eventPushSendingAction());
  apiPostPushEvent(eventId, content)
    .then(() => dispatch(eventPushAction(eventId)))
    .catch((error: AxiosError) => dispatch(eventPushFailedAction(error.message)));
};

export const postEventStartPush = (
  eventId: string,
) => (dispatch: DispatchType) => {
  dispatch(eventPushSendingAction());
  apiPostPushStartEvent(eventId)
    .then((data: AxiosResponse) => dispatch(eventPushAction(eventId, data.data)))
    .catch((error: AxiosError) => dispatch(eventPushFailedAction(error.message)));
};

export const resetPushStatus = () => (dispatch: DispatchType) => {
  dispatch(eventPushStatusResetAction());
};
