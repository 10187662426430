import React from 'react';
import { thea75 } from '../../constants/colors';

interface Props {
  color?: string,
  style?: any,
  width?: number,
  height?: number,
}

const SaveIcon: React.FC<Props> = ({
  color, style, width = 16, height = 16,
}: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={style} width={width} height={height}>
    <g data-name="Layer 2">
      <g data-name="save">
        <rect width="24" height="24" opacity="0" />
        <rect fill={color} x="10" y="17" width="4" height="4" />
        <path fill={color} d="M20.12 8.71l-4.83-4.83A3 3 0 0 0 13.17 3H10v6h5a1 1 0 0 1 0 2H9a1 1 0 0 1-1-1V3H6a3 3 0 0 0-3 3v12a3 3 0 0 0 3 3h2v-4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v4h2a3 3 0 0 0 3-3v-7.17a3 3 0 0 0-.88-2.12z" />
      </g>
    </g>
  </svg>
);

const defaultProps: Props = {
  color: thea75,
  style: {},
  width: 16,
  height: 16,
};

SaveIcon.defaultProps = defaultProps;

export default SaveIcon;
