import {
  AxiosError,
  AxiosResponse,
} from 'axios';
import {
  DispatchType,
  INotification,
  NotificationAction,
} from './types';
import {
  STORE_NOTIFICATION_FAILED,
  STORE_NOTIFICATION_LOADED,
  STORE_NOTIFICATION_PENDING,
  STORE_NOTIFICATION_ADDED_SENT,
  STORE_NOTIFICATION_DELETED,
  STORE_NOTIFICATION_SENDING,
  STORE_NOTIFICATION_UPDATED_SENT,
  STORE_NOTIFICATION_RESET_STATUS,
  STORE_NOTIFICATION_USER_PENDING,
  STORE_NOTIFICATION_USER_LOADED,
  STORE_NOTIFICATION_USER_RESET,
  STORE_NOTIFICATION_USER_FAILED,
  STORE_NOTIFICATION_USER_RESET_STATUS,
} from './actionTypes';
import {
  apiDeleteNotification,
  apiGetNotifications,
  apiGetNotificationUsers,
  apiPostNotification,
  apiPostNotificationUsers,
  apiPutNotification,
} from '../../api/apiNotification';
import { IUser } from '../user/types';

const notificationFailedAction = (error: string): NotificationAction => ({
  type: STORE_NOTIFICATION_FAILED,
  error,
});

const notificationPendingAction = (): NotificationAction => ({
  type: STORE_NOTIFICATION_PENDING,
});

const notificationSendingAction = (): NotificationAction => ({
  type: STORE_NOTIFICATION_SENDING,
});

const notificationResetStatusAction = (): NotificationAction => ({
  type: STORE_NOTIFICATION_RESET_STATUS,
});

const notificationLoadedAction = (notifications: INotification[]): NotificationAction => ({
  type: STORE_NOTIFICATION_LOADED,
  notifications,
});

const notificationAddedSentAction = (notification: INotification): NotificationAction => ({
  type: STORE_NOTIFICATION_ADDED_SENT,
  notification,
});

const notificationUpdatedSentAction = (notification: INotification): NotificationAction => ({
  type: STORE_NOTIFICATION_UPDATED_SENT,
  notification,
});
const notificationDeletedAction = (notificationId: string): NotificationAction => ({
  type: STORE_NOTIFICATION_DELETED,
  notificationId,
});

const notificationUserPendingAction = (): NotificationAction => ({
  type: STORE_NOTIFICATION_USER_PENDING,
});

const notificationUserLoadedAction = (affectedUsers: IUser[]): NotificationAction => ({
  type: STORE_NOTIFICATION_USER_LOADED,
  affectedUsers,
});

const notificationUserResetAction = (): NotificationAction => ({
  type: STORE_NOTIFICATION_USER_RESET,
});

const notificationUserResetStatusAction = (): NotificationAction => ({
  type: STORE_NOTIFICATION_USER_RESET_STATUS,
});

const notificationUserFailedAction = (error: string): NotificationAction => ({
  type: STORE_NOTIFICATION_USER_FAILED,
  error,
});

// eslint-disable-next-line import/prefer-default-export
export const getNotifications = () => (dispatch: DispatchType) => {
  dispatch(notificationPendingAction());
  apiGetNotifications()
    .then((data: AxiosResponse) => dispatch(notificationLoadedAction(data.data)))
    .catch((error: AxiosError) => dispatch(notificationFailedAction(error.message)));
};

export const postNotification = (content: Partial<INotification>) => (dispatch: DispatchType) => {
  dispatch(notificationSendingAction());
  apiPostNotification(content)
    .then((data: AxiosResponse) => dispatch(notificationAddedSentAction(data.data)))
    .catch((error: AxiosError) => dispatch(notificationFailedAction(error.message)));
};

export const putNotification = (
  id: string,
  content: Partial<INotification>,
) => (dispatch: DispatchType) => {
  dispatch(notificationSendingAction());
  apiPutNotification(id, content)
    .then((data: AxiosResponse) => dispatch(notificationUpdatedSentAction(data.data)))
    .catch((error: AxiosError) => dispatch(notificationFailedAction(error.message)));
};

export const deleteNotification = (id: string) => (dispatch: DispatchType) => {
  apiDeleteNotification(id)
    .then(() => dispatch(notificationDeletedAction(id)))
    .catch((error: AxiosError) => dispatch(notificationFailedAction(error.message)));
};

export const resetStatus = () => (dispatch: DispatchType) => {
  dispatch(notificationResetStatusAction());
};

export const resetAffectedUsers = () => (dispatch: DispatchType) => {
  dispatch(notificationUserResetAction());
};

export const resetAffectedUsersStatus = () => (dispatch: DispatchType) => {
  dispatch(notificationUserResetStatusAction());
};

export const postNotificationUsers = (
  content: any,
) => (dispatch: DispatchType) => {
  dispatch(notificationUserPendingAction());
  apiPostNotificationUsers(content)
    .then((data: AxiosResponse) => dispatch(notificationUserLoadedAction(data.data)))
    .catch((error: AxiosError) => dispatch(notificationUserFailedAction(error.message)));
};

export const getNotificationUsers = (
  id: string,
) => (dispatch: DispatchType) => {
  dispatch(notificationUserPendingAction());
  apiGetNotificationUsers(id)
    .then((data: AxiosResponse) => dispatch(notificationUserLoadedAction(data.data)))
    .catch((error: AxiosError) => dispatch(notificationUserFailedAction(error.message)));
};
