import React from 'react';
import {
  thea300,
} from '../../constants/colors';

interface Props {
  color?: string,
  style?: any,
  width?: number,
  height?: number,
}

const FileIcon: React.FC<Props> = (props: Props) => {
  const {
    color, style, width, height,
  } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={width} height={height} style={style}>
      <g data-name="Layer 2">
        <g data-name="file">
          <rect width="24" height="24" opacity="0" />
          <path
            d="M17.4443,20 L6.5553,20 C6.2493,20 6.0003,19.776 6.0003,19.5 L6.0003,4.5 C6.0003,4.224 6.2493,4 6.5553,4 L11.0003,4 L11.0003,8.15 C11.0003,9.722 12.2173,11 13.7143,11 L18.0003,11 L18.0003,19.5 C18.0003,19.776 17.7503,20 17.4443,20 L17.4443,20 Z M17.6493,9 L13.7143,9 C13.3203,9 13.0003,8.619 13.0003,8.15 L13.0003,4 L13.1123,4 L17.6493,9 Z M19.7403,8.328 L14.2963,2.328 C14.1073,2.119 13.8383,2 13.5553,2 L6.5553,2 C5.1463,2 4.0003,3.122 4.0003,4.5 L4.0003,19.5 C4.0003,20.878 5.1463,22 6.5553,22 L17.4443,22 C18.8533,22 20.0003,20.878 20.0003,19.5 L20.0003,9 C20.0003,8.751 19.9073,8.512 19.7403,8.328 L19.7403,8.328 Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

const defaultProps: Props = {
  color: thea300,
  style: {},
  width: 24,
  height: 24,
};

FileIcon.defaultProps = defaultProps;

export default FileIcon;
