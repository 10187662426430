import {
  AxiosError,
  AxiosResponse,
} from 'axios';
import {
  DispatchType,
  IProduct,
  IProductCategory,
  ProductAction,
} from './types';
import {
  STORE_PRODUCT_ADDED_SENT,
  STORE_PRODUCT_CATEGORIES_LOADED,
  STORE_PRODUCT_DELETED,
  STORE_PRODUCT_FAILED,
  STORE_PRODUCT_LOADED,
  STORE_PRODUCT_PENDING,
  STORE_PRODUCT_RESET_STATUS,
  STORE_PRODUCT_SENDING,
  STORE_PRODUCT_UPDATED_SENT,
  STORE_PRODUCT_UPDATED_FILTERS,
} from './actionTypes';
import {
  apiDeleteProduct,
  apiEditProduct,
  apiGetProductCategories,
  apiGetProducts,
  apiPostProduct,
  apiExportProductsOrder,
} from '../../api/apiProduct';
import generateCsv from '../../utils/generateCsv';

const ProductCategoriesLoadedAction = (categories: IProductCategory[]): ProductAction => ({
  type: STORE_PRODUCT_CATEGORIES_LOADED,
  categories,
});

const productDeletedAction = (productId: string): ProductAction => ({
  type: STORE_PRODUCT_DELETED,
  productId,
});

const productFailedAction = (error: string): ProductAction => ({
  type: STORE_PRODUCT_FAILED,
  error,
});

const productPendingAction = (): ProductAction => ({
  type: STORE_PRODUCT_PENDING,
});

const productLoadedAction = (
  products: IProduct[],
  categories: IProductCategory[],
): ProductAction => ({
  type: STORE_PRODUCT_LOADED,
  products,
  categories,
});

const productSendingAction = (): ProductAction => ({
  type: STORE_PRODUCT_SENDING,
});

const productAddedSentAction = (product: IProduct): ProductAction => ({
  type: STORE_PRODUCT_ADDED_SENT,
  product,
});

const productUpdatedAction = (product: IProduct): ProductAction => ({
  type: STORE_PRODUCT_UPDATED_SENT,
  product,
});

const productResetStatus = (): ProductAction => ({
  type: STORE_PRODUCT_RESET_STATUS,
});

export const resetStatus = () => (dispatch: DispatchType) => {
  dispatch(productResetStatus());
};

const productUpdatedFilters = (categoryIds: string[]): ProductAction => ({
  type: STORE_PRODUCT_UPDATED_FILTERS,
  categoryIds,
});

export const updateFiltersProduct = (categoryIds: string[]) => (dispatch: DispatchType) => {
  dispatch(productUpdatedFilters(categoryIds));
};
export const deleteProduct = (productId: string) => (dispatch: DispatchType) => {
  apiDeleteProduct(productId)
    .then(() => dispatch(productDeletedAction(productId)))
    .catch((error: AxiosError) => dispatch(productFailedAction(error.message)));
};

export const getProductsAndProductCategories = () => (dispatch: DispatchType) => {
  dispatch(productPendingAction());
  Promise.all([apiGetProducts(), apiGetProductCategories()])
    .then((
      [products, productCategories]: [AxiosResponse<IProduct[]>, AxiosResponse<IProductCategory[]>],
    ) => {
      dispatch(productLoadedAction(products.data, productCategories.data));
    })
    .catch((error: AxiosError) => dispatch(productFailedAction(error.message)));
};

export const getProductCategories = () => (dispatch: DispatchType) => {
  apiGetProductCategories()
    .then((data: AxiosResponse) => dispatch(ProductCategoriesLoadedAction(data.data)))
    .catch((error: AxiosError) => dispatch(productFailedAction(error.message)));
};

export const postProduct = (
  content: Partial<IProduct>,
  picture?: File,
) => (dispatch: DispatchType) => {
  dispatch(productSendingAction());
  apiPostProduct(content, picture)
    .then((data: AxiosResponse) => dispatch(productAddedSentAction(data.data)))
    .catch((error: AxiosError) => dispatch(productFailedAction(error.message)));
};

export const putProduct = (
  productId: string,
  content: Partial<IProduct>,
  file?: File | string,
) => (dispatch: DispatchType) => {
  dispatch(productSendingAction());
  apiEditProduct(productId, content, file)
    .then((data: AxiosResponse) => dispatch(productUpdatedAction(data.data)))
    .catch((error: AxiosError) => dispatch(productFailedAction(error.message)));
};

export const exportOrdersCsv = (type: 'TYPE_USERS' | 'TYPE_SIMPLE' | 'TYPE_DETAILED') => (dispatch: DispatchType) => {
  const filenameType = (type.length > 0) ? `_${type.split('_')[1].toLowerCase()}` : '';
  const filename = `commandes_up${filenameType}`;

  apiExportProductsOrder(type)
    .then((data: AxiosResponse) => generateCsv(data.data, filename, 'professionalUnitOrders'))
    .catch((error: AxiosError) => dispatch(productFailedAction(error.message)));
};
