import { makeStyles } from '@material-ui/core/styles';
import { thea150 } from '../../constants/colors';

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
    height: 'fit-content',
    marginBottom: '20px',
  },
  paper: {
    height: 'fit-content',
    backgroundColor: thea150,
    width: '100%',
    borderRadius: '10px',
    padding: '8px',
    marginBottom: '20px',
    position: 'relative',
    minWidth: 100,
    top: '20px',
  },
  input: {
    padding: 0,
    width: '100%',
    height: '1.5em',
  },
  label: {
    marginLeft: '3px',
  },
  helperText: {
    width: '100%',
    position: 'relative',
    marginLeft: '3px',
    // top: '-5px',
  },
  textarea: {
    background: 'transparent',
    border: 'none',
    resize: 'none',
    width: '97%',
    fontSize: '16px',
    fontFamily: '"FuturaPTBook", sans-serif',
    marginLeft: '10px',
  },
}));

export default useStyles;
