import React, { ReactNode } from 'react';
import { Paper } from '@material-ui/core';
import useStyles from './IconBluePaper.style';
import Logo from '../icon/Logo';
import Title from '../common/Title';

type Props = {
  children: ReactNode
}

const IconBluePaper: React.FC<Props> = (props: Props) => {
  const { children } = props;
  const classes = useStyles();
  return (
    <Paper elevation={0} className={classes.root}>
      <div className={classes.logoContainer}>
        <Logo className={classes.logo} />
      </div>
      <Title />
      <div className={classes.content}>
        {children}
      </div>
    </Paper>
  );
};

export default IconBluePaper;
