import React, { createContext, ReactElement, useState } from 'react';

import Notification from '../components/common/Notification';

interface Props {
  children: any;
}

interface NotificationProps {
  isOpened: boolean;
  severity: 'error' | 'success';
  message: string;
}

interface NotificationContextProps {
  notification: NotificationProps;
  setNotification: React.Dispatch<React.SetStateAction<NotificationProps>>;
}

const NotificationContext = createContext<NotificationContextProps>({
  notification: { isOpened: false, severity: 'success', message: '' },
  setNotification: () => {},
});

const NotificationProvider = ({ children }: Props): ReactElement => {
  const [notification, setNotification] = useState<NotificationProps>({
    isOpened: false,
    severity: 'success',
    message: '',
  });

  const handleClose = (reason: string): void => {
    if (reason !== 'clickaway') {
      setNotification({ ...notification, isOpened: false });
    }
  };

  return (
    <NotificationContext.Provider value={{ notification, setNotification }}>
      {children}
      <Notification
        isOpen={notification.isOpened}
        severity={notification.severity}
        handleClose={handleClose}
      >
        {notification.message}
      </Notification>
    </NotificationContext.Provider>
  );
};

export { NotificationContext, NotificationProvider };
