import React, { Dispatch } from 'react';
import {
  Grid,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ProductCategory from './ProductCategory';
import WhitePaper from '../../../components/paper/WhitePaper';
import { IProductCategory } from '../../../store/product/types';
import { updateFiltersProduct } from '../../../store/product/actions';

type Props = {
  categories: IProductCategory[],
  categoryIds: string[]
}

const ProductCategories = ({ categories, categoryIds }: Props) => {
  const { t } = useTranslation(['backoffice']);

  const dispatch: Dispatch<any> = useDispatch();

  const handleChange = (categoryId: string) => {
    const categoryIdExist = categoryIds.find((c) => c === categoryId);

    let newList: string[];
    if (categoryIdExist) {
      newList = categoryIds.filter((c) => c !== categoryId);
    } else {
      newList = [...categoryIds, categoryId];
    }

    dispatch(updateFiltersProduct(newList));
  };

  return (
    <Grid item xs={12}>
      <WhitePaper title={t('product.category_title')}>
        {categories.map((category) => (
          <ProductCategory
            key={category.id}
            category={category}
            handleChange={handleChange}
            categoryIds={categoryIds}
          />
        ))}
      </WhitePaper>
    </Grid>
  );
};

export default ProductCategories;
