import React from 'react';
import {
  Breadcrumbs, Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import useStyles from './Breadcrumb.style';

type Props = {
  baseUrl: string,
  link: string | undefined,
  title: string,
}

const Breadcrumb = ({
  baseUrl, link, title,
}: Props) => {
  const classes = useStyles();

  return (
    <Breadcrumbs separator="›" aria-label="breadcrumb">
      <Link color="inherit" to={baseUrl} className={classes.link}>
        {title}
      </Link>
      {link
        && (<Typography variant="subtitle2" className={classes.linkActive}>{link}</Typography>)}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
