import {
  IFlashInfo,
  FlashInfoAction,
  FlashInfoState,
} from './types';
import {
  STORE_FLASH_INFO_DELETED,
  STORE_FLASH_INFO_FAILED,
  STORE_FLASH_INFO_PENDING,
  STORE_FLASH_INFO_LOADED,
  STORE_FLASH_INFO_SENDING,
  STORE_FLASH_INFO_UPDATED_SENT,
  STORE_FLASH_INFO_ADDED_SENT,
  STORE_FLASH_INFO_RESET_STATUS,
} from './actionTypes';

const initialState: FlashInfoState = {
  error: null,
  pending: false,
  sending: false,
  flashInfos: [],
  status: 'INIT',
};

const reducer = (state: FlashInfoState = initialState, action: FlashInfoAction): FlashInfoState => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case STORE_FLASH_INFO_ADDED_SENT:
      if (action.flashInfo !== undefined) {
        return {
          ...state,
          sending: false,
          status: 'SUCCESS',
          flashInfos: [...state.flashInfos, action.flashInfo],
        };
      }
      return state;
    case STORE_FLASH_INFO_DELETED:
      if (action.flashInfoId !== undefined) {
        return {
          ...state,
          flashInfos: state.flashInfos.filter((flashInfo) => flashInfo.id !== action.flashInfoId),
        };
      }
      return state;
    case STORE_FLASH_INFO_PENDING:
      return {
        ...state,
        pending: true,
      };
    case STORE_FLASH_INFO_FAILED:
      if (action.error !== undefined) {
        return {
          ...state,
          pending: false,
          error: action.error,
          sending: false,
          status: 'ERROR',
        };
      }
      return state;
    case STORE_FLASH_INFO_LOADED:
      return {
        ...state,
        pending: false,
        flashInfos: action.flashInfos || [],
      };
    case STORE_FLASH_INFO_RESET_STATUS:
      return {
        ...state,
        status: 'INIT',
      };
    case STORE_FLASH_INFO_SENDING:
      return {
        ...state,
        sending: true,
      };
    case STORE_FLASH_INFO_UPDATED_SENT:
      if (action.flashInfo !== undefined) {
        return {
          ...state,
          sending: false,
          status: 'SUCCESS',
          flashInfos: state.flashInfos
            .map(
              (p) => (p.id === action.flashInfo!.id ? action.flashInfo : p),
            ) as IFlashInfo[],
        };
      }
  }
  return state;
};

export default reducer;
