import React, { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Fab, Menu, MenuItem, Typography,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useDispatch } from 'react-redux';
import useStyles from '../../components/paper/MainPaper.style';
import { exportOrdersCsv } from '../../store/product/actions';

type Props = {}

const ProfessionalUnitsPage: React.FC<Props> = () => {
  const { t } = useTranslation(['backoffice']);
  const classes = useStyles();
  const dispatch: Dispatch<any> = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleExportMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleExportMenuClose = () => {
    setAnchorEl(null);
  };

  const downloadCsv = (type: 'TYPE_USERS' | 'TYPE_SIMPLE' | 'TYPE_DETAILED') => {
    dispatch(exportOrdersCsv(type));
    handleExportMenuClose();
  };

  return (
    <>
      <Typography variant="h1" className={classes.title}>
        {t('title.professionalUnits')}
      </Typography>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleExportMenuClose}
      >
        <MenuItem onClick={() => downloadCsv('TYPE_USERS')}>
          {t('product.export.type.USERS')}
        </MenuItem>
        <MenuItem onClick={() => downloadCsv('TYPE_SIMPLE')}>
          {t('product.export.type.SIMPLE')}
        </MenuItem>
        <MenuItem onClick={() => downloadCsv('TYPE_DETAILED')}>
          {t('product.export.type.DETAILED')}
        </MenuItem>
      </Menu>
      <Fab
        color="default"
        variant="extended"
        className={classes.fab}
        aria-label={t('product.export.title')}
        onClick={handleExportMenuClick}
      >
        <GetAppIcon style={{ marginRight: '0.4em' }} />
        <Typography variant="button">
          {t('product.export.title')}
        </Typography>
      </Fab>
    </>
  );
};

export default ProfessionalUnitsPage;
