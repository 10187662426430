import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { tonique2 } from '../../../constants/colors';

type Props = {
  open: boolean,
  handleClose: () => void
  handleValidate: () => void,
  email: string
}

const UserModalDelete = ({
  open, handleClose, handleValidate, email,
}: Props) => {
  const { t } = useTranslation(['backoffice']);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('users.deleteModal.text', { email })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          <Typography variant="button">
            {t('users.deleteModal.disagree')}
          </Typography>
        </Button>
        <Button onClick={handleValidate} style={{ color: tonique2 }}>
          <Typography variant="button">
            {t('users.deleteModal.agree')}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserModalDelete;
