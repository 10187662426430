import React, { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Typography, Grid, Paper, Popper, IconButton, MenuList, MenuItem, ClickAwayListener, Fade,
} from '@material-ui/core';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import useStyles from './ProductItem.style';
import { IProduct } from '../../../store/product/types';
import { deleteProduct } from '../../../store/product/actions';
import { routeProducts } from '../../../constants/routes';
import MoreVerticalOutline from '../../../components/icon/MoreVerticalOutline';
import image from '../../../images/background_placeholder.jpg';

type Props = {
  product: IProduct
};

const ProductItem = ({ product } : Props) => {
  const { t } = useTranslation(['backoffice', 'common']);
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const dispatch: Dispatch<any> = useDispatch();

  const open = Boolean(anchorEl);
  const id = open ? 'popper-product' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickEdit = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    history.push(`${routeProducts}/edit/${product.id}`);
  };

  const handleClickDelete = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    dispatch(deleteProduct(product.id));
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  return (
    <Grid item>
      <Paper className={classes.product}>
        <img src={product.file_path || image} alt="" width={100} height={150} className={classes.picture} />
        {product.boxing && (<span className={classes.boxing}>{product.boxing}</span>)}
        <Typography variant="subtitle1" className={classes.productTitle} color="primary">{product.title}</Typography>
        <IconButton
          className={classes.icon}
          onClick={handleClick}
        >
          <MoreVerticalOutline />
        </IconButton>

        <Popper id={id} open={open} anchorEl={anchorEl} style={{ zIndex: 2 }} transition>
          {({ TransitionProps }) => (
            <ClickAwayListener onClickAway={handleClickAway}>
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <Fade {...TransitionProps} timeout={350}>
                <Paper elevation={1}>
                  <MenuList>
                    <MenuItem onClick={handleClickEdit} className={classes.buttonEdit}>
                      {t('common.edit')}
                    </MenuItem>
                    <MenuItem onClick={handleClickDelete} className={classes.buttonDelete}>
                      {t('common.delete')}
                    </MenuItem>
                  </MenuList>
                </Paper>
              </Fade>
            </ClickAwayListener>
          )}
        </Popper>
      </Paper>
    </Grid>
  );
};

export default ProductItem;
