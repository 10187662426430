import React from 'react';
import {
  Route,
  Switch,
} from 'react-router';
import './i18n';
import AnonymousLayout from './components/layout/AnonymousLayout';
import BackOfficeLayout from './components/layout/BackOfficeLayout';
import {
  routeAccount,
  routeBooks,
  routeBooksAdd,
  routeBooksEdit,
  routeChangePassword,
  routeEvents,
  routeEventsAdd,
  routeEventsEdit,
  routeEventsPush,
  routeFlashInfos,
  routeFlashInfosAdd,
  routeFlashInfosEdit,
  routeForgotPassword,
  routeLogin,
  routeLogout,
  routeNotification,
  routeNotificationAdd,
  routeNotificationEdit,
  routeProducts,
  routeProductsAdd,
  routeProductsEdit,
  routeProfessionalUnits,
  routeResetPassword,
  routeToolbox,
  routeToolboxAdd,
  routeToolboxEdit,
  routeUsers,
  routeUsersAdminAdd,
  routeUsersAdminEdit,
  routeUsersMarketingEmailAuthorized,
} from './constants/routes';
import DefaultPage from './containers/DefaultPage';
import LoginPage from './containers/anonymous/LoginPage';
import LogoutPage from './containers/anonymous/LogoutPage';
import ForgotPasswordPage from './containers/anonymous/ForgotPasswordPage';
import ResetPasswordPage from './containers/anonymous/ResetPasswordPage';
import EventsPage from './containers/backoffice/event/EventsPage';
import FlashInfoPage from './containers/backoffice/flash-info/FlashInfoPage';
import FormFlashInfoPage from './containers/backoffice/flash-info/FormFlashInfoPage';
import BooksPage from './containers/backoffice/book/BooksPage';
import FormBookPage from './containers/backoffice/book/FormBookPage';
import ProductsPage from './containers/backoffice/product/ProductsPage';
import FormProductPage from './containers/backoffice/product/FormProductPage';
import ProfessionalUnitsPage from './containers/backoffice/ProfessionalUnitsPage';
import AccountPage from './containers/backoffice/account/AccountPage';
import NotFoundPage from './containers/NotFoundPage';
import ChangePasswordPage from './containers/backoffice/account/ChangePasswordPage';
import FormToolboxPage from './containers/backoffice/toolbox/FormToolboxPage';
import ToolboxPage from './containers/backoffice/toolbox/ToolboxPage';
import FormEventPage from './containers/backoffice/event/FormEventPage';
import UserPage from './containers/backoffice/user/UserPage';
import UsersEmailMarketingAuthorizedList from './containers/backoffice/user/marketing/UsersEmailMarketingAuthorizedList';
import UsersFormAdmin from './containers/backoffice/user/admin/UsersFormAdmin';
import FormPushEventPage from './containers/backoffice/event/FormPushEventPage';
import NotificationsPage from './containers/backoffice/notification/NotificationsPage';
import FormNotificationPage from './containers/backoffice/notification/FormNotificationPage';

function App() {
  return (
    <Switch>
      <Route
        path={[
          routeUsers,
          routeFlashInfos,
          routeEvents,
          routeBooks,
          routeProducts,
          routeToolbox,
          routeProfessionalUnits,
          routeAccount,
          routeChangePassword,
          routeNotification,
        ]}
      >
        <BackOfficeLayout>
          <Route exact path={routeUsers} component={UserPage} />
          <Route exact path={routeUsersAdminAdd} component={UsersFormAdmin} />
          <Route exact path={routeUsersAdminEdit} component={UsersFormAdmin} />
          <Route
            exact
            path={routeUsersMarketingEmailAuthorized}
            component={UsersEmailMarketingAuthorizedList}
          />
          <Route exact path={routeFlashInfos} component={FlashInfoPage} />
          <Route exact path={routeFlashInfosAdd} component={FormFlashInfoPage} />
          <Route exact path={routeFlashInfosEdit} component={FormFlashInfoPage} />
          <Route exact path={routeEvents} component={EventsPage} />
          <Route exact path={routeEventsAdd} component={FormEventPage} />
          <Route exact path={routeEventsEdit} component={FormEventPage} />
          <Route exact path={routeEventsPush} component={FormPushEventPage} />
          <Route exact path={routeNotification} component={NotificationsPage} />
          <Route exact path={routeNotificationAdd} component={FormNotificationPage} />
          <Route exact path={routeNotificationEdit} component={FormNotificationPage} />
          <Route exact path={routeBooks} component={BooksPage} />
          <Route exact path={routeBooksAdd} component={FormBookPage} />
          <Route exact path={routeBooksEdit} component={FormBookPage} />
          <Route exact path={routeProducts} component={ProductsPage} />
          <Route exact path={routeProductsAdd} component={FormProductPage} />
          <Route exact path={routeProductsEdit} component={FormProductPage} />
          <Route exact path={routeToolbox} component={ToolboxPage} />
          <Route exact path={routeToolboxAdd} component={FormToolboxPage} />
          <Route exact path={routeToolboxEdit} component={FormToolboxPage} />
          <Route exact path={routeProfessionalUnits} component={ProfessionalUnitsPage} />
          <Route exact path={routeAccount} component={AccountPage} />
          <Route exact path={routeChangePassword} component={ChangePasswordPage} />
        </BackOfficeLayout>
      </Route>
      <Route path={[routeForgotPassword, routeResetPassword, routeLogin]}>
        <AnonymousLayout>
          <Route exact path={routeLogin} component={LoginPage} />
          <Route exact path={routeForgotPassword} component={ForgotPasswordPage} />
          <Route exact path={routeResetPassword} component={ResetPasswordPage} />
        </AnonymousLayout>
      </Route>
      <Route exact path={routeLogout} component={LogoutPage} />
      <Route exact path="/" component={DefaultPage} />
      <Route component={NotFoundPage} />
    </Switch>
  );
}

export default App;
