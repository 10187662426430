import React from 'react';
import { thea300 } from '../../constants/colors';

interface Props {
  color?: string,
  style?: any
}

const EyeOnFillIcon: React.FC<Props> = (props: Props) => {
  const { color, style } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      style={style}
    >
      <g id="eye-fill" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g>
          <path
            d="M12,10.5 C11.173,10.5 10.5,11.173 10.5,12 C10.5,12.827 11.173,13.5 12,13.5 C12.827,13.5 13.5,12.827 13.5,12 C13.5,11.173 12.827,10.5 12,10.5 M12,15.5 C10.07,15.5 8.5,13.93 8.5,12 C8.5,10.07 10.07,8.5 12,8.5 C13.93,8.5 15.5,10.07 15.5,12 C15.5,13.93 13.93,15.5 12,15.5 M21.868,11.502 C21.229,10.39 17.706,4.816 11.729,5.003 C6.202,5.144 2.986,10.014 2.132,11.502 C1.956,11.811 1.956,12.189 2.132,12.498 C2.762,13.595 6.162,19 12.025,19 C12.106,19 12.188,18.999 12.271,18.997 C17.798,18.856 21.014,13.986 21.868,12.498 C22.044,12.189 22.044,11.811 21.868,11.502"
            id="path-1"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

const defaultProps: Props = {
  color: thea300,
  style: {},
};

EyeOnFillIcon.defaultProps = defaultProps;

export default EyeOnFillIcon;
