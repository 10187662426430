import { makeStyles } from '@material-ui/core/styles';
import { thea150, thea75, nuance100 } from '../../constants/colors';

const useStyles = makeStyles(() => ({
  toolbar: {
    backgroundColor: thea150,
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
  },
  whiteEditor: {
    backgroundColor: `${nuance100} !important`,
  },
  editor: {
    backgroundColor: thea75,
    borderRadius: '10px',

    '& > .DraftEditor-root': {
      paddingLeft: '1rem',
    },
  },
  hide: {
    display: 'none',
  },
  label: {
    marginLeft: '3px',
  },
  helperText: {
    width: '100%',
    position: 'relative',
    marginLeft: '3px',
    // top: '-5px',
  },
}));

export default useStyles;
