import { makeStyles } from '@material-ui/core/styles';
import { nuance100 } from '../../constants/colors';

const useStyles = makeStyles(() => ({
  root: {
    zIndex: 2,
    backgroundColor: nuance100,
    borderRadius: '30px',
    margin: 0,
    height: 'auto',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  content: {
    zIndex: 3,
    position: 'relative',
    width: '100%',
    padding: '20px',
    height: 'auto',
    overflow: 'visible',
  },
  title: {
    marginBottom: '20px',
  },
  disabledContainer: {
    borderRadius: '30px',
    zIndex: 4,
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  disabledContainerProgress: {
    margin: 'auto',
    position: 'absolute',
    top: 'calc(50% - 12px)',
    left: 'calc(50% - 12px)',
  },
}));

export default useStyles;
