import React, { Dispatch, useEffect } from 'react';
import { DefaultRootState, useDispatch, useSelector } from 'react-redux';
import UsersAdminTable from './UsersAdminTable';
import { usersByRoleSelector } from '../../../../store/user/selectors';

import { getMe } from '../../../../store/me/actions';
import { MeState } from '../../../../store/me/types';
import ProgressContainer from '../../../../components/common/ProgressContainer';

const UsersAdminContent = () => {
  const usersByRole = useSelector(usersByRoleSelector('ROLE_ADMIN'));
  const dispatch: Dispatch<any> = useDispatch();

  const {
    me,
    pending,
  } = useSelector<DefaultRootState, MeState>(
    (state: any) => state.meStore,
  );

  useEffect(() => {
    if (!me) dispatch(getMe());
  }, []);

  if (!me) {
    return null;
  }

  return (
    <div style={{ padding: '1rem 0' }}>
      <ProgressContainer loading={pending}>
        <UsersAdminTable users={usersByRole} userIdConnected={me.id} />
      </ProgressContainer>
    </div>
  );
};

export default React.memo(UsersAdminContent);
