import React, { Dispatch, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Button } from '@material-ui/core';
import { useHistory } from 'react-router';
import { DefaultRootState, useDispatch, useSelector } from 'react-redux';
import useStyles from '../../../components/paper/MainPaper.style';
import { NotificationContext } from '../../../contexts/NotificationContext';
import { getFlashInfos, resetStatus } from '../../../store/flash-info/actions';
import ProgressContainer from '../../../components/common/ProgressContainer';
import PlusIcon from '../../../components/icon/PlusIcon';
import { FlashInfoState } from '../../../store/flash-info/types';
import FlashInfoTable from './FlashInfoTable';
import { routeFlashInfosAdd } from '../../../constants/routes';
import { comingSoonSelector, historicSelector, inProgressSelector } from '../../../store/flash-info/selectors';

type Props = {}

const FlashInfoPage: React.FC<Props> = () => {
  const { t } = useTranslation(['backoffice']);
  const classes = useStyles();
  const history = useHistory();
  const dispatch: Dispatch<any> = useDispatch();
  const {
    flashInfos,
    pending,
    error,
    status,
  } = useSelector<DefaultRootState, FlashInfoState>(
    (state: any) => state.flashInfoStore,
  );
  const comingSoon = useSelector(comingSoonSelector);
  const inProgress = useSelector(inProgressSelector);
  const historic = useSelector(historicSelector);

  const { setNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (flashInfos.length === 0) dispatch(getFlashInfos());
  }, []);

  useEffect(() => {
    if (status === 'ERROR') {
      setNotification({ isOpened: true, severity: 'error', message: error || '' });
    }
    if (status !== 'INIT') dispatch(resetStatus());
  }, [status]);

  return (
    <>
      <Typography variant="h1" className={classes.title}>
        {t('title.flashInfo')}
        <Button
          variant="contained"
          color="primary"
          startIcon={<PlusIcon width={22} height={22} />}
          className={classes.buttonAdd}
          onClick={() => history.push(routeFlashInfosAdd)}
        >
          <Typography variant="button">
            {t('flashInfo.buttonAdd')}
          </Typography>
        </Button>
      </Typography>
      <ProgressContainer loading={pending}>
        {inProgress.length !== 0 && (
        <>
          <Typography variant="h4" className={classes.title}>{t('flashInfo.subtitle.inProgress')}</Typography>
          <FlashInfoTable flashInfos={inProgress} />
        </>
        )}
        {comingSoon.length !== 0 && (
          <>
            <Typography variant="h4" className={classes.title}>{t('flashInfo.subtitle.comingSoon')}</Typography>
            <FlashInfoTable flashInfos={comingSoon} />
          </>
        )}
        {historic.length !== 0 && (
          <>
            <Typography variant="h4" className={classes.title}>{t('flashInfo.subtitle.historic')}</Typography>
            <FlashInfoTable flashInfos={historic} />
          </>
        )}
      </ProgressContainer>
    </>
  );
};

export default FlashInfoPage;
