import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  listPicture: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  picture: {
    position: 'relative',
    '&:hover .icon': {
      display: 'block',
    },
  },
  image: {
    borderRadius: '21px',
    width: '160px',
    height: '115px',
    objectFit: 'cover',
    margin: '0 16px 16px 0',
  },
  icon: {
    position: 'absolute',
    top: '10px',
    right: '30px',
    cursor: 'pointer',
    display: 'none',
  },
}));

export default useStyles;
