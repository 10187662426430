import { createSelector } from 'reselect';
import { isBefore, isAfter } from 'date-fns';
import { State } from '../store';

const flashInfoSelector = (state: State) => state.flashInfoStore.flashInfos;

export const historicSelector = createSelector(
  flashInfoSelector,
  (flashInfos) => flashInfos.filter(
    (flash) => flash.end_date
    && isBefore(new Date(flash.end_date), new Date()),
  ),
);

export const inProgressSelector = createSelector(
  flashInfoSelector,
  (flashInfos) => flashInfos.filter(
    (flash) => isBefore(new Date(flash.start_date), new Date())
   && (flash.end_date ? isAfter(new Date(flash.end_date), new Date()) : true),
  ),
);

export const comingSoonSelector = createSelector(
  flashInfoSelector,
  (flashInfos) => flashInfos.filter(
    (flash) => isAfter(new Date(flash.start_date), new Date()),
  ),
);
