import {
  ToolboxAction,
  ToolboxState,
  IToolbox,
} from './types';
import {
  STORE_TOOLBOX_DELETED,
  STORE_TOOLBOX_FAILED,
  STORE_TOOLBOX_PENDING,
  STORE_TOOLBOX_LOADED,
  STORE_TOOLBOX_SENDING,
  STORE_TOOLBOX_RESET_STATUS,
  STORE_TOOLBOX_UPDATED_SENT,
  STORE_TOOLBOX_ADDED_SENT,
} from './actionTypes';

const initialState: ToolboxState = {
  error: null,
  pending: false,
  sending: false,
  toolboxes: [],
  status: 'INIT',
};

const reducer = (state: ToolboxState = initialState, action: ToolboxAction): ToolboxState => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case STORE_TOOLBOX_ADDED_SENT:
      if (action.toolbox !== undefined) {
        return {
          ...state,
          sending: false,
          toolboxes: [...state.toolboxes, action.toolbox],
          status: 'SUCCESS',
        };
      }
      return state;
    case STORE_TOOLBOX_DELETED:
      if (action.toolboxId !== undefined) {
        return {
          ...state,
          toolboxes: state.toolboxes.filter((toolbox) => toolbox.id !== action.toolboxId),
        };
      }
      return state;
    case STORE_TOOLBOX_PENDING:
      return {
        ...state,
        pending: true,
      };
    case STORE_TOOLBOX_FAILED:
      if (action.error !== undefined) {
        return {
          ...state,
          pending: false,
          error: action.error,
          sending: false,
          status: 'ERROR',
        };
      }
      return state;
    case STORE_TOOLBOX_LOADED:
      return {
        ...state,
        pending: false,
        toolboxes: action.toolboxes || [],
      };
    case STORE_TOOLBOX_RESET_STATUS:
      return {
        ...state,
        status: 'INIT',
      };
    case STORE_TOOLBOX_SENDING:
      return {
        ...state,
        sending: true,
      };
    case STORE_TOOLBOX_UPDATED_SENT:
      if (action.toolbox !== undefined) {
        return {
          ...state,
          sending: false,
          status: 'SUCCESS',
          toolboxes: state.toolboxes
            .map(
              (t) => (t.id === action.toolbox!.id ? action.toolbox : t),
            ) as IToolbox[],
        };
      }
      return state;
  }
  return state;
};

export default reducer;
