import {
  IEvent,
  EventAction,
  EventState,
  Picture,
} from './types';
import {
  STORE_EVENT_DELETED,
  STORE_EVENT_FAILED,
  STORE_EVENT_PENDING,
  STORE_EVENT_LOADED,
  STORE_EVENT_SENDING,
  STORE_EVENT_UPDATED_SENT,
  STORE_EVENT_ADDED_SENT,
  STORE_EVENT_UPDATED_FILTERS,
  STORE_EVENT_RESET_STATUS,
  STORE_EVENT_ADDED_CATEGORIES,
  STORE_EVENT_ADDED_PICTURE,
  STORE_EVENT_DELETED_PICTURE,
  STORE_EVENT_LOADED_SUBSCRIPTIONS,
  STORE_EVENT_PUSHED,
  STORE_EVENT_PUSH_FAILED,
  STORE_EVENT_PUSH_STATUS_RESET,
  STORE_EVENT_PUSH_SENDING,
} from './actionTypes';

const initialState: EventState = {
  error: null,
  pushError: null,
  pushStatus: 'INIT',
  pushSending: false,
  pending: false,
  sending: false,
  events: [],
  status: 'INIT',
  categories: [],
  categoryIds: [],
  subscriptions: [],
};

const reducer = (state: EventState = initialState, action: EventAction): EventState => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case STORE_EVENT_ADDED_SENT:
      if (action.event !== undefined) {
        return {
          ...state,
          sending: false,
          status: 'SUCCESS',
          events: [...state.events, action.event],
        };
      }
      return state;
    case STORE_EVENT_DELETED:
      if (action.eventId !== undefined) {
        return {
          ...state,
          events: state.events.filter((event) => event.id !== action.eventId),
        };
      }
      return state;
    case STORE_EVENT_PENDING:
      return {
        ...state,
        pending: true,
      };
    case STORE_EVENT_UPDATED_FILTERS:
      return {
        ...state,
        categoryIds: action.categoryIds || state.categoryIds,
      };
    case STORE_EVENT_FAILED:
      if (action.error !== undefined) {
        return {
          ...state,
          pending: false,
          error: action.error,
          sending: false,
          status: 'ERROR',
        };
      }
      return state;

    case STORE_EVENT_LOADED:
      if (action.events && action.categories) {
        return {
          ...state,
          pending: false,
          events: action.events || [],
          categories: action.categories || [],
          categoryIds: action.categories?.map((c) => c.id) || [],
        };
      }
      return state;

    case STORE_EVENT_LOADED_SUBSCRIPTIONS:
      if (action.subscriptions) {
        return {
          ...state,
          subscriptions: action.subscriptions || [],
        };
      }
      return state;

    case STORE_EVENT_RESET_STATUS:
      return {
        ...state,
        status: 'INIT',
      };

    case STORE_EVENT_SENDING:
      return {
        ...state,
        sending: true,
      };

    case STORE_EVENT_UPDATED_SENT:
      if (action.event !== undefined) {
        return {
          ...state,
          sending: false,
          status: 'SUCCESS',
          events: state.events
            .map(
              (e) => (e.id === action.event!.id ? action.event : e),
            ) as IEvent[],
        };
      }
      return state;

    case STORE_EVENT_ADDED_CATEGORIES:
      if (action.categories !== undefined) {
        return {
          ...state,
          categories: action.categories,
        };
      }
      return state;

    case STORE_EVENT_ADDED_PICTURE:
      if (action.eventId !== undefined && action.picture !== undefined) {
        return {
          ...state,
          events: state.events.map(
            (e) => (e.id === action.eventId
              ? { ...e, pictures: [...e.pictures, (action.picture as Picture)] }
              : e
            ),
          ),
        };
      }
      return state;

    case STORE_EVENT_DELETED_PICTURE:
      if (action.eventId !== undefined && action.pictureId !== undefined) {
        return {
          ...state,
          events: state.events.map(
            (e) => (e.id === action.eventId
              ? {
                ...e,
                pictures: e.pictures
                  .filter((picture) => picture.id !== action.pictureId),
              }
              : e
            ),
          ),
        };
      }
      return state;
    case STORE_EVENT_PUSH_SENDING: {
      return {
        ...state,
        pushSending: true,
      };
    }
    case STORE_EVENT_PUSHED: {
      const newState:EventState = {
        ...state,
        pushSending: false,
        pushStatus: 'SUCCESS',
      };
      if (action.event) {
        const newEvents: IEvent[] = state.events.filter((event) => event.id !== action.event?.id);
        newEvents.push(action.event);
        newState.events = newEvents;
      }
      return <EventState>newState;
    }
    case STORE_EVENT_PUSH_FAILED:
      if (action.pushError !== undefined) {
        return {
          ...state,
          pushSending: false,
          pushError: action.pushError,
          pushStatus: 'ERROR',
        };
      }
      return state;

    case STORE_EVENT_PUSH_STATUS_RESET:
      return {
        ...state,
        pushStatus: 'INIT',
      };
  }
  return state;
};

export default reducer;
