import {
  TerritoryAction,
  TerritoryState,
} from './types';
import {
  STORE_TERRITORY_FAILED,
  STORE_TERRITORY_LOADED,
  STORE_TERRITORY_PENDING,
} from './actionTypes';

const initialState: TerritoryState = {
  territories: [],
  pending: false,
  error: null,
  status: 'INIT',
};

const reducer = (state: TerritoryState = initialState, action: TerritoryAction): TerritoryState => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case STORE_TERRITORY_LOADED:
      return {
        ...state,
        pending: false,
        territories: action.territories || [],
      };
    case STORE_TERRITORY_FAILED:
      if (action.error !== undefined) {
        return {
          ...state,
          error: action.error,
          pending: false,
          status: 'ERROR',
        };
      }
      return state;
    case STORE_TERRITORY_PENDING:
      return {
        ...state,
        pending: true,
      };
  }
  return state;
};

export default reducer;
