import {
  applyMiddleware,
  combineReducers,
  createStore,
} from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import meStore from './me/reducers';
import productStore from './product/reducers';
import toolboxStore from './toolbox/reducers';
import bookStore from './book/reducers';
import flashInfoStore from './flash-info/reducers';
import eventStore from './event/reducers';
import userStore from './user/reducers';
import regionStore from './region/reducers';
import territoryStore from './territory/reducers';
import notificationStore from './notification/reducers';
import expertCoursesStore from './expert-course/reducers';
import { FlashInfoState } from './flash-info/types';
import { EventState } from './event/types';
import { ProductState } from './product/types';
import { UserState } from './user/types';
import { NotificationState } from './notification/types';
import { BookState } from './book/types';

export type State = {
  flashInfoStore: FlashInfoState
  eventStore: EventState
  productStore: ProductState
  userStore: UserState
  notificationStore: NotificationState,
  bookStore: BookState
}

const store = createStore(
  combineReducers({
    meStore,
    productStore,
    toolboxStore,
    bookStore,
    flashInfoStore,
    eventStore,
    userStore,
    regionStore,
    territoryStore,
    notificationStore,
    expertCoursesStore,
  }),
  composeWithDevTools(applyMiddleware(thunk)),
);

export default store;
