import React, {
  MouseEvent,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Typography,
} from '@material-ui/core';
import useStyles from '../../components/paper/IconBluePaper.style';
import { apiPostForgotPassword } from '../../api/apiAnonymous';
import WhiteTextInput from '../../components/form/WhiteTextInput';
import { feedbackSuccess } from '../../constants/colors';
import BookmarkIcon from '../../components/icon/BookmarkIcon';
import { leftIconStyle } from '../../components/form/WhiteTextInput.style';

type Props = {}

const LoginPage: React.FC<Props> = () => {
  const { t } = useTranslation(['anonymous']);
  const classes = useStyles();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [username, setUsername] = useState('');
  const [usernameError, setUsernameError] = useState('');

  const handleSendForgotPassword = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (username.trim() === '') {
      setUsernameError(t('forgotPassword.usernameRequired'));
    } else {
      apiPostForgotPassword(username).then(() => {
        setSuccess(t('forgotPassword.success'));
      })
        .catch((apiError: any) => {
          setError(t('forgotPassword.error'));
          // eslint-disable-next-line no-console
          console.log(apiError);
        });
    }
  };

  const handleChangeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setUsername(event.target.value);
    setUsernameError('');
    setError('');
    setSuccess('');
  };

  return (
    <>
      <Typography variant="body1" className={classes.description}>
        {t('forgotPassword.text')}
      </Typography>
      {success !== ''
      && (
        <Typography
          variant="body1"
          style={{ color: feedbackSuccess }}
          className={classes.description}
        >
          {success}
        </Typography>
      )}
      {success === '' && (
        <>
          <form>
            {error !== ''
            && (
              <Typography variant="body1" color="error" className={classes.description}>
                {error}
              </Typography>
            )}
            <WhiteTextInput
              type="text"
              value={username}
              onChange={handleChangeUsername}
              name="username"
              helperText={usernameError}
              error={usernameError !== ''}
              label={t('forgotPassword.login')}
              icon={(<BookmarkIcon style={leftIconStyle} />)}
            />
          </form>
          <Button
            onClick={handleSendForgotPassword}
            type="button"
            variant="contained"
            color="primary"
            disableElevation
            className={classes.button}
          >
            <Typography variant="button">
              {t('forgotPassword.button')}
            </Typography>
          </Button>
        </>
      )}
    </>
  );
};

export default LoginPage;
