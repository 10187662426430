import { makeStyles } from '@material-ui/core/styles';
import { nuance100 } from '../../constants/colors';

export const leftIconStyle = {
  position: 'absolute',
  left: 18,
  top: 18,
  margin: 0,
  padding: 0,
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: '60px',
    backgroundColor: nuance100,
    minWidth: '305px',
    borderRadius: '10px',
    padding: '20px',
    marginBottom: '20px',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      minWidth: 0,
      maxWidth: '400px',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%',
    },
  },
  inputBase: {
    position: 'absolute',
    padding: 0,
    left: '20px',
    width: 'calc(100% - 40px)',
  },
  inputBaseWithIcon: {
    position: 'absolute',
    left: '54px',
    padding: 0,
    width: 'calc(100% - 74px)',
  },
  inputPassword: {
    position: 'absolute',
    left: '20px',
    padding: 0,
    width: 'calc(100% - 74px)',
  },
  inputPasswordWithIcon: {
    position: 'absolute',
    left: '54px',
    padding: 0,
    width: 'calc(100% - 110px)',
  },
  input: {
    padding: 0,
    width: '100%',
  },
  helperText: {
    width: '100%',
    position: 'relative',
    top: '40px',
  },
  rightIcon: {
    position: 'absolute',
    right: 18,
    top: 18,
    margin: 0,
    padding: 0,
  },
}));

export default useStyles;
