import {
  IProduct,
  ProductAction,
  ProductState,
} from './types';
import {
  STORE_PRODUCT_DELETED,
  STORE_PRODUCT_FAILED,
  STORE_PRODUCT_PENDING,
  STORE_PRODUCT_LOADED,
  STORE_PRODUCT_SENDING,
  STORE_PRODUCT_UPDATED_SENT,
  STORE_PRODUCT_CATEGORIES_LOADED,
  STORE_PRODUCT_ADDED_SENT,
  STORE_PRODUCT_RESET_STATUS,
  STORE_PRODUCT_UPDATED_FILTERS,
} from './actionTypes';

const initialState: ProductState = {
  error: null,
  pending: false,
  sending: false,
  products: [],
  categories: [],
  categoryIds: [],
  status: 'INIT',
};

const reducer = (state: ProductState = initialState, action: ProductAction): ProductState => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case STORE_PRODUCT_ADDED_SENT:
      if (action.product !== undefined) {
        return {
          ...state,
          sending: false,
          status: 'SUCCESS',
          products: [...state.products, action.product],
        };
      }
      return state;
    case STORE_PRODUCT_CATEGORIES_LOADED:
      return {
        ...state,
        categories: action.categories || [],
      };
    case STORE_PRODUCT_DELETED:
      if (action.productId !== undefined) {
        return {
          ...state,
          products: state.products.filter((product) => product.id !== action.productId),
        };
      }
      return state;
    case STORE_PRODUCT_PENDING:
      return {
        ...state,
        pending: true,
      };
    case STORE_PRODUCT_FAILED:
      if (action.error !== undefined) {
        return {
          ...state,
          pending: false,
          error: action.error,
          sending: false,
          status: 'ERROR',
        };
      }
      return state;
    case STORE_PRODUCT_LOADED:
      if (action.products && action.categories) {
        return {
          ...state,
          pending: false,
          products: action.products || [],
          categories: action.categories || [],
          categoryIds: action.categories?.map((c) => c.id) || [],
        };
      }
      return state;
    case STORE_PRODUCT_RESET_STATUS:
      return {
        ...state,
        status: 'INIT',
      };
    case STORE_PRODUCT_UPDATED_FILTERS:
      return {
        ...state,
        categoryIds: action.categoryIds || state.categoryIds,
      };
    case STORE_PRODUCT_SENDING:
      return {
        ...state,
        sending: true,
      };
    case STORE_PRODUCT_UPDATED_SENT:
      if (action.product !== undefined) {
        return {
          ...state,
          sending: false,
          status: 'SUCCESS',
          products: state.products
            .map(
              (p) => (p.id === action.product!.id ? action.product : p),
            ) as IProduct[],
        };
      }
  }
  return state;
};

export default reducer;
