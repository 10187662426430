import {
  IBook,
  BookAction,
  BookState,
} from './types';
import {
  STORE_BOOK_DISABLED,
  STORE_BOOK_FAILED,
  STORE_BOOK_PENDING,
  STORE_BOOK_LOADED,
  STORE_BOOK_SENDING,
  STORE_BOOK_UPDATED_SENT,
  STORE_BOOK_ADDED_SENT,
  STORE_BOOK_RESET_STATUS, STORE_BOOK_ENABLED,
} from './actionTypes';

const initialState: BookState = {
  error: null,
  pending: false,
  sending: false,
  books: [],
  status: 'INIT',
};

const reducer = (state: BookState = initialState, action: BookAction): BookState => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case STORE_BOOK_ADDED_SENT:
      if (action.book !== undefined) {
        return {
          ...state,
          sending: false,
          status: 'SUCCESS',
          books: [...state.books, action.book],
        };
      }
      return state;
    case STORE_BOOK_DISABLED:
    case STORE_BOOK_ENABLED:
      if (action.book !== undefined) {
        return {
          ...state,
          books: state.books
            .map(
              (p) => (p.id === action.book!.id ? action.book : p),
            ) as IBook[],
        };
      }
      return state;
    case STORE_BOOK_PENDING:
      return {
        ...state,
        pending: true,
      };
    case STORE_BOOK_FAILED:
      if (action.error !== undefined) {
        return {
          ...state,
          pending: false,
          error: action.error,
          sending: false,
          status: 'ERROR',
        };
      }
      return state;
    case STORE_BOOK_LOADED:
      return {
        ...state,
        pending: false,
        books: action.books || [],
      };
    case STORE_BOOK_RESET_STATUS:
      return {
        ...state,
        status: 'INIT',
      };
    case STORE_BOOK_SENDING:
      return {
        ...state,
        sending: true,
      };
    case STORE_BOOK_UPDATED_SENT:
      if (action.book !== undefined) {
        return {
          ...state,
          sending: false,
          status: 'SUCCESS',
          books: state.books
            .map(
              (p) => (p.id === action.book!.id ? action.book : p),
            ) as IBook[],
        };
      }
  }
  return state;
};

export default reducer;
