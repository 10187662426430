import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  DefaultRootState,
  useDispatch,
  useSelector,
} from 'react-redux';
import { useParams } from 'react-router';
import useStyles from '../../../components/paper/MainPaper.style';
import { routeEvents } from '../../../constants/routes';
import Breadcrumb from '../../../components/common/Breadcrumb';
import {
  EventState,
  IEvent,
  IEventPush,
} from '../../../store/event/types';
import {
  postEventPush,
  resetPushStatus,
} from '../../../store/event/actions';
import GreyTextInput from '../../../components/form/GreyTextInput';
import WhitePaper from '../../../components/paper/WhitePaper';
import { NotificationContext } from '../../../contexts/NotificationContext';
import NavigationFillIcon from '../../../components/icon/NavigationFillIcon';
import { nuance100 } from '../../../constants/colors';

type Props = {};

const FormPushEventPage: React.FC<Props> = () => {
  const { t } = useTranslation(['backoffice', 'common']);
  const classes = useStyles();
  const { setNotification } = useContext(NotificationContext);
  const dispatch = useDispatch();
  const slug: { id: string } = useParams();

  const {
    pushError,
    pushStatus,
    events,
    pushSending,
  } = useSelector<DefaultRootState, EventState>(
    (state: any) => state.eventStore,
  );

  const [values, setValues] = useState<IEventPush>({
    title: '',
    body: '',
  });

  const [formErrors, setFormErrors] = useState({
    title: '',
    body: '',
  });

  useEffect(() => {
    if (pushStatus === 'ERROR') {
      setNotification({ isOpened: true, severity: 'error', message: pushError || '' });
    }
    if (pushStatus === 'SUCCESS') {
      setNotification({ isOpened: true, severity: 'success', message: t('event.pushSuccess') });
      setValues({ title: '', body: '' });
    }
    if (pushStatus !== 'INIT') dispatch(resetPushStatus());
  }, [pushStatus]);

  const eventId = slug.id;
  let event: IEvent | undefined;

  if (eventId) {
    event = events.find((e: IEvent) => e.id === eventId);
  }

  const handleChange = (prop: keyof Partial<IEventPush>, value: any) => {
    setValues({ ...values, [prop]: value });
    setFormErrors({ ...formErrors, [prop]: '' });
  };

  const handleFormAction = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    let formError = false;
    if (values.title === '') {
      formErrors.title = t('event.form.error.titleRequired');
      formError = true;
    }
    setFormErrors({ ...formErrors });
    if (!formError && event) {
      dispatch(postEventPush(event.id, values));
    }
  };

  return (
    <>
      <Breadcrumb
        title={t('title.events')}
        baseUrl={routeEvents}
        link={event && event.title}
      />
      <Typography variant="h1" className={classes.title}>
        {`${t('event.push')} ${event && event.title}`}
      </Typography>
      <Typography variant="subtitle2" className={classes.text}>
        {t('event.pushExplain')}
      </Typography>
      <form onSubmit={handleFormAction}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={9}>
            <Grid container id="validation-grid-item" spacing={4}>
              <Grid item xs={12}>
                <WhitePaper title={t('event.form.subtitle.push')}>
                  <GreyTextInput
                    type="text"
                    value={values.title}
                    label={t('event.form.input.pushTitle')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('title', e.target.value)}
                    error={formErrors.title !== ''}
                    helperText={formErrors.title}
                  />
                  <GreyTextInput
                    type="text"
                    value={values.body}
                    label={t('event.form.input.pushBody')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('body', e.target.value)}
                  />
                </WhitePaper>
              </Grid>
            </Grid>
          </Grid>
          <Grid id="validation-grid-item" item xs={12} md={3}>
            <Grid container spacing={4} direction="column">
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  startIcon={<NavigationFillIcon width={22} height={22} color={nuance100} />}
                  disabled={pushSending}
                  fullWidth
                >
                  <Typography variant="button">
                    {t('event.push')}
                  </Typography>
                  {
                    pushSending
                    && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                        color="primary"
                      />
                    )
                  }
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default FormPushEventPage;
