import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {
  FormControl,
  FormHelperText,
  Paper,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import useStyles from './DatePicker.style';

type Props = {
  value: Date | string | null,
  label: string,
  /* eslint-disable no-unused-vars */
  onChange: (date: MaterialUiPickersDate) => void,
  error?: boolean,
  helperText?: string,
  format?: 'dateTime' | 'date',
  autoOk?: boolean,
}

const DatePicker: React.FC<Props> = ({
  value, label, onChange, error, helperText, format, autoOk,
}: Props) => {
  const { t } = useTranslation(['backoffice']);
  const classes = useStyles();

  return (
    <FormControl
      className={(error === true || helperText !== '') ? classes.formControlError : classes.formControl}
      error={error}
    >
      <Paper elevation={0} className={classes.paper}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          {format === 'dateTime' && (
            <KeyboardDateTimePicker
              autoOk={autoOk}
              allowKeyboardControl={false}
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy HH:mm"
              ampm={false}
              margin="normal"
              id="date-picker-inline"
              value={value}
              className={classes.input}
              label={label}
              onChange={onChange}
              invalidDateMessage={!error && t('datePicker.invalidFormat')}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              InputLabelProps={{
                shrink: true,
                error,
              }}
              animateYearScrolling={false}
            />
          )}
          {format === 'date' && (
            <KeyboardDatePicker
              autoOk={autoOk}
              allowKeyboardControl={false}
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              value={value}
              className={classes.input}
              label={label}
              onChange={onChange}
              invalidDateMessage={!error && t('datePicker.invalidFormat')}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              InputLabelProps={{
                shrink: true,
                error,
              }}
              animateYearScrolling={false}
            />
          )}
        </MuiPickersUtilsProvider>
      </Paper>
      {
        helperText
        && (
          <FormHelperText error={error}>
            <Typography
              variant="caption"
              color={error ? 'error' : 'primary'}
              className={classes.helperText}
            >
              {helperText}
            </Typography>
          </FormHelperText>
        )
      }
    </FormControl>
  );
};

const defaultProps = {
  helperText: '',
  error: false,
  format: 'dateTime',
  autoOk: true,
} as Props;

DatePicker.defaultProps = defaultProps;

export default React.memo(DatePicker);
