import React, { ReactElement } from 'react';
import { FormHelperText, Typography } from '@material-ui/core';
import useStyles, { iconStyle } from './UploadFileButton.style';
import PlusIcon from '../icon/PlusIcon';

type Props = {
  /* eslint-disable no-unused-vars */
  uploadFile: (files: File) => void,
  error?: boolean,
  helperText?: string,
  multiFile?: boolean,
  label: string,
  accept?: string
}

const UploadFileButton = ({
  uploadFile, error, helperText, multiFile, label, accept,
}: Props): ReactElement => {
  const classes = useStyles();

  return (
    <>
      <label htmlFor="file" className={classes.label}>
        <input
          type="file"
          name="file"
          id="file"
          multiple={multiFile}
          onChange={(event: any) => uploadFile(event.target.files[0])}
          className={classes.input}
          accept={accept}
        />
        <PlusIcon style={iconStyle} width={22} height={22} />
        <Typography variant="button">
          {label}
        </Typography>
      </label>
      {
        helperText
        && (
          <FormHelperText>
            <Typography
              variant="caption"
              color={error ? 'error' : 'primary'}
              className={classes.helperText}
            >
              {helperText}
            </Typography>
          </FormHelperText>
        )
      }
    </>
  );
};

const defaultProps: Partial<Props> = {
  helperText: '',
  error: false,
  multiFile: false,
  accept: 'image/*',
};

UploadFileButton.defaultProps = defaultProps;

export default React.memo(UploadFileButton);
