import React from 'react';
import { thea300 } from '../../constants/colors';

interface Props {
  color?: string,
  style?: any,
  width?: number,
  height?: number,
}

const BellFillIcon: React.FC<Props> = ({
  color, style, width = 24, height = 24,
}: Props) => (
  <svg
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    style={style}
    width={width}
    height={height}
  >
    <g
      id="Eva-icons/bell-fill"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g>
        <path
          d="M14,18.3413 C14,19.2403 13.084,20.0003 12,20.0003 C10.916,20.0003 10,19.2403 10,18.3413 L10,18.0003 L14,18.0003 L14,18.3413 Z M20.521,15.2073 L18.72,13.4043 L18.72,8.9363 C18.72,5.4553 16.218,2.4993 12.899,2.0593 C10.978,1.8043 9.038,2.3903 7.583,3.6663 C6.119,4.9483 5.28,6.7933 5.28,8.7273 L5.279,13.4043 L3.479,15.2083 C3.01,15.6773 2.871,16.3773 3.125,16.9903 C3.38,17.6033 3.973,18.0003 4.637,18.0003 L8,18.0003 L8,18.3413 C8,20.3593 9.794,22.0003 12,22.0003 C14.206,22.0003 16,20.3593 16,18.3413 L16,18.0003 L19.362,18.0003 C20.026,18.0003 20.619,17.6043 20.873,16.9903 C21.128,16.3773 20.989,15.6773 20.521,15.2073 L20.521,15.2073 Z"
          id="path-1"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

const defaultProps: Props = {
  color: thea300,
  style: {},
  width: 24,
  height: 24,
};
BellFillIcon.defaultProps = defaultProps;
export default BellFillIcon;
