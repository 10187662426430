import React, {
} from 'react';
import {
  FormControl,
  FormHelperText,
  TextareaAutosize,
  InputLabel,
  Paper,
  Typography,
} from '@material-ui/core';
import useStyles from './TextareaInput.style';
import { feedbackError } from '../../constants/colors';

interface Props {
  value: string | number,
  onChange: React.ChangeEventHandler<HTMLElement>,
  label: string,
  helperText?: string,
  error?: boolean,
}

const TextareaInput: React.FC<Props> = (props: Props) => {
  const {
    label,
    value,
    onChange,
    helperText,
    error,
  } = props;

  const classes = useStyles();

  return (
    <FormControl className={classes.formControl} error={error}>
      <InputLabel error={error} className={classes.label} disableAnimation shrink>
        <Typography variant="body1" style={error ? { color: feedbackError } : {}}>
          {label}
        </Typography>
      </InputLabel>
      <Paper elevation={0} className={classes.paper}>
        <TextareaAutosize
          defaultValue={value}
          rowsMin={2}
          onChange={onChange}
          className={classes.textarea}
        />
      </Paper>
      {
        helperText
        && (
          <FormHelperText error={error}>
            <Typography
              variant="caption"
              color={error ? 'error' : 'primary'}
              className={classes.helperText}
            >
              {helperText}
            </Typography>
          </FormHelperText>
        )
      }
    </FormControl>
  );
};

const defaultProps: Partial<Props> = {
  helperText: '',
  error: false,
};

TextareaInput.defaultProps = defaultProps;

export default TextareaInput;
