import React from 'react';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import UsersOphthaTable from './UsersOphthaTable';
import { usersActiveSelector, usersDisabledSelector, usersToModerateSelector } from '../../../../store/user/selectors';
import {
  ROLE_NURSE,
  ROLE_OPHTHA,
  ROLE_ORTHOPTIST,
} from '../../../../constants/role';

const UsersOphthaContent = () => {
  const { t } = useTranslation(['backoffice']);
  const availableRoles = [ROLE_OPHTHA, ROLE_NURSE, ROLE_ORTHOPTIST];
  const usersToModerate = useSelector(usersToModerateSelector(availableRoles));
  const usersActive = useSelector(usersActiveSelector(availableRoles));
  const usersDisabled = useSelector(usersDisabledSelector(availableRoles));

  return (
    <div style={{ padding: '1rem 0' }}>
      <Typography variant="h4" style={{ margin: '1rem 0' }}>
        {t('users.subtitle.toModerate')}
      </Typography>
      <UsersOphthaTable users={usersToModerate} isModerate />
      <Typography variant="h4" style={{ margin: '1rem 0' }}>
        {t('users.subtitle.active')}
      </Typography>
      <UsersOphthaTable users={usersActive} isActive />
      <Typography variant="h4" style={{ margin: '1rem 0' }}>
        {t('users.subtitle.disabled')}
      </Typography>
      <UsersOphthaTable users={usersDisabled} isDisabled />
    </div>
  );
};

export default React.memo(UsersOphthaContent);
