import React, {
  Dispatch, useEffect, useContext, useState,
} from 'react';
import { DefaultRootState, useDispatch, useSelector } from 'react-redux';
import {
  Typography, Button, Grid,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { IUser, UserState } from '../../../../store/user/types';
// import useStyles from '../../../../components/paper/MainPaper.style';
import {
  addAdminUser,
  putUser,
  resetStatus,
} from '../../../../store/user/actions';
import WhitePaper from '../../../../components/paper/WhitePaper';
import { NotificationContext } from '../../../../contexts/NotificationContext';
import GreyTextInput from '../../../../components/form/GreyTextInput';
import Breadcrumb from '../../../../components/common/Breadcrumb';
import { routeUsers } from '../../../../constants/routes';

const UsersFormAdmin = () => {
  const { t } = useTranslation(['backoffice']);
  const history = useHistory();
  // const classes = useStyles();
  const dispatch: Dispatch<any> = useDispatch();
  const slug: { id: string } = useParams();
  const { setNotification } = useContext(NotificationContext);
  const {
    users,
    error,
    status,
  } = useSelector<DefaultRootState, UserState>(
    (state: any) => state.userStore,
  );

  const userId = slug.id;
  let user: IUser | undefined;

  if (userId) {
    user = users.find((u: IUser) => u.id === userId);
  }

  const [values, setValues] = useState({
    email: user?.email ?? '',
    first_name: user?.first_name ?? '',
    last_name: user?.last_name ?? '',
  });

  const [formErrors, setFormErrors] = useState({
    email: '',
  });

  const handleFormAction = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    let formError = false;

    if (values.email === '') {
      formErrors.email = t('users.admin.form.emailRequired');
      formError = true;
    }

    setFormErrors({ ...formErrors });

    if (!formError) {
      let content: Partial<IUser> = { email: values.email };
      if (values.first_name) {
        content = { ...content, first_name: values.first_name };
      }
      if (values.last_name) {
        content = { ...content, last_name: values.last_name };
      }
      if (userId) {
        dispatch(putUser(userId, content));
      } else {
        dispatch(addAdminUser(content));
      }
    }
  };

  const handleChange = (prop: keyof Partial<IUser>, value: any) => {
    setValues({ ...values, [prop]: value });
    setFormErrors({ ...formErrors, [prop]: '' });
  };

  useEffect(() => {
    if (status === 'ERROR') {
      setNotification({ isOpened: true, severity: 'error', message: error || '' });
    }
    if (status === 'SUCCESS') {
      setNotification({ isOpened: true, severity: 'success', message: t('users.emailsAuthorized.form.success') });
      history.push(routeUsers);
    }
    if (status !== 'INIT') dispatch(resetStatus());
  }, [status]);

  return (
    <div>
      <Breadcrumb
        title={t('title.usersAdmin')}
        baseUrl={routeUsers}
        link={user && user.email}
      />
      <form
        onSubmit={handleFormAction}
        style={{ marginTop: '1rem' }}
      >
        <Grid container spacing={3}>
          <Grid item id="form-grid-item" xs={12} md={9}>
            <WhitePaper title={t('users.admin.form.subtitle')}>
              <GreyTextInput
                type="text"
                value={values.email}
                label={t('users.admin.form.email')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('email', event.target.value)}
                error={formErrors.email !== ''}
                helperText={formErrors.email}
              />
              <GreyTextInput
                type="text"
                value={values.first_name}
                label={t('users.admin.form.firstName')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('first_name', event.target.value)}
              />
              <GreyTextInput
                type="text"
                value={values.last_name}
                label={t('users.admin.form.lastName')}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange('last_name', event.target.value)}
              />
            </WhitePaper>
            <br />
          </Grid>
          <Grid item id="validation-grid-item" xs={12} md={3}>
            <Button
              variant="contained"
              color="secondary"
              type="submit"
              // disabled={sending}
            >
              <Typography variant="button">
                {t('common.save')}
              </Typography>
              {/*
                  sending
                  && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                      color="primary"
                    />
                  )
                  */}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default React.memo(UsersFormAdmin);
