import React from 'react';
import { thea300 } from '../../constants/colors';

interface Props {
  color?: string,
  style?: any
}

const BookOpenFillIcon: React.FC<Props> = (props: Props) => {
  const { color, style } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      style={style}
    >
      <g id="book-open-fill" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g>
          <path
            d="M2.982,4.3356 C3.288,4.0986 3.681,4.0156 4.058,4.1106 L4.058,4.1106 L11,5.8936 L11,20.1606 L3.438,18.2186 C2.886,18.0776 2.5,17.5796 2.5,17.0076 L2.5,17.0076 L2.5,5.3226 C2.5,4.9346 2.676,4.5756 2.982,4.3356 Z M19.9386,4.1109 C20.3196,4.0129 20.7106,4.0979 21.0176,4.3359 C21.3246,4.5749 21.4996,4.9349 21.4996,5.3219 L21.4996,5.3219 L21.4996,17.0079 C21.4996,17.5789 21.1146,18.0769 20.5616,18.2189 L20.5616,18.2189 L12.9996,20.1599 L12.9996,5.8939 Z"
            id="path-1"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

const defaultProps: Props = {
  color: thea300,
  style: {},
};

BookOpenFillIcon.defaultProps = defaultProps;

export default BookOpenFillIcon;
