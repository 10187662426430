import React from 'react';
import {
  Typography,
  Switch,
  FormControlLabel,
  InputLabel,
} from '@material-ui/core';
import useStyles from './GreyTextInput.style';

type Props = {
  value: boolean,
  onChange: any,
  label: string,
  name: string,
  disabled?: boolean
}
const SwitchComponent = ({
  value,
  label,
  onChange,
  name,
  disabled,
}: Props) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      className={classes.switchContainer}
      control={(
        <Switch
          checked={value}
          onChange={onChange}
          name={name}
          disabled={disabled}
        />
      )}
      label={(
        <InputLabel className={classes.label} disableAnimation shrink>
          <Typography variant="body1">
            {label}
          </Typography>
        </InputLabel>
      )}
    />
  );
};

SwitchComponent.defaultProps = {
  disabled: false,
};

export default SwitchComponent;
