import React, { Dispatch } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Popper,
  ClickAwayListener,
  Fade,
  MenuList,
  MenuItem,
} from '@material-ui/core';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import useStyles from './FlashInfoTable.style';
import { IFlashInfo } from '../../../store/flash-info/types';
import MoreVerticalOutline from '../../../components/icon/MoreVerticalOutline';
import { deleteFlashInfo } from '../../../store/flash-info/actions';
import { routeFlashInfos } from '../../../constants/routes';
import { htmlToString } from '../../../utils/editorHelper';

type Props = {
  flashInfos: IFlashInfo[]
}

const FlashInfoTable = ({ flashInfos }: Props) => {
  const { t } = useTranslation(['backoffice']);
  const classes = useStyles();
  const history = useHistory();
  const dispatch: Dispatch<any> = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [toolSelected, setToolSelected] = React.useState<IFlashInfo | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'popper-flashInfo' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLElement>, flashInfo: IFlashInfo) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setToolSelected(flashInfo);
  };

  const handleClickEdit = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    history.push(`${routeFlashInfos}/edit/${toolSelected?.id}`);
  };

  const handleClickDelete = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    if (toolSelected) {
      dispatch(deleteFlashInfo(toolSelected.id));
    }
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  return (
    <TableContainer component={Paper} style={{ padding: '30px' }}>
      <Table aria-label="flashInfo table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell}>{t('flashInfo.table.title')}</TableCell>
            <TableCell className={classes.cell}>{t('flashInfo.table.description')}</TableCell>
            <TableCell className={classes.cell}>{t('flashInfo.table.startDate')}</TableCell>
            <TableCell className={classes.cell}>{t('flashInfo.table.endDate')}</TableCell>
            <TableCell className={classes.cell}>{t('flashInfo.table.display_end_date')}</TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {flashInfos.map((flashInfo) => (
            <TableRow key={flashInfo.id}>
              <TableCell component="th" scope="row">
                {flashInfo.title}
              </TableCell>
              <TableCell component="th" scope="row">
                <div
                  style={{ wordBreak: 'break-all', wordWrap: 'break-word' }}
                // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: htmlToString(flashInfo.description as string),
                  }}
                />
              </TableCell>
              <TableCell component="th" scope="row">
                {flashInfo.start_date && format(new Date(flashInfo.start_date), 'dd-MM-yyyy')}
              </TableCell>
              <TableCell component="th" scope="row">
                {flashInfo.end_date && format(new Date(flashInfo.end_date), 'dd-MM-yyyy')}
              </TableCell>
              <TableCell component="th" scope="row">
                {flashInfo.display_end_date ? 'Oui' : 'Non' }
              </TableCell>
              <TableCell align="right">
                <IconButton
                  onClick={(e) => handleClick(e, flashInfo)}
                >
                  <MoreVerticalOutline />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          <Popper
            id={id}
            open={open}
            anchorEl={anchorEl}
            style={{ zIndex: 2 }}
            transition
          >
            {({ TransitionProps }) => (
              <ClickAwayListener onClickAway={handleClickAway}>
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <Fade {...TransitionProps} timeout={350}>
                  <Paper elevation={1}>
                    <MenuList>
                      <MenuItem
                        onClick={handleClickEdit}
                        className={classes.buttonEdit}
                      >
                        {t('common.edit')}
                      </MenuItem>
                      <MenuItem
                        onClick={handleClickDelete}
                        className={classes.buttonDelete}
                      >
                        {t('common.delete')}
                      </MenuItem>
                    </MenuList>
                  </Paper>
                </Fade>
              </ClickAwayListener>
            )}
          </Popper>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FlashInfoTable;
