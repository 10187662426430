import axios from 'axios';
import {
  apiProductCategories,
  apiProducts,
  apiProfessionalUnitOrders,
} from '../constants/api';
import { getToken } from '../services/AuthService';
import { IProduct } from '../store/product/types';

export const apiGetProducts = async () => {
  const token: string = await getToken();
  const data = await axios.get(
    `${process.env.REACT_APP_API_ROOT}${apiProducts}`,
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return data;
};

export const apiGetProductCategories = async () => {
  const token: string = await getToken();
  const data = await axios.get(
    `${process.env.REACT_APP_API_ROOT}${apiProductCategories}`,
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return data;
};

export const apiPostProduct = async (content: Partial<IProduct>, file?: File) => {
  const token: string = await getToken();

  const formData = new FormData();
  formData.append('content', JSON.stringify(content));
  if (file) {
    formData.append('picture', file);
  }

  const data = await axios.post(
    `${process.env.REACT_APP_API_ROOT}${apiProducts}`,
    formData,
    { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/x-www-form-urlencoded' } },
  );
  return data;
};

export const apiEditProduct = async (
  productId: string,
  content: Partial<IProduct>,
  file?: File | string,
) => {
  const token: string = await getToken();

  const formData = new FormData();
  formData.append('content', JSON.stringify(content));
  if (file) {
    formData.append('picture', file);
  }

  const data = await axios.post(
    `${process.env.REACT_APP_API_ROOT}${apiProducts}/${productId}`,
    formData,
    { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/x-www-form-urlencoded' } },
  );
  return data;
};

export const apiDeleteProduct = async (id: string) => {
  const token: string = await getToken();
  const data = await axios.delete(
    `${process.env.REACT_APP_API_ROOT}${apiProducts}/${id}`,
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return data;
};

export const apiExportProductsOrder = async (type: 'TYPE_USERS' | 'TYPE_SIMPLE' | 'TYPE_DETAILED') => {
  const token: string = await getToken();
  const data = await axios.get(
    `${process.env.REACT_APP_API_ROOT}${apiProfessionalUnitOrders}?format=csv&type=${type}`,
    { headers: { Authorization: `Bearer ${token}` } },
  );
  return data;
};
