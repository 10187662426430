import React, { ReactNode } from 'react';
import { useHistory } from 'react-router';
import useStyles from './BackOfficeLayout.style';
import MainPaper from '../paper/MainPaper';
import Menu from '../menu/Menu';
import AccountFab from '../menu/AccountFab';
import {
  hasToken,
  refreshToken,
  tokenRemainingTime,
} from '../../services/AuthService';
import { routeLogout } from '../../constants/routes';

type Props = {
  children: ReactNode
}

const BackOfficeLayout: React.FC<Props> = (props: Props) => {
  const { children } = props;
  const classes = useStyles();
  const history = useHistory();
  if (!hasToken()) {
    history.replace(routeLogout); // remove all localstorage
  }
  if (tokenRemainingTime() < -2592000) {
    // refresh expired
    history.replace(routeLogout);
  }
  if (tokenRemainingTime() < 300) {
    refreshToken().then(
      (token: string) => {
        if (token === '') {
          history.replace(routeLogout); // refresh error, logout
        }
      },
    );
  }
  return (
    <div className={classes.root}>
      <Menu />
      <MainPaper>
        <AccountFab />
        {children}
      </MainPaper>
    </div>
  );
};

export default BackOfficeLayout;
