import React from 'react';
import { thea300 } from '../../constants/colors';

interface Props {
  color?: string,
  style?: any
}

const BookmarkIcon: React.FC<Props> = (props: Props) => {
  const { color, style } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      style={style}
    >
      <g id="bookmark" fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g>
          <path
            d="M11.8213,15.5059 C11.9903,15.5059 12.1603,15.5489 12.3133,15.6349 L17.0003,18.2859 L17.0003,5.3339 C17.0003,5.1329 16.8793,4.9999 16.8003,4.9999 L7.2003,4.9999 C7.1203,4.9999 7.0003,5.1329 7.0003,5.3339 L7.0003,18.2339 L11.3063,15.6489 C11.4653,15.5539 11.6433,15.5059 11.8213,15.5059 M6.0003,20.9999 C5.8303,20.9999 5.6603,20.9569 5.5073,20.8699 C5.1943,20.6929 5.0003,20.3599 5.0003,19.9999 L5.0003,5.3339 C5.0003,4.0469 5.9873,2.9999 7.2003,2.9999 L16.8003,2.9999 C18.0133,2.9999 19.0003,4.0469 19.0003,5.3339 L19.0003,19.9999 C19.0003,20.3559 18.8113,20.6849 18.5043,20.8639 C18.1963,21.0439 17.8183,21.0469 17.5083,20.8709 L11.8363,17.6629 L6.5143,20.8579 C6.3563,20.9519 6.1783,20.9999 6.0003,20.9999"
            id="path-1"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
};

const defaultProps: Props = {
  color: thea300,
  style: {},
};

BookmarkIcon.defaultProps = defaultProps;

export default BookmarkIcon;
