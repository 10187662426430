import {
  INotification,
  NotificationAction,
  NotificationState,
} from './types';
import {
  STORE_NOTIFICATION_FAILED,
  STORE_NOTIFICATION_LOADED,
  STORE_NOTIFICATION_PENDING,
  STORE_NOTIFICATION_ADDED_SENT,
  STORE_NOTIFICATION_DELETED,
  STORE_NOTIFICATION_SENDING,
  STORE_NOTIFICATION_UPDATED_SENT,
  STORE_NOTIFICATION_RESET_STATUS,
  STORE_NOTIFICATION_USER_PENDING,
  STORE_NOTIFICATION_USER_LOADED,
  STORE_NOTIFICATION_USER_RESET,
  STORE_NOTIFICATION_USER_FAILED,
  STORE_NOTIFICATION_USER_RESET_STATUS,
} from './actionTypes';

const initialState: NotificationState = {
  notifications: [],
  pending: false,
  sending: false,
  error: null,
  status: 'INIT',
  affectedUsers: [],
  affectedUsersPending: false,
  affectedUsersError: null,
  affectedUsersStatus: 'INIT',
};

const reducer = (
  state: NotificationState = initialState,
  action: NotificationAction,
): NotificationState => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case STORE_NOTIFICATION_LOADED:
      return {
        ...state,
        pending: false,
        notifications: action.notifications || [],
      };
    case STORE_NOTIFICATION_FAILED:
      if (action.error !== undefined) {
        return {
          ...state,
          error: action.error,
          pending: false,
          sending: false,
          status: 'ERROR',
        };
      }
      return state;
    case STORE_NOTIFICATION_PENDING:
      return {
        ...state,
        pending: true,
      };
    case STORE_NOTIFICATION_SENDING:
      return {
        ...state,
        sending: true,
      };
    case STORE_NOTIFICATION_ADDED_SENT:
      if (action.notification !== undefined) {
        return {
          ...state,
          sending: false,
          status: 'SUCCESS',
          notifications: [...state.notifications, action.notification],
        };
      }
      return state;
    case STORE_NOTIFICATION_UPDATED_SENT:
      if (action.notification !== undefined) {
        return {
          ...state,
          sending: false,
          status: 'SUCCESS',
          notifications: state.notifications.map(
            (n) => (n.id === action.notification!.id ? action.notification : n),
          ) as INotification[],
        };
      }
      return state;
    case STORE_NOTIFICATION_DELETED:
      if (action.notificationId !== undefined) {
        return {
          ...state,
          notifications: state.notifications.filter((n) => n.id !== action.notificationId),
        };
      }
      return state;

    case STORE_NOTIFICATION_RESET_STATUS:
      return {
        ...state,
        status: 'INIT',
      };
    case STORE_NOTIFICATION_USER_PENDING:
      return {
        ...state,
        affectedUsersPending: true,
      };
    case STORE_NOTIFICATION_USER_LOADED:
      if (action.affectedUsers) {
        return {
          ...state,
          affectedUsersPending: false,
          affectedUsersStatus: 'SUCCESS',
          affectedUsers: action.affectedUsers,
        };
      }
      return state;
    case STORE_NOTIFICATION_USER_RESET:
      return {
        ...state,
        affectedUsers: [],
        affectedUsersPending: false,
        affectedUsersStatus: 'INIT',
      };
    case STORE_NOTIFICATION_USER_RESET_STATUS:
      return {
        ...state,
        affectedUsersStatus: 'INIT',
      };
    case STORE_NOTIFICATION_USER_FAILED:
      if (action.error !== undefined) {
        return {
          ...state,
          affectedUsersError: action.error,
          affectedUsersPending: false,
          affectedUsersStatus: 'ERROR',
        };
      }
      return state;
  }
  return state;
};

export default reducer;
