import React, { ReactElement } from 'react';
import {
  ListItemIcon,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { useHistory } from 'react-router';
import { nuance100Alpha } from '../../constants/colors';

interface Props {
  classes: ClassNameMap<string>,
  isActivate: boolean,
  matches: boolean,
  title: string,
  icon: ReactElement,
  link: string,
}

const IconMenuItem: React.FC<Props> = (props: Props) => {
  const {
    classes,
    isActivate,
    matches,
    title,
    icon,
    link,
  } = props;
  const history = useHistory();

  const handleClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    history.push(link);
  };

  return (
    <MenuItem onClick={handleClick} className={classes.menuItem}>
      <ListItemIcon className={classes.menuIcon}>
        {icon}
      </ListItemIcon>
      {matches && (
        <Typography
          variant="h5"
          className={classes.text}
          style={!isActivate ? { color: nuance100Alpha } : {}}
        >
          {title}
        </Typography>
      )}
    </MenuItem>
  );
};

export default IconMenuItem;
