import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import {
  ClickAwayListener,
  Fab,
  Fade,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import PersonIcon from '../icon/PersonIcon';
import useStyles from './AccountFab.style';
import {
  thea300,
  thea75,
} from '../../constants/colors';
import {
  routeAccount,
  routeLogout,
} from '../../constants/routes';

type Props = {};

const AccountFab: React.FC<Props> = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation(['backoffice']);
  const [iconColor, setIconColor] = useState(thea300);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'transitions-popper' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickLogout = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    history.replace(routeLogout);
  };

  const handleClickAccount = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    history.push(routeAccount);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  const handleMouseEnter = () => {
    setIconColor(thea75);
  };

  const handleMouseLeave = () => {
    setIconColor(thea300);
  };

  return (
    <>
      <Fab
        size="medium"
        color="primary"
        aria-label="account"
        className={classes.root}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        <PersonIcon
          color={iconColor}
          style={{ color: '250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms' }}
        />
      </Fab>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        transition
        placement="bottom-end"
        className={classes.popper}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClickAway}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Fade {...TransitionProps} timeout={350}>
              <Paper className={classes.paper} elevation={1}>
                <MenuList>
                  <MenuItem onClick={handleClickAccount}>
                    {t('title.account')}
                  </MenuItem>
                  <MenuItem onClick={handleClickLogout}>
                    {t('account.logout')}
                  </MenuItem>
                </MenuList>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default AccountFab;
