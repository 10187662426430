import {
  AxiosError,
  AxiosResponse,
} from 'axios';
import {
  DispatchType,
  ITerritory,
  TerritoryAction,
} from './types';
import {
  STORE_TERRITORY_FAILED,
  STORE_TERRITORY_LOADED,
  STORE_TERRITORY_PENDING,
} from './actionTypes';
import { apiGetTerritories } from '../../api/apiTerritory';

const territoryFailedAction = (error: string): TerritoryAction => ({
  type: STORE_TERRITORY_FAILED,
  error,
});

const territoryPendingAction = (): TerritoryAction => ({
  type: STORE_TERRITORY_PENDING,
});

const territoryLoadedAction = (territories: ITerritory[]): TerritoryAction => ({
  type: STORE_TERRITORY_LOADED,
  territories,
});

// eslint-disable-next-line import/prefer-default-export
export const getTerritories = () => (dispatch: DispatchType) => {
  dispatch(territoryPendingAction());
  apiGetTerritories()
    .then((data: AxiosResponse) => dispatch(territoryLoadedAction(data.data)))
    .catch((error: AxiosError) => dispatch(territoryFailedAction(error.message)));
};
