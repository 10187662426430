import { createSelector } from 'reselect';
import { State } from '../store';
import { INotification } from './types';

const notificationsSelector = (state: State) => state.notificationStore.notifications;

export const notificationsDateSortedSelector = createSelector(
  notificationsSelector,
  (notifications: INotification[]) => notifications
    .sort((n1: INotification, n2: INotification) => {
      if (n1.send_date === null && n2.send_date !== null) {
        return -1;
      }
      if (n1.send_date !== null && n2.send_date === null) {
        return 1;
      }
      if (n1.send_date === null && n2.send_date === null) {
        return 0;
      }
      let d1 = n1.send_date;
      if (typeof n1.send_date === 'string') {
        d1 = new Date(n1.send_date);
      }
      let d2 = n2.send_date;
      if (typeof n2.send_date === 'string') {
        d2 = new Date(n2.send_date);
      }
      // @ts-ignore
      if (d1.getTime() === d2.getTime()) {
        return 0;
      }
      // @ts-ignore
      if (d1.getTime() > d2.getTime()) {
        return -1;
      }
      return 1;
    }),
);

export default {
  notificationsDateSortedSelector,
};
