import { createSelector } from 'reselect';
import { State } from '../store';
import { IEvent } from './types';

const eventsSelector = (state: State) => state.eventStore.events;
const categoriesIdSelector = (state: State) => state.eventStore.categoryIds;

const eventsFilteredSelector = createSelector(
  eventsSelector,
  categoriesIdSelector,
  (events, cid) => events.filter((e) => e.event_categories
    .find((ec) => cid?.includes(ec))),
);

export const futureOrCurrentEventsFilteredSelector = createSelector(
  eventsFilteredSelector,
  (events) => events
    .filter((e) => Date.parse(String(e.end_date)) > Date.now())
    .sort((e1: IEvent, e2: IEvent) => {
      if (e1.start_date < e2.start_date) {
        return -1;
      }
      return 1;
    }),
);

export const futureEventsFilteredSelector = createSelector(
  eventsFilteredSelector,
  (events) => events
    .filter((e) => Date.parse(String(e.start_date)) > Date.now())
    .sort((e1: IEvent, e2: IEvent) => {
      if (e1.start_date < e2.start_date) {
        return -1;
      }
      return 1;
    }),
);

export const pastEventsFilteredSelector = createSelector(
  eventsFilteredSelector,
  (events) => events
    .filter((e) => Date.parse(String(e.start_date)) <= Date.now())
    .sort((e1: IEvent, e2: IEvent) => {
      if (e1.start_date > e2.start_date) {
        return -1;
      }
      return 1;
    }),
);

export default {
  futureEventsFilteredSelector,
  pastEventsFilteredSelector,
};
