import React, {
  FormEvent,
  useState,
} from 'react';
import { useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Typography,
} from '@material-ui/core';
import useStyles from '../../components/paper/IconBluePaper.style';
import { apiPostResetPassword } from '../../api/apiAnonymous';
import WhiteTextInput from '../../components/form/WhiteTextInput';
import LockIcon from '../../components/icon/LockIcon';
import { leftIconStyle } from '../../components/form/WhiteTextInput.style';
import { checkPassword } from '../../services/PasswordService';
import { feedbackSuccess } from '../../constants/colors';

type Props = {}

const ResetPasswordPage: React.FC<Props> = () => {
  const { t } = useTranslation(['anonymous']);
  const location = useLocation();
  const code = new URLSearchParams(location.search).get('c');
  const classes = useStyles();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [passwordRepeatError, setPasswordRepeatError] = useState('');

  const handleLogin = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    let formError = false;
    if (password.trim() === '') {
      formError = true;
      setPasswordError(t('resetPassword.passwordRequired'));
    }
    if (passwordRepeat.trim() === '') {
      formError = true;
      setPasswordRepeatError(t('resetPassword.passwordRepeatRequired'));
    }
    if (password !== passwordRepeat) {
      formError = true;
      setPasswordRepeatError(t('resetPassword.passwordNotEquals'));
    }
    if (!checkPassword(password)) {
      formError = true;
      setPasswordError(t('resetPassword.passwordDontRespectRules'));
    }
    if (!formError) {
      if (code) {
        apiPostResetPassword(code, password).then(
          () => {
            setSuccess(t('resetPassword.success'));
          },
        ).catch((apiError: any) => {
          setError(t('resetPassword.expiredCode'));
          // eslint-disable-next-line no-console
          console.log(apiError);
        });
      } else {
        setError(t('resetPassword.expiredCode'));
      }
    } else {
      setError(t('resetPassword.expiredCode'));
    }
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setPassword(event.target.value);
    setPasswordError('');
    setError('');
  };

  const handleChangePasswordRepeat = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setPasswordRepeat(event.target.value);
    setPasswordRepeatError('');
    setError('');
  };

  if (success !== '') {
    return (
      <Typography
        variant="body1"
        style={{ color: feedbackSuccess }}
        className={classes.description}
      >
        {t('resetPassword.success')}
      </Typography>
    );
  }
  return (
    <>
      <Typography variant="body1" className={classes.description}>
        {t('resetPassword.text')}
      </Typography>
      <form onSubmit={handleLogin}>
        {error !== ''
        && (
          <Typography variant="body1" color="error" className={classes.description}>
            {error}
          </Typography>
        )}
        <WhiteTextInput
          type="password"
          value={password}
          onChange={handleChangePassword}
          name="username"
          label={t('resetPassword.password')}
          helperText={passwordError}
          error={passwordError !== ''}
          icon={(<LockIcon style={leftIconStyle} />)}
        />
        <WhiteTextInput
          type="password"
          value={passwordRepeat}
          onChange={handleChangePasswordRepeat}
          name="passwordRepeat"
          label={t('resetPassword.passwordRepeat')}
          helperText={passwordRepeatError}
          error={passwordRepeatError !== ''}
          icon={(<LockIcon style={leftIconStyle} />)}
        />
        <Typography variant="body1" className={classes.description}>
          {t('resetPassword.help')}
        </Typography>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disableElevation
          className={classes.button}
        >
          <Typography variant="button">
            {t('resetPassword.button')}
          </Typography>
        </Button>
      </form>
    </>
  );
};

export default ResetPasswordPage;
