import React, {
  ReactNode,
  useState,
} from 'react';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputBase,
  InputLabel,
  Paper,
  Typography,
} from '@material-ui/core';
import { InputBaseComponentProps } from '@material-ui/core/InputBase/InputBase';
import useStyles from './GreyTextInput.style';
import { feedbackError } from '../../constants/colors';
import EyeOffIcon from '../icon/EyeOffIcon';
import EyeOnIcon from '../icon/EyeOnIcon';

interface Props {
  value: string | number | null,
  onChange: React.ChangeEventHandler<HTMLElement>,
  type?: 'text' | 'number' | 'password',
  icon?: ReactNode,
  label: string,
  name?: string,
  placeholder?: string,
  helperText?: string,
  error?: boolean,
  disabled?: boolean,
  style?: any
  inputProps?: InputBaseComponentProps
}

const GreyTextInput: React.FC<Props> = (props: Props) => {
  const {
    type,
    label,
    value,
    onChange,
    icon,
    name,
    placeholder,
    helperText,
    error,
    disabled,
    style,
    inputProps,
  } = props;

  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [inputType, setInputType] = useState(type);

  const handleEyeClick = () => {
    if (type === 'password') {
      if (showPassword) {
        setShowPassword(false);
        setInputType('password');
      } else {
        setShowPassword(true);
        setInputType('text');
      }
    }
  };
  let inputClass = null;
  if (type === 'password') {
    inputClass = icon !== null ? classes.inputPasswordWithIcon : classes.inputPassword;
  } else {
    inputClass = icon !== null ? classes.inputBaseWithIcon : classes.inputBase;
  }

  return (
    <FormControl
      className={(error === true || helperText !== '') ? classes.formControlError : classes.formControl}
      error={error}
    >
      <InputLabel error={error} className={classes.label} disableAnimation shrink>
        <Typography variant="body1" style={error ? { color: feedbackError } : {}}>
          {label}
        </Typography>
      </InputLabel>
      <Paper elevation={0} className={classes.paper} style={style}>
        {icon}
        <InputBase
          type={inputType}
          placeholder={placeholder}
          inputProps={{
            'aria-label': label,
            className: classes.input,
            style: error ? { color: feedbackError } : {},
            ...inputProps,
          }}
          className={inputClass}
          value={value}
          onChange={onChange}
          name={name}
          error={error}
          disabled={disabled}
        />
        {
          type === 'password'
          && showPassword
          && (
            <IconButton onClick={handleEyeClick} className={classes.rightIcon}>
              <EyeOffIcon />
            </IconButton>
          )
        }
        {
          type === 'password'
          && !showPassword
          && (
            <IconButton onClick={handleEyeClick} className={classes.rightIcon}>
              <EyeOnIcon />
            </IconButton>
          )
        }
      </Paper>
      {
        helperText
        && (
          <FormHelperText error={error}>
            <Typography
              variant="caption"
              color={error ? 'error' : 'primary'}
              className={classes.helperText}
            >
              {helperText}
            </Typography>
          </FormHelperText>
        )
      }
    </FormControl>
  );
};

const defaultProps: Partial<Props> = {
  type: 'text',
  icon: null,
  name: '',
  helperText: '',
  placeholder: '',
  error: false,
  disabled: false,
  style: {},
  inputProps: {},
};

GreyTextInput.defaultProps = defaultProps;

export default React.memo(GreyTextInput);
