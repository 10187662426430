import { createSelector } from 'reselect';
import { State } from '../store';
import { IBook } from './types';

const booksSelector = (state: State) => state.bookStore.books;

export const activeBooksSelector = createSelector(
  booksSelector,
  (books) => books.filter((e: IBook) => e.is_activated),
);

export const inactiveBooksSelector = createSelector(
  booksSelector,
  (books) => books.filter((e: IBook) => e.is_activated === false),
);

export default {
  activeBooksSelector,
  inactiveBooksSelector,
};
